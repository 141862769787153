import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

import styles from '@features/section-what-is-sminex/ui/styles.module.css';
import ellipseUpsidePNG from '@shared/assets/gradients/ellipse-upside-down.png';

const EllipseGradient = ({ gsapModule }: { gsapModule: typeof gsap }) => {
  const imgRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsapModule?.context(() => {
      const img = imgRef.current;
      if (!img) return;

      const tl = gsapModule.timeline({
        scrollTrigger: {
          trigger: img,
          start: 'top bottom',
        },
      });

      tl.fromTo(
        img,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 3,
        },
        '=0.5'
      );
    });
    return () => ctx.revert();
  }, []);

  return (
    <>
      <img src={ellipseUpsidePNG} className={styles.backgroungImage} alt="фоновый градиент" ref={imgRef} />
      <div className={styles.spaceFiller} />
    </>
  );
};

export default EllipseGradient;
