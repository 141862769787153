const ReactIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9997 30.7427C29.5145 30.7427 30.7425 29.5147 30.7425 27.9999C30.7425 26.4851 29.5145 25.2571 27.9997 25.2571C26.4848 25.2571 25.2568 26.4851 25.2568 27.9999C25.2568 29.5147 26.4848 30.7427 27.9997 30.7427Z"
        fill="#61DAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1089 26.555C14.4878 27.1157 14.2822 27.6057 14.2822 28.0001C14.2822 28.3944 14.4878 28.8844 15.1089 29.4451C15.7299 30.0057 16.6847 30.5564 17.9496 31.0394C20.4726 32.0027 24.0256 32.6195 27.9998 32.6195C31.9741 32.6195 35.5271 32.0027 38.0501 31.0394C39.315 30.5564 40.2698 30.0057 40.8908 29.4451C41.5119 28.8844 41.7175 28.3944 41.7175 28.0001C41.7175 27.6057 41.5119 27.1157 40.8908 26.555C40.2698 25.9944 39.315 25.4437 38.0501 24.9607C35.5271 23.9974 31.9741 23.3806 27.9998 23.3806C24.0256 23.3806 20.4726 23.9974 17.9496 24.9607C16.6847 25.4437 15.7299 25.9944 15.1089 26.555ZM17.2362 23.0923C20.04 22.0218 23.8458 21.3806 27.9998 21.3806C32.1539 21.3806 35.9597 22.0218 38.7635 23.0923C40.1619 23.6262 41.3625 24.2864 42.231 25.0704C43.0993 25.8543 43.7175 26.8426 43.7175 28.0001C43.7175 29.1575 43.0993 30.1458 42.231 30.9297C41.3625 31.7137 40.1619 32.3739 38.7635 32.9078C35.9597 33.9784 32.1539 34.6195 27.9998 34.6195C23.8458 34.6195 20.04 33.9784 17.2362 32.9078C15.8378 32.3739 14.6372 31.7137 13.7687 30.9297C12.9004 30.1458 12.2822 29.1575 12.2822 28.0001C12.2822 26.8426 12.9004 25.8543 13.7687 25.0704C14.6372 24.2864 15.8378 23.6262 17.2362 23.0923Z"
        fill="#61DAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8062 16.1135C22.01 15.856 21.4829 15.923 21.1414 16.1201C20.7999 16.3173 20.4783 16.7403 20.3033 17.5586C20.1283 18.3767 20.1288 19.4789 20.343 20.8159C20.7702 23.4825 22.0125 26.8679 23.9997 30.3097C25.9868 33.7515 28.2974 36.5201 30.3932 38.2234C31.4439 39.0773 32.3982 39.6289 33.1942 39.8864C33.9904 40.1439 34.5175 40.0769 34.859 39.8798C35.2005 39.6826 35.5221 39.2596 35.6971 38.4413C35.8721 37.6232 35.8717 36.521 35.6575 35.184C35.2302 32.5174 33.9879 29.132 32.0008 25.6902C30.0137 22.2484 27.703 19.4798 25.6072 17.7765C24.5565 16.9226 23.6022 16.371 22.8062 16.1135ZM26.8687 16.2245C29.1976 18.1173 31.6558 21.0927 33.7328 24.6902C35.8099 28.2878 37.1575 31.9043 37.6323 34.8676C37.8691 36.3457 37.8976 37.7155 37.6529 38.8596C37.4082 40.0036 36.8614 41.0331 35.859 41.6118C34.8567 42.1905 33.6917 42.1494 32.5786 41.7893C31.4654 41.4291 30.2934 40.7195 29.1318 39.7754C26.8028 37.8826 24.3446 34.9072 22.2676 31.3097C20.1906 27.7121 18.8429 24.0956 18.3681 21.1323C18.1313 19.6542 18.1028 18.2844 18.3475 17.1403C18.5922 15.9963 19.1391 14.9668 20.1414 14.3881C21.1438 13.8094 22.3087 13.8505 23.4218 14.2106C24.535 14.5708 25.707 15.2804 26.8687 16.2245Z"
        fill="#61DAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6963 17.5585C35.5213 16.7403 35.1997 16.3173 34.8582 16.1201C34.5167 15.9229 33.9896 15.8559 33.1934 16.1135C32.3974 16.371 31.4431 16.9225 30.3924 17.7765C28.2966 19.4798 25.986 22.2484 23.9988 25.6902C22.0117 29.1319 20.7694 32.5173 20.3421 35.184C20.1279 36.5209 20.1275 37.6231 20.3025 38.4412C20.4775 39.2595 20.7991 39.6825 21.1406 39.8797C21.4821 40.0769 22.0092 40.1439 22.8054 39.8863C23.6014 39.6288 24.5557 39.0773 25.6064 38.2233C27.7022 36.52 30.0128 33.7514 32 30.3096C33.9871 26.8678 35.2294 23.4824 35.6567 20.8158C35.8708 19.4789 35.8713 18.3766 35.6963 17.5585ZM37.6315 21.1322C37.1567 24.0956 35.8091 27.7121 33.732 31.3096C31.655 34.9071 29.1968 37.8825 26.8678 39.7753C25.7062 40.7194 24.5342 41.4291 23.421 41.7892C22.3079 42.1493 21.143 42.1904 20.1406 41.6117C19.1382 41.033 18.5914 40.0036 18.3467 38.8596C18.102 37.7154 18.1305 36.3456 18.3673 34.8676C18.8421 31.9042 20.1897 28.2877 22.2668 24.6902C24.3438 21.0926 26.802 18.1173 29.131 16.2244C30.2926 15.2803 31.4646 14.5707 32.5778 14.2106C33.6909 13.8505 34.8558 13.8093 35.8582 14.388C36.8606 14.9667 37.4074 15.9962 37.6521 17.1402C37.8968 18.2843 37.8683 19.6541 37.6315 21.1322Z"
        fill="#61DAFB"
      />
    </svg>
  );
};

export default ReactIcon;
