import { Fragment } from 'react';

const REG_EXP_DOUBLE_ASTERISK = /\*\*(.*?)\*\*/g;

interface ITextParserProps {
  text?: string;
  mainColor: string;
  secondColor: string;
}

function ColorTextParser({ text, mainColor, secondColor }: ITextParserProps) {
  if (!text) return null;
  // Основный текст будет иметь mainColor.
  // Части текста, выделенные двойными звездочками ** ** будут иметь secondColor
  const parts = text.split(REG_EXP_DOUBLE_ASTERISK);

  return (
    <span style={{ color: mainColor }}>
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          return (
            <span key={index} style={{ color: secondColor }}>
              {part}
            </span>
          );
        } else {
          return <Fragment key={index}>{part}</Fragment>;
        }
      })}
    </span>
  );
}

export default ColorTextParser;
