const PostmanIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.3114 18.4924C38.4309 16.0572 35.8704 14.2344 32.9539 13.2546C30.0373 12.2747 26.8957 12.1817 23.9263 12.9874C20.9569 13.7931 18.2931 15.4613 16.2719 17.781C14.2506 20.1008 12.9627 22.9678 12.5709 26.0195C12.1792 29.0712 12.7013 32.1706 14.0711 34.9256C15.4409 37.6806 17.597 39.9675 20.2666 41.497C22.9363 43.0266 25.9995 43.7301 29.069 43.5186C32.1385 43.3071 35.0763 42.1901 37.511 40.3089C44.3067 35.057 45.5609 25.2906 40.3109 18.4924H40.3114Z"
        fill="#FF6C37"
      />
      <path
        d="M33.2465 22.3559L26.6875 28.967L24.834 27.0615C31.2894 20.606 31.9374 21.1893 33.247 22.3559H33.2465Z"
        fill="#1E2235"
      />
      <path
        d="M26.6873 29.1478C26.6292 29.1464 26.5738 29.1233 26.5318 29.0832L24.6651 27.2165C24.6231 27.173 24.5996 27.1149 24.5996 27.0544C24.5996 26.9939 24.6231 26.9358 24.6651 26.8923C31.0297 20.5277 31.8984 20.8257 33.4019 22.1868C33.4271 22.2069 33.4472 22.2327 33.4607 22.2619C33.4742 22.2912 33.4807 22.3232 33.4797 22.3555C33.4894 22.4187 33.4651 22.4833 33.4151 22.5241L26.856 29.0837C26.81 29.1258 26.7497 29.1489 26.6873 29.1483V29.1478ZM25.1576 27.061L26.6873 28.5907L32.9095 22.3686C32.6947 22.0993 32.3881 21.9188 32.0485 21.8614C31.7089 21.804 31.36 21.8738 31.0686 22.0575C29.9408 22.563 28.1262 24.1054 25.1576 27.061Z"
        fill="#FF6C37"
      />
      <path
        d="M28.5672 30.7948L26.7783 28.9671L33.3374 22.4854C35.1005 24.2096 32.4818 27.0873 28.5672 30.7943V30.7948Z"
        fill="#1E2235"
      />
      <path
        d="M28.5674 31.0281C28.51 31.0236 28.4555 31.0009 28.4118 30.9635L26.584 29.1746C26.5566 29.1081 26.5566 29.0335 26.584 28.967C26.5646 28.9125 26.5646 28.8529 26.584 28.7983L33.1431 22.2393C33.1887 22.1965 33.2489 22.1726 33.3115 22.1726C33.3741 22.1726 33.4343 22.1965 33.48 22.2393C33.6924 22.4348 33.8602 22.6738 33.972 22.9399C34.0838 23.2061 34.137 23.4932 34.1279 23.7817C34.1279 25.5317 32.1315 27.7352 28.7355 30.9503C28.6902 30.9952 28.6305 31.0228 28.5669 31.0281H28.5674ZM27.1154 28.967L28.58 30.4312C31.1724 27.9812 33.6486 25.4535 33.6613 23.7424C33.6754 23.3796 33.5555 23.0244 33.3244 22.7444L27.1154 28.967Z"
        fill="#FF6C37"
      />
      <path
        d="M24.8854 27.1005L26.1819 28.3969C26.1902 28.4042 26.1968 28.4132 26.2013 28.4232C26.2059 28.4333 26.2082 28.4442 26.2082 28.4552C26.2082 28.4663 26.2059 28.4772 26.2013 28.4872C26.1968 28.4973 26.1902 28.5063 26.1819 28.5136L23.4466 29.0969C23.3914 29.1088 23.3338 29.1024 23.2825 29.0785C23.2313 29.0547 23.1893 29.0148 23.1629 28.9648C23.1365 28.9149 23.1271 28.8577 23.1362 28.802C23.1453 28.7462 23.1723 28.695 23.2132 28.656L24.8077 27.0747C24.8077 27.0747 24.8597 27.0747 24.8854 27.1005Z"
        fill="#1E2235"
      />
      <path
        d="M23.3817 29.3559C23.2953 29.3548 23.2105 29.3316 23.1357 29.2884C23.0608 29.2453 22.9982 29.1837 22.9539 29.1094C22.9041 29.0154 22.886 28.9078 22.9024 28.8027C22.9187 28.6975 22.9687 28.6005 23.0448 28.5261L24.6261 26.9317C24.6831 26.8807 24.7569 26.8525 24.8334 26.8525C24.9099 26.8525 24.9837 26.8807 25.0408 26.9317L26.3372 28.2281C26.414 28.3011 26.4441 28.4114 26.415 28.5135C26.3858 28.6156 26.3002 28.6967 26.1948 28.721L23.4595 29.3044L23.3817 29.3559ZM24.8337 27.3857L23.369 28.8504L25.7932 28.3317L24.8337 27.3857Z"
        fill="#FF6C37"
      />
      <path
        d="M36.7333 18.7131C35.7951 17.808 34.3212 17.7701 33.3373 18.6241C32.3534 19.4782 32.1852 20.9443 32.9513 21.9992C33.7175 23.054 35.1617 23.3496 36.2798 22.6797L34.5166 20.9166L36.7333 18.7131Z"
        fill="#1E2235"
      />
      <path
        d="M34.9834 23.2757C34.4444 23.2688 33.9194 23.1034 33.4737 22.8001C33.028 22.4969 32.6814 22.0692 32.4771 21.5704C32.2729 21.0719 32.2193 20.5246 32.323 19.996C32.4267 19.4674 32.683 18.9808 33.0604 18.5964C33.5794 18.0869 34.2776 17.8015 35.0048 17.8015C35.7321 17.8015 36.4303 18.0869 36.9493 18.5964C36.9989 18.6382 37.0271 18.6999 37.0271 18.7651C37.0269 18.7971 37.0199 18.8288 37.0065 18.8579C36.9931 18.887 36.9736 18.9128 36.9493 18.9337L34.8493 20.9171L36.4437 22.4857C36.4696 22.5104 36.489 22.541 36.5002 22.5749C36.5115 22.6088 36.5143 22.645 36.5084 22.6802C36.5045 22.7142 36.4933 22.7469 36.4754 22.776C36.4575 22.8052 36.4334 22.8301 36.4048 22.8489C35.9765 23.1166 35.4841 23.2643 34.9791 23.2766L34.9834 23.2757ZM34.9834 18.2459C34.0598 18.2508 33.2335 18.8064 32.8869 19.6556C32.5403 20.5048 32.7289 21.4824 33.3764 22.1348C33.6984 22.4587 34.1108 22.6778 34.5595 22.7634C35.0082 22.849 35.4723 22.7972 35.891 22.6146L34.3612 21.0848C34.3369 21.0653 34.3173 21.0407 34.3038 21.0126C34.2903 20.9845 34.2833 20.9538 34.2833 20.9227C34.2833 20.8915 34.2903 20.8608 34.3038 20.8327C34.3173 20.8047 34.3369 20.78 34.3612 20.7605L36.3834 18.7252C35.9815 18.4171 35.4899 18.2487 34.9834 18.2454V18.2459Z"
        fill="#FF6C37"
      />
      <path
        d="M36.7723 18.752L34.5557 20.9555L36.3188 22.7186C36.4962 22.6167 36.6614 22.4949 36.8112 22.3555C37.2733 21.867 37.5275 21.2182 37.5203 20.5458C37.513 19.8735 37.2448 19.2303 36.7723 18.752Z"
        fill="#1E2235"
      />
      <path
        d="M36.2798 22.9132C36.2507 22.9148 36.2216 22.9098 36.1947 22.8986C36.1677 22.8874 36.1437 22.8703 36.1243 22.8485L34.3611 21.0854C34.3368 21.066 34.3172 21.0413 34.3037 21.0132C34.2902 20.9852 34.2832 20.9544 34.2832 20.9233C34.2832 20.8922 34.2902 20.8614 34.3037 20.8334C34.3172 20.8053 34.3368 20.7806 34.3611 20.7612L36.5647 18.5965C36.586 18.5645 36.6148 18.5381 36.6488 18.5199C36.6827 18.5017 36.7206 18.4922 36.7591 18.4922C36.7976 18.4922 36.8355 18.5017 36.8695 18.5199C36.9034 18.5381 36.9323 18.5645 36.9536 18.5965C37.2122 18.85 37.4178 19.1525 37.558 19.4864C37.6983 19.8203 37.7706 20.1788 37.7706 20.541C37.7706 20.9031 37.6983 21.2617 37.558 21.5955C37.4178 21.9294 37.2122 22.2319 36.9536 22.4854C36.801 22.6473 36.6264 22.7868 36.4349 22.9001C36.3867 22.9244 36.3309 22.9291 36.2793 22.9132H36.2798ZM34.8541 20.9172L36.3187 22.3819C36.4195 22.3112 36.5148 22.2333 36.604 22.1485C37.0346 21.7191 37.2771 21.1364 37.2783 20.5283C37.2793 19.9842 37.0809 19.4585 36.7207 19.0506L34.8541 20.9172Z"
        fill="#FF6C37"
      />
      <path
        d="M33.5187 22.6282C33.3432 22.4634 33.1114 22.3716 32.8705 22.3716C32.6297 22.3716 32.3979 22.4634 32.2223 22.6282L26.3506 28.4868L27.3228 29.4722L33.5319 24.0277C33.6265 23.937 33.7017 23.8278 33.7526 23.7069C33.8035 23.5861 33.8291 23.4561 33.8279 23.3249C33.8266 23.1938 33.7986 23.0642 33.7454 22.9444C33.6922 22.8245 33.6151 22.7167 33.5187 22.6277V22.6282Z"
        fill="#1E2235"
      />
      <path
        d="M27.2709 29.706C27.2128 29.7046 27.1573 29.6815 27.1153 29.6413L26.13 28.656C26.0879 28.6125 26.0645 28.5544 26.0645 28.4939C26.0645 28.4334 26.0879 28.3753 26.13 28.3318L32.0021 22.4854C32.1713 22.3164 32.3873 22.2022 32.6221 22.1575C32.8569 22.1128 33.0998 22.1397 33.3192 22.2347C33.5385 22.3297 33.7243 22.4884 33.8524 22.6902C33.9805 22.8921 34.0449 23.1277 34.0375 23.3667C34.0307 23.5289 33.9917 23.6881 33.9227 23.835C33.8537 23.9819 33.756 24.1135 33.6355 24.2222L27.4264 29.6666C27.3813 29.6986 27.3258 29.7125 27.2709 29.7055V29.706ZM26.584 28.4868L27.232 29.1479L33.3244 23.846C33.475 23.7147 33.565 23.5271 33.5708 23.3273C33.5766 23.1275 33.4979 22.9321 33.3506 22.796C33.2146 22.6605 33.0304 22.5845 32.8385 22.5845C32.6465 22.5845 32.4624 22.6605 32.3264 22.796L26.584 28.4868Z"
        fill="#FF6C37"
      />
      <path
        d="M21.8004 35.163C21.7838 35.1855 21.7749 35.2128 21.7749 35.2407C21.7749 35.2687 21.7838 35.296 21.8004 35.3185L22.0595 36.4463C22.0699 36.5127 22.06 36.5806 22.0313 36.6413C22.0025 36.702 21.9561 36.7526 21.8981 36.7866C21.8404 36.8204 21.7734 36.8349 21.7068 36.8282C21.6402 36.8215 21.5775 36.7938 21.5277 36.7491L19.752 35.038L25.2872 29.459H27.2059L28.5023 30.7554C26.48 32.5236 24.2251 34.0066 21.8004 35.163Z"
        fill="#1E2235"
      />
      <path
        d="M21.6834 37.0686C21.5366 37.0657 21.3917 37.0054 21.2945 36.8999L19.5965 35.2019C19.5722 35.1825 19.5525 35.1578 19.5391 35.1298C19.5256 35.1017 19.5186 35.071 19.5186 35.0398C19.5186 35.0087 19.5256 34.978 19.5391 34.9499C19.5525 34.9218 19.5722 34.8972 19.5965 34.8777L25.1702 29.2909C25.2014 29.2577 25.2422 29.2351 25.2869 29.2262H27.2182C27.2685 29.2084 27.3234 29.2084 27.3737 29.2262L28.6702 30.5227C28.6948 30.5454 28.7136 30.5738 28.7248 30.6054C28.7361 30.637 28.7395 30.6708 28.7348 30.704C28.7357 30.7362 28.7292 30.7682 28.7157 30.7975C28.7022 30.8267 28.6821 30.8525 28.657 30.8727L28.5535 30.9636C26.523 32.6564 24.2891 34.089 21.9036 35.2282L22.15 36.2782C22.1691 36.3592 22.1698 36.4434 22.1522 36.5247C22.1345 36.606 22.0989 36.6824 22.048 36.7482C21.9971 36.814 21.9321 36.8677 21.8579 36.9052C21.7836 36.9427 21.7019 36.9631 21.6187 36.9651L21.6834 37.0686ZM20.102 35.0333L21.6313 36.5757C21.6456 36.5884 21.664 36.5954 21.6831 36.5954C21.7022 36.5954 21.7206 36.5884 21.7349 36.5757C21.7444 36.5624 21.7495 36.5465 21.7495 36.5302C21.7495 36.5139 21.7444 36.498 21.7349 36.4848L21.4758 35.357C21.4554 35.2761 21.4636 35.1906 21.499 35.115C21.5345 35.0394 21.5949 34.9784 21.6702 34.9424C23.9916 33.8184 26.1679 32.4167 28.1515 30.7682L27.0884 29.7051H25.3515L20.102 35.0333Z"
        fill="#FF6C37"
      />
      <path
        d="M18.4171 36.3685L19.7135 35.072L21.7099 37.0685L18.5342 36.8482C18.4797 36.844 18.4279 36.8226 18.3863 36.7871C18.3446 36.7517 18.3152 36.704 18.3023 36.6509C18.2894 36.5977 18.2935 36.5418 18.3142 36.4912C18.3349 36.4406 18.3711 36.3978 18.4176 36.3689L18.4171 36.3685Z"
        fill="#1E2235"
      />
      <path
        d="M21.7482 37.263L18.5851 37.0428C18.3975 37.0253 18.2361 36.897 18.17 36.7254C18.1039 36.5538 18.1442 36.3501 18.274 36.213L19.5705 34.9166C19.614 34.8746 19.6721 34.8511 19.7326 34.8511C19.7931 34.8511 19.8512 34.8746 19.8947 34.9166L21.878 36.8999C21.9055 36.9375 21.9203 36.9829 21.9203 37.0295C21.9203 37.076 21.9055 37.1214 21.878 37.159C21.848 37.2075 21.8021 37.2441 21.7482 37.2626V37.263ZM18.5851 36.5373L21.1775 36.706L19.7518 35.3706L18.5851 36.5373Z"
        fill="#FF6C37"
      />
      <path d="M27.3872 29.6154L24.7686 30.0174L26.2852 28.5139L27.3872 29.6154Z" fill="#1E2235" />
      <path
        d="M24.7686 30.2631C24.7251 30.2642 24.6822 30.2525 24.6453 30.2294C24.6084 30.2063 24.5791 30.1729 24.561 30.1333C24.5334 30.0957 24.5186 30.0503 24.5186 30.0037C24.5186 29.9571 24.5334 29.9117 24.561 29.8742L26.0646 28.3575C26.1102 28.3146 26.1704 28.2908 26.233 28.2908C26.2956 28.2908 26.3558 28.3146 26.4014 28.3575L27.5039 29.459C27.5259 29.494 27.5376 29.5344 27.5376 29.5757C27.5376 29.617 27.5259 29.6574 27.5039 29.6924C27.4931 29.7349 27.4702 29.7735 27.4381 29.8034C27.4059 29.8333 27.3658 29.8533 27.3226 29.861L24.7302 30.2631H24.7686ZM26.2852 28.8499L25.4428 29.6924L26.8948 29.4721L26.2852 28.8499Z"
        fill="#FF6C37"
      />
      <path
        d="M27.3871 29.6145L25.5594 29.8478C25.5105 29.8589 25.4593 29.854 25.4134 29.8337C25.3675 29.8134 25.3294 29.7789 25.3047 29.7352C25.28 29.6916 25.27 29.6411 25.2762 29.5914C25.2824 29.5416 25.3045 29.4951 25.3392 29.4589L26.2856 28.5125L27.3871 29.6145Z"
        fill="#1E2235"
      />
      <path
        d="M25.508 30.0949C25.4232 30.0941 25.3402 30.0709 25.2674 30.0276C25.1946 29.9843 25.1345 29.9225 25.0933 29.8485C25.0438 29.7597 25.0246 29.6573 25.0387 29.5566C25.0527 29.456 25.0992 29.3627 25.1711 29.2909L26.1175 28.3444C26.1632 28.3016 26.2234 28.2777 26.286 28.2777C26.3486 28.2777 26.4088 28.3016 26.4544 28.3444L27.5564 29.4464C27.5892 29.4775 27.6122 29.5174 27.6228 29.5613C27.6334 29.6052 27.6309 29.6512 27.6159 29.6937C27.6008 29.7363 27.5737 29.7736 27.5379 29.801C27.502 29.8285 27.459 29.845 27.414 29.8485L25.5993 30.0818L25.508 30.0949ZM25.508 29.6151L26.8822 29.4464L26.2731 28.8374L25.4953 29.6151H25.508ZM36.7856 20.243C36.7612 20.2021 36.7226 20.1715 36.6772 20.1572C36.6318 20.1429 36.5827 20.1457 36.5392 20.1652C36.5177 20.1761 36.4987 20.1911 36.4831 20.2095C36.4676 20.2279 36.4559 20.2492 36.4488 20.2721C36.4343 20.3185 36.4389 20.3687 36.4614 20.4116C36.5523 20.6027 36.527 20.8287 36.3968 20.995C36.3806 21.011 36.3679 21.0302 36.3595 21.0514C36.3512 21.0725 36.3473 21.0952 36.3481 21.118C36.3486 21.1636 36.3659 21.2074 36.3968 21.2409C36.43 21.2541 36.467 21.2541 36.5003 21.2409C36.5305 21.2485 36.5622 21.2477 36.5919 21.2385C36.6216 21.2294 36.6483 21.2122 36.669 21.1889C36.7757 21.0582 36.8433 20.9 36.864 20.7326C36.8846 20.5651 36.8574 20.3952 36.7856 20.2425V20.243Z"
        fill="#FF6C37"
      />
    </svg>
  );
};

export default PostmanIcon;
