const MailIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95866 5.33301H24.044C24.6288 5.333 25.1334 5.33298 25.5512 5.36147C25.9909 5.39149 26.4308 5.45749 26.8654 5.63749C27.8454 6.04346 28.6242 6.82217 29.0301 7.80228C29.2101 8.23685 29.2761 8.67673 29.3062 9.1165C29.3346 9.53418 29.3346 10.0388 29.3346 10.6237V21.3757C29.3346 21.9605 29.3346 22.4651 29.3062 22.8829C29.2761 23.3226 29.2101 23.7625 29.0301 24.1971C28.6242 25.1771 27.8454 25.9559 26.8654 26.3618C26.4308 26.5418 25.9909 26.6078 25.5512 26.6379C25.1334 26.6663 24.6288 26.6663 24.044 26.6663H7.95865C7.37378 26.6663 6.86914 26.6663 6.45146 26.6379C6.01169 26.6078 5.57181 26.5418 5.13724 26.3618C4.15713 25.9559 3.37842 25.1771 2.97245 24.1971C2.79245 23.7625 2.72645 23.3226 2.69644 22.8829C2.66794 22.4651 2.66796 21.9605 2.66797 21.3757V10.6237C2.66796 10.0388 2.66794 9.53418 2.69644 9.1165C2.72645 8.67673 2.79245 8.23685 2.97245 7.80228C3.37842 6.82217 4.15713 6.04346 5.13724 5.63749C5.57181 5.45749 6.01169 5.39149 6.45146 5.36147C6.86914 5.33298 7.3738 5.333 7.95866 5.33301ZM5.7579 8.37004C6.24282 7.81586 7.08517 7.7597 7.63936 8.24461L15.1233 14.793C15.626 15.2329 16.3766 15.2329 16.8793 14.793L24.3633 8.24461C24.9174 7.7597 25.7598 7.81586 26.2446 8.37004C26.7296 8.92422 26.6734 9.76657 26.1193 10.2515L18.6353 16.7999C17.1272 18.1195 14.8754 18.1195 13.3673 16.7999L5.88334 10.2515C5.32916 9.76657 5.273 8.92422 5.7579 8.37004Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MailIcon;