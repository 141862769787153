export const Analitics = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3779 10C20.138 9.15079 20.6001 8.02937 20.6001 6.8C20.6001 4.14903 18.4511 2 15.8001 2C13.1492 2 11.0001 4.14903 11.0001 6.8C11.0001 8.02937 11.4623 9.15079 12.2224 10H11C10.5582 10 10.2 10.3582 10.2 10.8V11.6C10.2 12.0418 10.5582 12.4 11 12.4H12.1555C13.7103 15.3795 13.3514 19.0152 11.2116 21.638L8.84483 24.5389C8.47808 24.9884 8.45241 25.5493 8.66263 26H7.8C7.35817 26 7 26.3582 7 26.8V29.2C7 29.6418 7.35817 30 7.8 30H23.8C24.2418 30 24.6 29.6418 24.6 29.2V26.8C24.6 26.3582 24.2418 26 23.8 26H22.9372C23.1474 25.5493 23.1217 24.9884 22.755 24.5389L20.3882 21.638C18.2484 19.0152 17.8895 15.3795 19.4443 12.4H20.6C21.0418 12.4 21.4 12.0418 21.4 11.6V10.8C21.4 10.3582 21.0418 10 20.6 10H19.3779Z"
        fill="#A0A0A0"
      />
    </svg>
  );
};
