import cn from 'classnames';
import ArrowRightIcon from '@shared/icons/our-products/arrow-right-icon.tsx';
import { IOurProductCardProps } from '@features/our-products-screen/model/types.ts';

import styles from '@features/our-products-screen/ui/styles.module.css';

const OurProductCard = ({ description, icon, variant = 'black', onClick }: IOurProductCardProps) => {
  return (
    <button className={cn(styles.card, styles[variant])} onClick={onClick}>
      <div className={styles.iconsContainer}>
        <div className={styles.iconWrapper}>{icon}</div>
        <div className={cn(styles.arrowWrapper, styles.iconWrapper)}>
          <ArrowRightIcon />
        </div>
      </div>
      <div className={styles.cardDescription}>{description}</div>
    </button>
  );
};

export default OurProductCard;
