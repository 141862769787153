const LinkIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 4H8.5C6.01472 4 4 6.01471 4 8.49998V23.5C4 25.9853 6.01472 28 8.5 28H23.5C25.9853 28 28 25.9853 28 23.5V17.4999M20.4993 4.00036L28 4M28 4V10.7501M28 4L15.2489 16.7496"
        stroke="#F69E4B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
