import { SectionWhatIsSminexItem } from './types';

export const mockSections: SectionWhatIsSminexItem[] = [
  {
    header: 'ЧТО ТАКОЕ **SMINEX TECH**',
    firstDescription:
      'Sminex Tech – лидер среди ИТ-компаний в области автоматизации девелопмента и строительства. С 2003 года разрабатываем и внедряем решения для эффективного управления проектами, создаем собственное ПО, используя самые прогрессивные технологии: информационное моделирование зданий, цифровые двойники, компьютерное зрение, автономную робототехнику.',
    secondDescription:
      'Мы создаем как внутренние инструменты, так и внешние: сайты, мобильные приложения, корпоративный портал, HR-сервисы, с помощью внутренней экспертизы и собственной команды полного цикла.',
  },
  {
    header: 'Работа в **Sminex TECH — это рост** и новые **возможности** для амбициозных **профессионалов**',
    firstDescription:
      'Мы создаём лучшие условия для профессионального и личного роста, предлагая сотрудникам возможность строить успешную карьеру. Мы всегда оцениваем таланты и навыки по заслугам, а кодекс компании гарантирует поощрение и развитие за успехи и инициативу.',
    secondDescription:
      'В Sminex каждый талант ценится, и мы активно поддерживаем тех, кто стремится к новым высотам. Множество наших лидеров начали путь с младших позиций и выросли в должности и доходе благодаря развитию внутри компании, вместе со Sminex. Присоединяйся к команде, где твои достижения становятся нашей общей победой!',
  },
];
