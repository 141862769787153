import { PropsWithChildren } from 'react';
import gsap from 'gsap';
import useGSAPLenis from '@shared/hooks/useGSAPLenis.tsx';
import { ReactLenis } from 'lenis/dist/lenis-react';
import useHasTouch from '@shared/hooks/useHasTouch.tsx';

const SMOOTH_SCROLL_OPTIONS = {
  duration: 2,
  direction: 'vertical',
  smooth: true,
  smoothWheel: true,
  wheelMultiplier: 0.8,
};

const SmoothScroll = ({ children, gsapModule }: PropsWithChildren<{ gsapModule: typeof gsap }>) => {
  const { lenisRef } = useGSAPLenis({ gsapModule });
  const hasTouch = useHasTouch();

  if (hasTouch) return children;
  return (
    <ReactLenis ref={lenisRef} autoRaf={false} options={SMOOTH_SCROLL_OPTIONS} root>
      {children}
    </ReactLenis>
  );
};

export default SmoothScroll;
