const CssIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.7164 12.4443L39.2172 40.4411L27.9835 43.5554L16.7807 40.4455L14.2842 12.4443H41.7164Z"
        fill="#264DE4"
      />
      <path d="M37.0774 38.6584L39.2131 14.7339H28V41.175L37.0774 38.6584Z" fill="#2965F1" />
      <path d="M20.0078 25.1191L20.3156 28.5533H28.0007V25.1191H20.0078Z" fill="#EBEBEB" />
      <path d="M28.0001 18.168H27.9882H19.3887L19.7009 21.6022H28.0001V18.168Z" fill="#EBEBEB" />
      <path
        d="M28.0004 37.611V34.038L27.9854 34.042L24.1607 33.0092L23.9162 30.2703H22.0577H20.4688L20.9499 35.6625L27.9846 37.6154L28.0004 37.611Z"
        fill="#EBEBEB"
      />
      <path
        d="M32.2171 28.5531L31.8185 33.007L27.9883 34.0408V37.6137L35.0286 35.6625L35.0803 35.0823L35.8873 26.0411L35.9711 25.1189L36.5911 18.168H27.9883V21.6022H32.8275L32.515 25.1189H27.9883V28.5531H32.2171Z"
        fill="#1E2235"
      />
    </svg>
  );
};

export default CssIcon;