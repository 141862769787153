import { IFoxButtonProps } from '@shared/ui/button/model/types.ts';

import styles from '@shared/ui/button/ui/styles.module.css';

const Button = ({
  title,
  size = 'medium',
  variant = 'primary',
  disabled,
  onClick,
  type = 'button',
  icon,
  href,
  target,
}: IFoxButtonProps) => {
  if (href)
    return (
      <a
        className={`${styles.button} ${styles[size]} ${styles[variant]} ${icon ? styles.withIcon : ''}`}
        href={href}
        target={target}
      >
        <span>{title}</span>
        {Boolean(icon) && <span className={styles.iconWrapper}>{icon}</span>}
      </a>
    );

  return (
    <button
      className={`${styles.button} ${styles[size]} ${styles[variant]} ${icon ? styles.withIcon : ''}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <span>{title}</span>
      {Boolean(icon) && <span className={styles.iconWrapper}>{icon}</span>}
    </button>
  );
};
export default Button;
