const ArrowDownBoldIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2 21.6C15.6 22.1333 16.4 22.1333 16.8 21.6L22.8 13.6C23.2944 12.9407 22.824 12 22 12H10C9.17595 12 8.70557 12.9407 9.2 13.6L15.2 21.6Z"
        fill="#F79F4D"
      />
    </svg>
  );
};

export default ArrowDownBoldIcon;
