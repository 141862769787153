const FileIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5549 4V10C17.5549 10.8284 18.2514 11.5 19.1105 11.5H25.3327M17.8218 4H9.77713C8.05891 4 6.66602 5.34315 6.66602 7V25C6.66602 26.6569 8.05891 28 9.77713 28H22.2216C23.9398 28 25.3327 26.6569 25.3327 25V11.2426C25.3327 10.447 25.0049 9.68393 24.4215 9.12132L20.0217 4.87868C19.4382 4.31607 18.6469 4 17.8218 4Z"
        stroke="#F69E4B"
        strokeWidth="2"
      />
    </svg>
  );
};

export default FileIcon;