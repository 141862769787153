import { PointOurTeamItem } from '@features/section-our-team/model/types';
import React, { useRef, useLayoutEffect } from 'react';
import styles from '@entities/team-card/ui/styles.module.css';
import { ArrowRight } from '@shared/icons/arrow-right';
import gsap from 'gsap';
import useIsMobile from '@shared/hooks/useIsMobile';

export const TeamCard: React.FC<PointOurTeamItem> = ({ Icon, cardName, description, onClick }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useIsMobile();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (!isMobile && cardRef.current) {
        const card = cardRef.current;
        const front = card.querySelector<HTMLDivElement>(`.${styles.cardFront}`);
        const back = card.querySelector<HTMLDivElement>(`.${styles.cardBack}`);
        gsap.set(card, {
          transformStyle: 'preserve-3d',
          transformPerspective: 1000,
        });

        gsap.set(back, { rotationY: -180 });

        const tl = gsap
          .timeline({ paused: true })
          .to(front, { duration: 0.75, rotationY: 180 })
          .to(back, { duration: 0.75, rotationY: 0 }, 0);

        const playAnimation = () => tl.play();
        const reverseAnimation = () => tl.reverse();

        card.addEventListener('mouseenter', playAnimation);
        card.addEventListener('mouseleave', reverseAnimation);
        if (onClick) {
          card.addEventListener('click', onClick);
        }

        return () => {
          card.removeEventListener('mouseenter', playAnimation);
          card.removeEventListener('mouseleave', reverseAnimation);
          if (onClick) {
            card.removeEventListener('click', onClick);
          }
        };
      }
    });
    return () => ctx.revert();
  }, [onClick, isMobile]);

  if (isMobile) {
    return (
      <div className={styles.cardCont}>
        <div className={styles.cardFront} onClick={onClick}>
          <div className={styles.iconContainer}>
            <div className={styles.icon}>{Icon && <Icon />}</div>
            <span className={styles.iconWrapper}>
              <ArrowRight />
            </span>
          </div>
          <p className={styles.cardName}>{cardName}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    );
  }

  return (
    <div ref={cardRef} className={styles.cardCont}>
      <div className={styles.cardBack}>
        <p className={styles.description}>{description}</p>
        <span className={styles.iconWrapper}>
          <ArrowRight />
        </span>
      </div>
      <div className={styles.cardFront}>
        <div className={styles.icon}>{Icon && <Icon />}</div>
        <p className={styles.cardName}>{cardName}</p>
      </div>
    </div>
  );
};
