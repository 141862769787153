import { useRef } from 'react';
import ColorTextParser from '@shared/lib/text-parser.tsx';
import FilesList from '@features/feedback-screen/ui/files-list';
import AttachItemsButtons from '@features/feedback-screen/ui/attach-items-buttons';
import { IAttachmentSectionProps } from '@features/feedback-screen/model/types.ts';

import styles from '@features/feedback-screen/ui/styles.module.css';

import { DESCRIPTION_TEXT } from '@features/feedback-screen/api/mock-data.ts';
import { DEFAULT_WHITE_COLOR } from '@shared/lib/constants.ts';

const AttachmentSection = ({
  mainTextColor,
  accentTextColor,
  attachedItems,
  onDeleteHandler,
  openModalHandler,
  setAttachedItems,
}: IAttachmentSectionProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.resumeWrapper}>
      <div className={styles.resumeTitle}>
        {ColorTextParser({
          text: DESCRIPTION_TEXT,
          mainColor: mainTextColor || DEFAULT_WHITE_COLOR,
          secondColor: accentTextColor || DEFAULT_WHITE_COLOR,
        })}
      </div>
      <AttachItemsButtons
        attachedItems={attachedItems}
        inputFileRef={inputFileRef}
        setAttachedItems={setAttachedItems}
        openModalHandler={openModalHandler}
      />
      <FilesList attachedItems={attachedItems} onDeleteHandler={onDeleteHandler} />
    </div>
  );
};

export default AttachmentSection;
