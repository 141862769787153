import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

import styles from './timeline.module.css';

export const Timeline = ({ gsapModule }: { gsapModule: typeof gsap }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    const ctx = gsapModule.context(() => {
      const svg = svgRef.current;
      if (!svg) return;
      const paths = svg.querySelectorAll('path');
      const circles = svg.querySelectorAll('circle');
      const rects = svg.querySelectorAll('rect');

      paths.forEach((path) => {
        const length = path.getTotalLength();
        gsapModule.set(path, {
          strokeDasharray: length,
          strokeDashoffset: length,
        });
      });

      gsapModule.set(circles, { scale: 0, opacity: 0 });
      gsapModule.set(rects, { scale: 0, opacity: 0 });

      const tl = gsapModule.timeline({
        scrollTrigger: {
          trigger: svg,
          start: 'top bottom',
        },
      });

      tl.to(
        paths,
        {
          strokeDashoffset: 0,
          duration: 2,
          ease: 'power1.inOut',
          stagger: {
            each: 0.05,
            from: 'start',
          },
        },
        '=0.3'
      );

      tl.to(
        circles,
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
          stagger: {
            each: 0.1,
            from: 'start',
          },
        },
        '-=2'
      ); // Начинаем анимацию кругов за 2 секунды до окончания анимации линий
      tl.to(
        rects,
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
          stagger: {
            each: 0.1,
            from: 'start',
          },
        },
        '-=2'
      );
    });
    return () => ctx.revert();
  }, []);

  return (
    <svg
      ref={svgRef}
      className={styles.container}
      width="100%"
      height="100%"
      viewBox="0 0 1353 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M22.5486 170.971L150.926 122.686C338.972 51.9588 543.655 38.6512 739.218 84.4375C900.215 122.131 1067.99 119.328 1227.65 76.2775L1333.43 47.7558"
        stroke="url(#paint0_linear_989_828)"
        strokeOpacity="0.01"
      />
      <path
        opacity="0.6"
        d="M21.2774 168.257L132.066 146.187C285.104 115.423 442.588 109.61 597.679 129.069C798.79 152.81 1002.7 137.076 1197.97 82.7017L1331.95 45.387"
        stroke="url(#paint1_linear_989_828)"
        strokeOpacity="0.7"
        strokeWidth="1.875"
      />
      <path
        opacity="0.6"
        d="M21.3717 163.675L134.673 137.86C292.712 101.387 456.939 94.503 617.811 117.724C813.192 143.458 1011.94 129.571 1202.12 76.8143L1332.08 40.7557"
        stroke="url(#paint2_linear_989_828)"
        strokeOpacity="0.6"
        strokeWidth="1.75"
      />
      <path
        opacity="0.6"
        d="M21.4601 159.093L137.274 129.533C300.314 87.3511 471.284 79.3964 637.938 106.378C827.588 134.106 1021.17 122.066 1206.27 70.9268L1332.19 36.1244"
        stroke="url(#paint3_linear_989_828)"
        strokeOpacity="0.5"
        strokeWidth="1.625"
      />
      <path
        opacity="0.6"
        d="M21.6028 156.563L139.929 123.258C307.971 75.3672 485.683 66.3419 658.119 97.0845C842.038 126.805 1030.46 116.613 1210.47 67.0915L1332.37 33.5453"
        stroke="url(#paint4_linear_989_828)"
        strokeOpacity="0.3"
        strokeWidth="1.5"
      />
      <path
        opacity="0.6"
        d="M21.9003 160.144L142.739 123.094C315.782 69.4945 500.237 59.3986 678.455 93.9022C856.644 125.616 1039.91 117.271 1214.83 69.3674L1332.69 37.0773"
        stroke="url(#paint5_linear_989_828)"
        strokeOpacity="0.25"
        strokeWidth="1.375"
      />
      <path
        opacity="0.25"
        d="M22.1185 163.743L145.47 122.948C323.514 63.639 514.712 52.4725 698.711 90.737C871.17 124.444 1049.27 117.946 1219.1 71.6605L1332.94 40.6265"
        stroke="url(#paint6_linear_989_828)"
        strokeOpacity="0.2"
        strokeWidth="1.25"
      />
      <path
        opacity="0.6"
        d="M22.3333 167.352L148.198 122.813C331.243 57.7946 529.183 45.5575 718.964 87.5829C885.692 123.283 1058.63 118.633 1223.38 73.9646L1333.18 44.1868"
        stroke="url(#paint7_linear_989_828)"
        strokeOpacity="0.1"
        strokeWidth="1.125"
      />
      <path
        opacity="0.6"
        d="M21.933 178.07L146.24 191.633C359.661 214.918 575.573 193.105 780.092 127.595L838.048 109.031C997.463 57.9693 1165.48 39.6128 1332.1 55.0535"
        stroke="url(#paint8_linear_989_828)"
        strokeOpacity="0.01"
      />
      <path
        opacity="0.6"
        d="M21.7845 172.943L132.064 158.604C288.274 139.592 447.162 132.716 603.372 138.263L1149.85 90.5952C1290.54 50.463 1311.54 48.1684 1332.37 50.0985"
        stroke="url(#paint9_linear_989_828)"
        strokeOpacity="0.7"
        strokeWidth="1.875"
      />
      <path
        opacity="0.6"
        d="M21.9503 173.468L134.234 163.115C298.617 150.146 465.651 141.135 628.762 136.532L1105.45 93.0214C1248.82 51.3278 1290.82 46.7387 1332.48 50.5988"
        stroke="url(#paint10_linear_989_828)"
        strokeOpacity="0.6"
        strokeWidth="1.75"
      />
      <path
        opacity="0.6"
        d="M22.1032 174.123L136.391 167.756C308.946 160.829 484.127 149.684 654.14 134.93L1061.04 95.5776C1207.08 52.3225 1270.09 45.4388 1332.57 51.2291"
        stroke="url(#paint11_linear_989_828)"
        strokeOpacity="0.5"
        strokeWidth="1.625"
      />
      <path
        opacity="0.6"
        d="M22.0703 174.85L138.362 172.469C319.09 171.585 502.417 158.307 679.331 133.401L1016.44 98.2063C1165.16 53.3899 1249.17 44.2116 1332.48 51.932"
        stroke="url(#paint12_linear_989_828)"
        strokeOpacity="0.3"
        strokeWidth="1.5"
      />
      <path
        opacity="0.6"
        d="M22.0356 175.62L140.331 177.225C329.232 182.383 520.706 166.971 704.521 131.914L971.844 100.877C1123.23 54.4995 1228.24 43.0266 1332.38 52.6771"
        stroke="url(#paint13_linear_989_828)"
        strokeOpacity="0.25"
        strokeWidth="1.375"
      />
      <path
        opacity="0.6"
        d="M22.0004 176.419L142.3 182.009C339.374 193.21 538.994 175.664 729.71 130.457L927.244 103.577C1081.31 55.6378 1207.32 41.8704 1332.29 53.451"
        stroke="url(#paint14_linear_989_828)"
        strokeOpacity="0.2"
        strokeWidth="1.25"
      />
      <path
        opacity="0.6"
        d="M21.9663 177.237L144.27 186.814C349.517 204.057 557.283 184.377 754.901 129.019L882.646 106.297C1039.39 56.7962 1186.4 40.7342 1332.2 54.2448"
        stroke="url(#paint15_linear_989_828)"
        strokeOpacity="0.12"
        strokeWidth="1.125"
      />
      <path
        opacity="0.6"
        d="M19.8163 172.852L158.041 137.977C309.309 99.8113 467.494 98.5526 619.375 134.306L804.92 177.984C990.137 221.584 1184.94 174.192 1329.66 50.3198"
        stroke="#FBB327"
        strokeOpacity="0.01"
      />
      <path
        d="M21.1831 172.838L129.459 154.513C277.496 129.459 428.237 124.716 577.546 140.415C784.388 162.163 993.459 144.58 1193.81 88.5889L1331.83 50.0181"
        stroke="url(#paint16_linear_989_828)"
        strokeWidth="3"
      />
      <g filter="url(#filter0_f_989_828)">
        <path
          d="M21.1831 172.838L129.459 154.513C277.496 129.459 428.237 124.716 577.546 140.415C784.388 162.163 993.459 144.58 1193.81 88.5889L1331.83 50.0181"
          stroke="url(#paint17_linear_989_828)"
          strokeWidth="3"
        />
      </g>
      <path
        opacity="0.6"
        d="M21.6325 172.823L134.9 151.735C283.476 124.49 435.458 120.322 585.194 139.359L1129.67 103.444C1275.55 78.5623 1308.02 70.6568 1332.15 50.0061"
        stroke="url(#paint18_linear_989_828)"
        strokeOpacity="0.3"
        strokeWidth="1.83333"
      />
      <path
        opacity="0.6"
        d="M21.621 172.82L139.881 148.968C288.996 119.533 442.218 115.941 592.381 138.314L1065.07 118.311C1218.82 107.118 1283.75 91.3072 1332 50.0059"
        stroke="url(#paint19_linear_989_828)"
        strokeOpacity="0.3"
        strokeWidth="1.66667"
      />
      <path
        opacity="0.6"
        d="M21.2442 172.825L144.497 146.211C294.151 114.585 448.613 111.568 599.203 137.279L1000.1 133.187C1161.71 135.683 1259.12 111.967 1331.49 50.0147"
        stroke="url(#paint20_linear_989_828)"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path
        opacity="0.6"
        d="M20.7776 172.834L149.022 143.457C299.216 109.64 454.918 107.199 605.934 136.247L935.045 148.066C1104.52 164.252 1234.39 132.63 1330.89 50.0269"
        stroke="url(#paint21_linear_989_828)"
        strokeOpacity="0.12"
        strokeWidth="1.33333"
      />
      <path
        opacity="0.6"
        d="M20.2954 172.843L153.532 140.702C304.265 104.696 461.207 102.829 612.65 135.215L869.972 162.945C1047.31 192.82 1209.65 153.292 1330.27 50.0389"
        stroke="url(#paint22_linear_989_828)"
        strokeOpacity="0.01"
        strokeWidth="1.16667"
      />

      <rect x="1125.5" y="92.5" width="23" height="23" rx="11.5" stroke="#F69E4B" />
      <circle cx="1137" cy="104" r="6" fill="#F69E4B" />
      <path d="M1137 180V116" stroke="url(#paint23_linear_989_828)" />

      <path d="M820.5 72V136" stroke="url(#paint24_linear_989_828)" />
      <rect x="809" y="136.5" width="23" height="23" rx="11.5" stroke="#F69E4B" />
      <circle cx="820.5" cy="148" r="6" fill="#F69E4B" />

      <rect x="457" y="120.5" width="23" height="23" rx="11.5" stroke="#F69E4B" />
      <circle cx="468.5" cy="132" r="6" fill="#F69E4B" />
      <path d="M468.5 208V144" stroke="url(#paint25_linear_989_828)" />

      <path d="M161.5 73V137" stroke="url(#paint26_linear_989_828)" />
      <rect x="150" y="137.5" width="23" height="23" rx="11.5" stroke="#F69E4B" />
      <circle cx="161.5" cy="149" r="6" fill="#F69E4B" />

      <defs>
        <filter
          id="filter0_f_989_828"
          x="0.933594"
          y="28.5735"
          width="1351.3"
          height="165.744"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_989_828" />
        </filter>
        <linearGradient
          id="paint0_linear_989_828"
          x1="20.2294"
          y1="126.544"
          x2="1333.96"
          y2="57.9624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_989_828"
          x1="20.2286"
          y1="148.168"
          x2="1333.74"
          y2="79.5975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_989_828"
          x1="20.23"
          y1="141.806"
          x2="1333.77"
          y2="73.2345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_989_828"
          x1="20.228"
          y1="135.491"
          x2="1333.8"
          y2="66.918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_989_828"
          x1="20.2291"
          y1="130.249"
          x2="1333.83"
          y2="61.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_989_828"
          x1="20.228"
          y1="128.11"
          x2="1333.87"
          y2="59.5332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_989_828"
          x1="20.2292"
          y1="127.551"
          x2="1333.9"
          y2="58.9732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_989_828"
          x1="20.2292"
          y1="127.046"
          x2="1333.93"
          y2="58.4655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_989_828"
          x1="21.2267"
          y1="164.54"
          x2="1334.24"
          y2="95.9959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_989_828"
          x1="20.3532"
          y1="145.525"
          x2="1333.77"
          y2="76.9597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_989_828"
          x1="20.479"
          y1="145.286"
          x2="1333.84"
          y2="76.7234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_989_828"
          x1="20.6017"
          y1="145.361"
          x2="1333.91"
          y2="76.8019"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_989_828"
          x1="20.7268"
          y1="149.115"
          x2="1333.97"
          y2="80.5591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_989_828"
          x1="20.8519"
          y1="152.946"
          x2="1334.04"
          y2="84.3931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_989_828"
          x1="20.9763"
          y1="156.8"
          x2="1334.11"
          y2="88.2495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_989_828"
          x1="21.1013"
          y1="160.666"
          x2="1334.17"
          y2="92.1185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_989_828"
          x1="20.2297"
          y1="154.576"
          x2="1333.71"
          y2="86.0071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69E4B" stopOpacity="0" />
          <stop offset="0.25" stopColor="#F69E4B" />
          <stop offset="0.755" stopColor="#F69E4B" />
          <stop offset="1" stopColor="#F69E4B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_989_828"
          x1="20.2297"
          y1="154.576"
          x2="1333.71"
          y2="86.0071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69E4B" stopOpacity="0" />
          <stop offset="0.25" stopColor="#F69E4B" />
          <stop offset="0.755" stopColor="#F69E4B" />
          <stop offset="1" stopColor="#F69E4B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_989_828"
          x1="20.2303"
          y1="145.963"
          x2="1333.58"
          y2="77.4017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_989_828"
          x1="20.2288"
          y1="146.152"
          x2="1333.44"
          y2="77.5978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_989_828"
          x1="20.2288"
          y1="153.375"
          x2="1333.3"
          y2="84.8272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_989_828"
          x1="20.2279"
          y1="162.304"
          x2="1333.17"
          y2="93.764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_989_828"
          x1="20.2288"
          y1="171.567"
          x2="1333.04"
          y2="103.033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_989_828"
          x1="1136.5"
          y1="180"
          x2="1136.5"
          y2="116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
        <linearGradient id="paint24_linear_989_828" x1="820" y1="72" x2="820" y2="136" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
        <linearGradient id="paint25_linear_989_828" x1="468" y1="208" x2="468" y2="144" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
        <linearGradient id="paint26_linear_989_828" x1="161" y1="73" x2="161" y2="137" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
