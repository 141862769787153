const SminexLogo = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 396 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M332.647 92.7233L357.165 63.4854L333.547 29.8953H346.368C352.666 29.8953 351.767 32.462 362.676 47.9737L366.387 53.3303L381.233 30.6765C381.683 30.0069 382.47 29.7837 383.145 29.7837H390.793L368.524 56.3433L394.279 92.7233H382.245C374.26 92.8349 375.722 89.5987 365.15 74.7565L359.302 66.4985L342.319 91.7189C341.869 92.3885 341.195 92.6117 340.295 92.6117H332.647V92.7233Z"
        fill="white"
      />
      <path
        d="M306.891 93.9504C298.231 93.9504 289.234 91.4953 283.611 84.5764C278.325 78.1039 276.413 70.0691 276.188 61.9226C276.075 51.7675 278.662 43.6211 284.061 37.4833C289.459 31.3456 296.882 28.3325 306.329 28.3325C310.828 28.3325 314.876 29.0021 318.363 30.3412C321.849 31.6804 324.549 33.4659 326.686 35.8094C328.822 38.1529 330.397 40.8312 331.409 43.8442C332.421 46.8573 332.984 50.2051 333.096 53.7762C333.096 55.6733 332.984 57.4588 332.646 59.0212C332.646 59.2443 332.421 59.3559 332.309 59.3559H292.72C292.945 69.5111 294.52 76.7648 297.444 81.3402C300.368 85.9156 305.204 88.1475 312.065 88.1475C319.15 88.1475 324.661 86.1388 330.06 82.0097C330.959 82.7909 331.971 84.9112 330.06 86.5851C325.111 91.049 315.439 93.9504 306.891 93.9504ZM292.608 56.3429L318.25 54.4458C318.363 45.9645 318.026 32.0151 306.666 32.0151C294.745 32.0151 292.945 47.3037 292.608 56.3429Z"
        fill="white"
      />
      <path
        d="M266.292 85.4699V48.6435C266.292 36.0333 261.456 28.4448 248.972 28.4448C240.2 28.4448 231.878 32.5738 225.917 38.7115C225.917 33.8014 225.129 29.7839 220.293 29.7839H210.171L210.734 36.9261V85.3583L210.171 92.612H226.592L226.029 85.3583V42.0594C230.753 38.4884 235.139 36.7029 241.437 36.7029C249.422 36.7029 250.997 44.0681 250.997 52.2146V84.1308C250.997 89.4873 252.234 92.612 256.733 92.612H266.855L266.292 85.4699Z"
        fill="white"
      />
      <path
        d="M194.313 11.5935C194.313 16.0573 190.601 19.6283 186.103 19.6283C181.604 19.6283 177.893 16.0573 177.893 11.5935C177.893 7.12965 181.604 3.55859 186.103 3.55859C190.601 3.55859 194.313 7.12965 194.313 11.5935Z"
        fill="white"
      />
      <path
        d="M178.454 29.7834L179.016 36.9256V85.3578L178.454 92.6115H194.874L194.312 85.3578V38.1531C194.312 33.3545 193.412 29.6719 188.576 29.6719H178.454V29.7834Z"
        fill="white"
      />
      <path
        d="M162.936 85.4699V48.6435C162.936 35.9217 158.1 28.4448 145.616 28.4448C135.156 28.4448 128.071 33.6898 122.448 39.6043C120.423 32.5738 115.475 28.4448 106.027 28.4448C97.1426 28.4448 89.9447 32.5738 84.0964 38.8232V38.7115C84.0964 33.8014 83.3092 29.7839 78.4731 29.7839H68.3511L68.9134 36.9261V85.3583L68.3511 92.612H84.7712L84.2089 85.3583V42.0594C88.8201 38.2652 94.3309 36.7029 98.7171 36.7029C107.377 36.7029 108.277 45.5189 108.277 52.2146V85.3583L107.714 92.612H124.135L123.572 85.3583V42.0594C127.509 38.7115 133.02 36.7029 138.193 36.7029C147.19 36.7029 147.753 46.4116 147.753 52.2146V84.1308C147.753 90.1569 149.44 92.612 153.489 92.612H163.611L162.936 85.4699Z"
        fill="white"
      />
      <path
        d="M26.8495 93.9506C19.3143 93.9506 8.18004 91.8303 1.43202 88.0361C2.66916 85.2462 5.03096 83.1259 8.40497 84.9114C14.4782 88.0361 20.5514 89.4868 27.2994 89.4868C36.9716 89.4868 44.057 84.1302 43.6071 74.4215C42.5949 54.1112 0.532289 51.0981 0.644756 26.6588C0.757223 8.69198 17.0649 3.55859 32.6978 3.55859C40.2331 3.55859 47.6559 5.00936 54.9663 7.91083C53.7291 10.8123 51.5922 13.2674 47.8808 11.4818C42.7074 9.02676 37.5339 7.91083 32.023 7.91083C22.4633 7.91083 17.0649 13.0442 17.0649 20.6327C16.9524 40.0502 59.6899 42.9517 60.4771 68.1722C60.927 86.5853 45.4066 93.9506 26.8495 93.9506Z"
        fill="white"
      />
    </svg>
  );
};

export default SminexLogo;
