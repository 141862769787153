const GitIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.9687 26.614L29.3853 13.0313C28.6035 12.249 27.3348 12.249 26.552 13.0313L23.7313 15.8519L27.3095 19.4301C28.141 19.1491 29.0945 19.3376 29.7573 20.0004C30.4233 20.6675 30.6104 21.6291 30.3227 22.4633L33.771 25.9118C34.6053 25.6244 35.5679 25.8103 36.2342 26.4777C37.1655 27.4086 37.1655 28.9174 36.2342 29.8489C35.3027 30.7805 33.794 30.7805 32.862 29.8489C32.1615 29.1477 31.9884 28.119 32.3431 27.256L29.1272 24.0401L29.1268 32.503C29.3599 32.6182 29.5729 32.7705 29.7573 32.9538C30.6886 33.8847 30.6886 35.3936 29.7573 36.3259C28.8258 37.257 27.3163 37.257 26.3861 36.3259C25.4549 35.3936 25.4549 33.8848 26.3861 32.9539C26.6093 32.7307 26.8745 32.5538 27.1665 32.4337V23.892C26.8741 23.7726 26.6087 23.5957 26.3861 23.3717C25.6805 22.6671 25.5109 21.6318 25.8724 20.7654L22.3452 17.2378L13.0313 26.5511C12.2487 27.3341 12.2487 28.603 13.0313 29.3854L26.615 42.9683C27.397 43.7507 28.6655 43.7507 29.4485 42.9683L42.9686 29.4485C43.751 28.6658 43.751 27.3962 42.9686 26.614"
        fill="#EF4444"
      />
    </svg>
  );
};

export default GitIcon;
