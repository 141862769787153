import { ChangeEvent } from 'react';
import { Button } from '@features/button';
import {
  AttachedItemTypes,
  FeedbackScreenModalsVariants,
  IAttachItemsButtonsProps,
} from '@features/feedback-screen/model/types.ts';

import styles from '@features/feedback-screen/ui/styles.module.css';

import { ALLOWED_FILE_EXTENSIONS } from '@features/feedback-screen/lib/constants.ts';

const AttachItemsButtons = ({
  attachedItems,
  inputFileRef,
  setAttachedItems,
  openModalHandler,
}: IAttachItemsButtonsProps) => {
  const onFileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setAttachedItems((prev) => [
      ...prev,
      ...Array.from(e.target.files || [])
        .filter((file) => ALLOWED_FILE_EXTENSIONS.some((el) => file.name.endsWith(el)))
        .map((file) => ({
          type: AttachedItemTypes.File,
          fileInfo: file,
          id: String(Math.round(Math.random() * Date.now()).toString(16)),
        })),
    ]);
  };

  const onFileBtnClick = () => {
    inputFileRef?.current?.click();
  };

  return (
    <div className={styles.resumeBtnsWrapper}>
      <input
        type="file"
        accept=".pdf, .doc, .docx"
        multiple
        onChange={onFileChangeHandler}
        ref={inputFileRef}
        className={styles.hidden}
        key={attachedItems.length}
      />
      <Button title="Резюме (pdf,docx)" variant="accent" size="medium" onClick={onFileBtnClick} />
      <Button title="Ссылка" size="medium" onClick={() => openModalHandler(FeedbackScreenModalsVariants.AttachLink)} />
    </div>
  );
};

export default AttachItemsButtons;
