const TechLogo = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 231 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M213.397 26.1371C224.703 26.1371 230.355 31.8755 230.355 43.3522V90.2441H219.307V46.4355C219.307 42.2388 218.622 39.3696 217.251 37.828C215.967 36.2007 213.697 35.387 210.442 35.387C206.588 35.387 202.734 36.2863 198.88 38.0849C195.026 39.8835 190.444 42.4957 185.134 45.9216L184.62 38.0849C189.502 34.4021 194.341 31.4901 199.137 29.3489C203.933 27.2077 208.687 26.1371 213.397 26.1371ZM186.29 0.314453C186.358 13.3893 186.418 26.4635 186.418 39.5385L186.29 41.6201V90.2441H175.37V0.314453H186.29Z"
        fill="white"
      />
      <path
        d="M140.674 26.1377C142.558 26.1377 144.656 26.2233 146.969 26.3946C149.281 26.5659 151.637 26.8657 154.035 27.2939C156.518 27.7222 158.788 28.3217 160.844 29.0925L159.43 36.1584C156.604 35.9015 153.606 35.7302 150.437 35.6445C147.354 35.4732 144.699 35.3876 142.472 35.3876C137.505 35.3876 133.565 36.0728 130.653 37.4431C127.741 38.8135 125.643 41.2116 124.358 44.6375C123.073 48.0634 122.431 52.8168 122.431 58.8978C122.431 64.9787 123.073 69.775 124.358 73.2865C125.643 76.7124 127.741 79.1105 130.653 80.4809C133.565 81.8512 137.505 82.5364 142.472 82.5364C143.757 82.5364 145.427 82.4936 147.483 82.408C149.538 82.3223 151.722 82.1938 154.035 82.0225C156.433 81.8512 158.702 81.5943 160.844 81.2517L162 88.5746C158.831 89.688 155.362 90.5016 151.594 91.0155C147.911 91.5294 144.228 91.7863 140.545 91.7863C133.351 91.7863 127.57 90.7157 123.202 88.5746C118.834 86.4334 115.665 82.9647 113.695 78.1684C111.811 73.3722 110.868 66.9486 110.868 58.8978C110.868 50.8469 111.853 44.4234 113.823 39.6271C115.793 34.8309 118.962 31.405 123.33 29.3495C127.698 27.2083 133.479 26.1377 140.674 26.1377Z"
        fill="white"
      />
      <path
        d="M70.643 26.1377C80.0642 26.1377 86.8732 27.8506 91.0699 31.2765C95.3522 34.7024 97.4934 39.7984 97.4934 46.5646C97.5791 52.1316 96.4228 56.4997 94.0247 59.6686C91.6266 62.7519 87.8153 64.2935 82.5908 64.2935H56.5734L60.7789 55.8145H79.7644C82.5908 55.8145 84.4322 54.8724 85.2887 52.9881C86.1452 51.0182 86.5734 48.877 86.5734 46.5646C86.4877 42.5391 85.2887 39.67 82.9762 37.957C80.6637 36.2441 76.7239 35.3876 71.1569 35.3876C66.6176 35.3876 63.0204 36.0728 60.3653 37.4431C57.7959 38.7278 55.9545 41.0403 54.8411 44.3806C53.8133 47.6352 53.2994 52.2173 53.2994 58.1269C53.2994 64.8074 53.9418 69.9034 55.2265 73.415C56.5968 76.9265 58.738 79.3246 61.65 80.6094C64.562 81.8941 68.4162 82.5364 73.2124 82.5364C76.5527 82.5364 80.1927 82.408 84.1325 82.151C88.1579 81.8084 91.7551 81.423 94.924 80.9948L96.0802 88.3176C94.1104 89.0884 91.6694 89.7308 88.7574 90.2447C85.931 90.7586 82.9762 91.144 79.8929 91.4009C76.8952 91.6579 74.1974 91.7863 71.7992 91.7863C64.6049 91.7863 58.8237 90.7157 54.4556 88.5746C50.0876 86.3477 46.8759 82.8362 44.8203 78.0399C42.8504 73.158 41.8655 66.7345 41.8655 58.7693C41.8655 50.5472 42.8504 44.0808 44.8203 39.3702C46.8759 34.5739 50.002 31.1909 54.1987 29.221C58.4811 27.1655 63.9625 26.1377 70.643 26.1377Z"
        fill="white"
      />
      <path
        d="M11.693 9.17969V73.0297C11.693 76.0274 12.3353 78.1686 13.62 79.4533C14.9047 80.738 17.0887 81.3803 20.172 81.3803H29.6789L30.9636 89.731C29.5932 90.0736 28.0088 90.3733 26.2102 90.6303C24.4116 90.8872 22.6558 91.0585 20.9429 91.1441C19.2299 91.3154 17.8596 91.4011 16.8318 91.4011C11.6073 91.4011 7.62473 89.9451 4.88402 87.0331C2.14331 84.1211 0.772949 79.9243 0.772949 74.4429V9.17969H11.693ZM31.606 27.6795V36.2871H14.7518L19.2249 27.6795H31.606Z"
        fill="white"
      />
    </svg>
  );
};

export default TechLogo;
