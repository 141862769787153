import React, { useRef } from 'react';
import cn from 'classnames';
import useClickOutside from '@shared/hooks/useClickOutside/useClickOutside.ts';

import H2Heading from '@shared/ui/h2-heading';
import CustomCursor from '@shared/custom-cursor/custom-cursor';
import { Button } from '@shared/ui/button';
import { Close } from '@shared/icons/close';
import { ArrowBack } from '@shared/icons/arrow-back';
import { ArrowUpRight } from '@shared/icons/arrow-up-right';
import { PointOurTeamItem } from '@features/section-our-team/model/types';

import styles from './styles.module.css';

const MAIN_TEXT_COLOR = '#A0A0A0';
const ACCENT_TEXT_COLOR = '#fff';

const OurTeamModal = ({
  closeModalHandler,
  selectedCard,
  onApplyClick,
  isOpen,
}: {
  closeModalHandler: () => void;
  selectedCard: PointOurTeamItem | null;
  onApplyClick?: () => void;
  isOpen?: boolean;
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef(null);
  useClickOutside(contentRef, closeModalHandler);

  const handleApplyClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onApplyClick?.();
    closeModalHandler();
  };

  return (
    <div className={cn(styles.modalContainer, isOpen ? styles.isDarken : '')}>
      <div className={styles.innerContainer}>
        <div className={styles.emptySpace} ref={modalRef}>
          <CustomCursor containerRef={modalRef} className={styles.customCursorPaddings}>
            <Close />
          </CustomCursor>
        </div>
        <div className={cn(styles.modalLinkContentWrapper, isOpen ? styles.visible : '')} ref={contentRef}>
          <div className={styles.headingContainer}>
            <button className={styles.iconWrapper} onClick={closeModalHandler}>
              <ArrowBack />
            </button>
            <H2Heading
              heading={selectedCard?.listTitle}
              accentTextColor={ACCENT_TEXT_COLOR}
              mainTextColor={MAIN_TEXT_COLOR}
            />
            <div className={styles.spaceFiller} />
          </div>
          <ul className={styles.list}>
            {Boolean(selectedCard) &&
              selectedCard?.vacancies?.map((vacancy, index) => (
                <li key={index} className={styles.vacancyContain}>
                  <h3 className={styles.titleVacancy}>{vacancy.titleVacancy}</h3>
                  <div className={styles.vacancyBtn}>
                    <Button title="Откликнуться" variant="accent" onClick={(e) => handleApplyClick(e)} />
                    <Button
                      title="Смотреть вакансию на hh"
                      icon={<ArrowUpRight />}
                      href={vacancy.hhLink}
                      target="_blank"
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurTeamModal;
