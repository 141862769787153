import { PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';

import styles from '@shared/custom-cursor/custom-cursor.module.css';
import classNames from 'classnames';

function CustomCursor({
  containerRef,
  children,
  className,
}: PropsWithChildren<{ containerRef: RefObject<HTMLDivElement | null>; className?: string }>) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const cursorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef?.current;
    if (!container) return;

    const handleMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      const rect = container.getBoundingClientRect();
      const cursor = cursorRef?.current?.getBoundingClientRect();
      setPosition({
        x: e.clientX - rect.left - (cursor?.width || 0) / 2,
        y: e.clientY - rect.top - (cursor?.height || 0) / 2,
      });
    };

    const handleMouseEnter = () => {
      setIsVisible(true);
    };

    const handleMouseLeave = () => {
      setIsVisible(false);
    };

    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [containerRef]);

  return (
    <div
      ref={cursorRef}
      className={classNames(styles.customCursor, className)}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        opacity: isVisible ? 1 : 0,
      }}
    >
      {children}
    </div>
  );
}

export default CustomCursor;
