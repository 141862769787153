const FigmaIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.8151 43.5554C25.6773 43.5554 28.0003 41.2325 28.0003 38.3702V33.1851H22.8151C19.9528 33.1851 17.6299 35.508 17.6299 38.3702C17.6299 41.2325 19.9528 43.5554 22.8151 43.5554Z"
        fill="#0ACF83"
      />
      <path
        d="M17.6299 27.9999C17.6299 25.1377 19.9528 22.8147 22.8151 22.8147H28.0003V33.185H22.8151C19.9528 33.185 17.6299 30.8621 17.6299 27.9999Z"
        fill="#A259FF"
      />
      <path
        d="M17.6299 17.6295C17.6299 14.7673 19.9528 12.4443 22.8151 12.4443H28.0003V22.8147H22.8151C19.9528 22.8147 17.6299 20.4917 17.6299 17.6295Z"
        fill="#F24E1E"
      />
      <path
        d="M28 12.4443H33.1852C36.0474 12.4443 38.3704 14.7673 38.3704 17.6295C38.3704 20.4917 36.0474 22.8147 33.1852 22.8147H28V12.4443Z"
        fill="#FF7262"
      />
      <path
        d="M38.3704 27.9999C38.3704 30.8621 36.0474 33.185 33.1852 33.185C30.323 33.185 28 30.8621 28 27.9999C28 25.1377 30.323 22.8147 33.1852 22.8147C36.0474 22.8147 38.3704 25.1377 38.3704 27.9999Z"
        fill="#1ABCFE"
      />
    </svg>
  );
};

export default FigmaIcon;