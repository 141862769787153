import cn from 'classnames';
import { IOurProductInfoCardProps } from '@features/our-products-screen/model/types.ts';

import styles from '@features/our-products-screen/ui/styles.module.css';

const OurProductInfoCard = ({ mainTitle, extraTitle }: IOurProductInfoCardProps) => {
  return (
    <div className={cn(styles.card, styles.cardTransparent)}>
      <div className={styles.cardNumber}>{mainTitle}</div>
      <div>{extraTitle}</div>
    </div>
  );
};

export default OurProductInfoCard;
