import styles from '@features/marquee-line/ui/marquee-line.module.css';

const MarqueeLine = ({
  techStackArray,
  isReversed,
}: {
  techStackArray: { title: string; icon: JSX.Element }[];
  isReversed?: boolean;
}) => {
  return (
    <div className={styles.marqueesWrapper}>
      <div className={`${styles.marquee} ${isReversed ? styles.reverseAnimated : ''}`}>
        {techStackArray.map((icon) => (
          <div className={styles.card} key={icon.title}>
            <div className={styles.icon}>{icon.icon}</div>
            <div className={styles.title}>{icon.title}</div>
          </div>
        ))}
      </div>
      <div className={`${styles.marquee} ${isReversed ? styles.reverseAnimated : ''}`}>
        {techStackArray.map((icon) => (
          <div className={styles.card} key={icon.title}>
            <div className={styles.icon}>{icon.icon}</div>
            <div className={styles.title}>{icon.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarqueeLine;
