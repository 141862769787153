import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

export const TimelineMobile = ({ gsapModule }: { gsapModule: typeof gsap }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    const ctx = gsapModule.context(() => {
      const svg = svgRef.current;
      if (!svg) return;
      const paths = svg.querySelectorAll('path');
      const circles = svg.querySelectorAll('circle');
      const rects = svg.querySelectorAll('rect');

      paths.forEach((path) => {
        const length = path.getTotalLength();
        gsapModule.set(path, {
          strokeDasharray: length,
          strokeDashoffset: length,
        });
      });

      gsapModule.set(circles, { scale: 0, opacity: 0 });
      gsapModule.set(rects, { scale: 0, opacity: 0 });

      const tl = gsapModule.timeline({
        scrollTrigger: {
          trigger: svg,
          start: 'top bottom',
        },
      });

      tl.to(
        paths,
        {
          strokeDashoffset: 0,
          duration: 2,
          ease: 'power1.inOut',
          stagger: {
            each: 0.05,
            from: 'start',
          },
        },
        '=0.3'
      );

      tl.to(
        circles,
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
          stagger: {
            each: 0.1,
            from: 'start',
          },
        },
        '-=2'
      );
      tl.to(
        rects,
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
          stagger: {
            each: 0.1,
            from: 'start',
          },
        },
        '-=2'
      );
    });
    return () => ctx.revert();
  }, []);

  return (
    <svg ref={svgRef} width="100%" height="100%" viewBox="0 0 355 708" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1137_2048)">
        <path
          opacity="0.6"
          d="M254.755 2.98868L280.811 72.1372C318.977 173.426 326.158 283.676 301.451 389.013C281.11 475.732 282.623 566.103 305.854 652.101L321.245 709.076"
          stroke="url(#paint0_linear_1137_2048)"
          strokeOpacity="0.01"
        />
        <path
          opacity="0.6"
          d="M256.22 2.30376L268.129 61.9784C284.73 144.41 287.867 229.237 277.366 312.775C264.555 421.1 273.046 530.933 302.387 636.111L322.523 708.282"
          stroke="url(#paint1_linear_1137_2048)"
          strokeOpacity="0.7"
          strokeWidth="1.875"
        />
        <path
          opacity="0.6"
          d="M258.693 2.35442L272.623 63.3825C292.304 148.508 296.019 236.967 283.489 323.619C269.602 428.857 277.096 535.91 305.564 638.348L325.022 708.348"
          stroke="url(#paint2_linear_1137_2048)"
          strokeOpacity="0.6"
          strokeWidth="1.75"
        />
        <path
          opacity="0.6"
          d="M261.165 2.40263L277.116 64.7841C299.878 152.604 304.171 244.694 289.611 334.46C274.649 436.612 281.146 540.885 308.741 640.583L327.522 708.412"
          stroke="url(#paint3_linear_1137_2048)"
          strokeOpacity="0.5"
          strokeWidth="1.625"
        />
        <path
          opacity="0.6"
          d="M262.53 2.47921L280.502 66.2141C306.345 156.727 311.216 252.45 294.626 345.33C278.588 444.396 284.088 545.888 310.811 642.846L328.913 708.504"
          stroke="url(#paint4_linear_1137_2048)"
          strokeOpacity="0.3"
          strokeWidth="1.5"
        />
        <path
          opacity="0.6"
          d="M260.598 2.6401L280.591 67.7284C309.514 160.936 314.962 260.29 296.343 356.284C279.23 452.263 283.733 550.975 309.583 645.193L327.007 708.681"
          stroke="url(#paint5_linear_1137_2048)"
          strokeOpacity="0.25"
          strokeWidth="1.375"
        />
        <path
          opacity="0.25"
          d="M258.656 2.75698L280.67 69.1987C312.674 165.1 318.7 268.086 298.051 367.194C279.862 460.087 283.369 556.019 308.346 647.497L325.092 708.813"
          stroke="url(#paint6_linear_1137_2048)"
          strokeOpacity="0.2"
          strokeWidth="1.25"
        />
        <path
          opacity="0.6"
          d="M256.708 2.87271L280.743 70.6678C315.828 169.263 322.431 275.881 299.753 378.103C280.488 467.909 282.998 561.061 307.102 649.799L323.171 708.944"
          stroke="url(#paint7_linear_1137_2048)"
          strokeOpacity="0.1"
          strokeWidth="1.125"
        />
        <path
          opacity="0.6"
          d="M250.924 2.65675L243.606 69.6132C231.04 184.569 242.811 300.867 278.162 411.029L288.179 442.246C315.734 528.113 325.639 618.612 317.307 708.362"
          stroke="url(#paint8_linear_1137_2048)"
          strokeOpacity="0.01"
        />
        <path
          opacity="0.6"
          d="M253.691 2.57758L261.429 61.9782C271.688 146.118 275.399 231.701 272.405 315.842L298.128 610.195C319.784 685.977 321.022 697.289 319.981 708.508"
          stroke="url(#paint9_linear_1137_2048)"
          strokeOpacity="0.7"
          strokeWidth="1.875"
        />
        <path
          opacity="0.6"
          d="M253.408 2.66597L258.994 63.146C265.993 151.688 270.855 241.659 273.339 329.517L296.819 586.279C319.317 663.502 321.794 686.127 319.711 708.564"
          stroke="url(#paint10_linear_1137_2048)"
          strokeOpacity="0.6"
          strokeWidth="1.75"
        />
        <path
          opacity="0.6"
          d="M253.054 2.7492L256.49 64.3087C260.228 157.253 266.242 251.612 274.204 343.187L295.439 562.358C318.781 641.022 322.495 674.959 319.371 708.615"
          stroke="url(#paint11_linear_1137_2048)"
          strokeOpacity="0.5"
          strokeWidth="1.625"
        />
        <path
          opacity="0.6"
          d="M252.662 2.73084L253.947 65.3697C254.424 162.717 261.589 261.463 275.029 356.755L294.021 538.336C318.205 618.44 323.158 663.69 318.991 708.565"
          stroke="url(#paint12_linear_1137_2048)"
          strokeOpacity="0.3"
          strokeWidth="1.5"
        />
        <path
          opacity="0.6"
          d="M252.246 2.71169L251.381 66.43C248.597 168.179 256.914 271.314 275.831 370.323L292.579 514.313C317.606 595.858 323.797 652.42 318.589 708.513"
          stroke="url(#paint13_linear_1137_2048)"
          strokeOpacity="0.25"
          strokeWidth="1.375"
        />
        <path
          opacity="0.6"
          d="M251.816 2.69335L248.799 67.491C242.755 173.642 252.223 281.165 276.618 383.892L291.123 490.29C316.992 573.276 324.421 641.15 318.172 708.463"
          stroke="url(#paint14_linear_1137_2048)"
          strokeOpacity="0.2"
          strokeWidth="1.25"
        />
        <path
          opacity="0.6"
          d="M251.374 2.67508L246.206 68.5521C236.902 179.106 247.521 291.016 277.394 397.46L289.655 466.268C316.367 550.694 325.034 629.881 317.743 708.412"
          stroke="url(#paint15_linear_1137_2048)"
          strokeOpacity="0.12"
          strokeWidth="1.125"
        />
        <path
          opacity="0.6"
          d="M253.741 1.52122L272.549 75.9726C293.131 157.449 293.798 242.654 274.493 324.467L250.909 424.414C227.367 524.183 252.926 629.107 319.757 707.051"
          stroke="#FBB327"
          strokeOpacity="0.01"
        />
        <path
          d="M253.748 2.25311L263.636 60.5744C277.156 140.312 279.716 221.507 271.244 301.93C259.508 413.343 268.996 525.956 299.211 633.874L320.024 708.216"
          stroke="url(#paint16_linear_1137_2048)"
          strokeWidth="3"
        />
        <g filter="url(#filter0_f_1137_2048)">
          <path
            d="M253.748 2.25311L263.636 60.5744C277.156 140.312 279.716 221.507 271.244 301.93C259.508 413.343 268.996 525.956 299.211 633.874L320.024 708.216"
            stroke="url(#paint17_linear_1137_2048)"
            strokeWidth="3"
          />
        </g>
        <path
          opacity="0.6"
          d="M253.756 2.49491L265.135 63.5052C279.837 143.534 282.086 225.396 271.814 306.05L291.194 599.325C304.621 677.903 308.887 695.391 320.031 708.385"
          stroke="url(#paint18_linear_1137_2048)"
          strokeOpacity="0.3"
          strokeWidth="1.83333"
        />
        <path
          opacity="0.6"
          d="M253.758 2.48916L266.628 66.1884C282.512 146.507 284.451 229.038 272.378 309.921L283.172 564.529C289.212 647.342 297.744 682.318 320.031 708.306"
          stroke="url(#paint19_linear_1137_2048)"
          strokeOpacity="0.3"
          strokeWidth="1.66667"
        />
        <path
          opacity="0.6"
          d="M253.755 2.28614L268.116 68.6744C285.182 149.284 286.81 232.482 272.936 313.595L275.144 529.535C273.797 616.584 286.595 669.049 320.026 708.031"
          stroke="url(#paint20_linear_1137_2048)"
          strokeOpacity="0.2"
          strokeWidth="1.5"
        />
        <path
          opacity="0.6"
          d="M253.75 2.03478L269.603 71.112C287.851 152.012 289.168 235.878 273.493 317.221L267.115 494.493C258.381 585.778 275.445 655.73 320.019 707.707"
          stroke="url(#paint21_linear_1137_2048)"
          strokeOpacity="0.12"
          strokeWidth="1.33333"
        />
        <path
          opacity="0.6"
          d="M253.745 1.77513L271.089 73.5413C290.519 154.732 291.526 239.266 274.05 320.839L259.086 459.442C242.965 554.964 264.295 642.404 320.013 707.374"
          stroke="url(#paint22_linear_1137_2048)"
          strokeOpacity="0.01"
          strokeWidth="1.16667"
        />

        <path style={{ animationDelay: '3s' }} d="M213.955 129H261.955" stroke="url(#paint23_linear_1137_2048)" />
        <rect
          style={{ animationDelay: '2s' }}
          x="262.455"
          y="117.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="#F69E4B"
        />
        <circle cx="273.955" cy="129" r="6" fill="#F69E4B" />

        <path style={{ animationDelay: '3s' }} d="M212.955 279H260.955" stroke="url(#paint24_linear_1137_2048)" />
        <rect
          style={{ animationDelay: '2s' }}
          x="261.455"
          y="267.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="#F69E4B"
        />
        <circle cx="272.955" cy="279" r="6" fill="#F69E4B" />

        <path style={{ animationDelay: '3s' }} d="M206.955 429H254.955" stroke="url(#paint25_linear_1137_2048)" />
        <rect
          style={{ animationDelay: '2s' }}
          x="255.455"
          y="417.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="#F69E4B"
        />
        <circle cx="266.955" cy="429" r="6" fill="#F69E4B" />

        <path style={{ animationDelay: '3s' }} d="M228.955 594H276.955" stroke="url(#paint26_linear_1137_2048)" />
        <rect
          style={{ animationDelay: '2s' }}
          x="277.455"
          y="582.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="#F69E4B"
        />
        <circle cx="288.955" cy="594" r="6" fill="#F69E4B" />
      </g>
      <defs>
        <filter
          id="filter0_f_1137_2048"
          x="232.269"
          y="-17.9966"
          width="109.2"
          height="746.616"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_1137_2048" />
        </filter>
        <linearGradient
          id="paint0_linear_1137_2048"
          x1="278.729"
          y1="1.73946"
          x2="315.602"
          y2="709.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1137_2048"
          x1="267.06"
          y1="1.73888"
          x2="303.928"
          y2="709.251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1137_2048"
          x1="270.493"
          y1="1.73951"
          x2="307.361"
          y2="709.269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1137_2048"
          x1="273.901"
          y1="1.73899"
          x2="310.77"
          y2="709.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1137_2048"
          x1="276.73"
          y1="1.7393"
          x2="313.6"
          y2="709.302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1137_2048"
          x1="277.884"
          y1="1.73934"
          x2="314.755"
          y2="709.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1137_2048"
          x1="278.185"
          y1="1.73933"
          x2="315.057"
          y2="709.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1137_2048"
          x1="278.458"
          y1="1.73933"
          x2="315.331"
          y2="709.353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1137_2048"
          x1="258.226"
          y1="2.2763"
          x2="295.079"
          y2="709.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1137_2048"
          x1="268.486"
          y1="1.80661"
          x2="305.351"
          y2="709.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1137_2048"
          x1="268.616"
          y1="1.87351"
          x2="305.479"
          y2="709.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1137_2048"
          x1="268.575"
          y1="1.94046"
          x2="305.436"
          y2="709.341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1137_2048"
          x1="266.549"
          y1="2.00719"
          x2="303.409"
          y2="709.377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1137_2048"
          x1="264.482"
          y1="2.07413"
          x2="301.34"
          y2="709.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1137_2048"
          x1="262.402"
          y1="2.14169"
          x2="299.259"
          y2="709.448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1137_2048"
          x1="260.316"
          y1="2.20911"
          x2="297.171"
          y2="709.484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1137_2048"
          x1="263.603"
          y1="1.73958"
          x2="300.469"
          y2="709.235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69E4B" stopOpacity="0" />
          <stop offset="0.25" stopColor="#F69E4B" />
          <stop offset="0.755" stopColor="#F69E4B" />
          <stop offset="1" stopColor="#F69E4B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1137_2048"
          x1="263.603"
          y1="1.73958"
          x2="300.469"
          y2="709.235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69E4B" stopOpacity="0" />
          <stop offset="0.25" stopColor="#F69E4B" />
          <stop offset="0.755" stopColor="#F69E4B" />
          <stop offset="1" stopColor="#F69E4B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1137_2048"
          x1="268.25"
          y1="1.73964"
          x2="305.113"
          y2="709.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1137_2048"
          x1="268.148"
          y1="1.7393"
          x2="305.007"
          y2="709.089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1137_2048"
          x1="264.251"
          y1="1.73922"
          x2="301.106"
          y2="709.017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1137_2048"
          x1="259.432"
          y1="1.7387"
          x2="296.283"
          y2="708.944"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1137_2048"
          x1="254.434"
          y1="1.73925"
          x2="291.281"
          y2="708.872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB327" stopOpacity="0" />
          <stop offset="0.25" stopColor="#FBB327" />
          <stop offset="0.75" stopColor="#FBB327" stopOpacity="0.5" />
          <stop offset="1" stopColor="#FBB327" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1137_2048"
          x1="213.955"
          y1="129.5"
          x2="261.955"
          y2="129.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1137_2048"
          x1="212.955"
          y1="279.5"
          x2="260.955"
          y2="279.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1137_2048"
          x1="206.955"
          y1="429.5"
          x2="254.955"
          y2="429.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1137_2048"
          x1="228.955"
          y1="594.5"
          x2="276.955"
          y2="594.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F79F4D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
