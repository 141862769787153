import { PointsOurTeamProps } from './types';
import { Analitics } from '@shared/icons/analitics';
import { FrontendDev } from '@shared/icons/frontend-dev';
import { BackendDev } from '@shared/icons/backend-dev';
import { QA } from '@shared/icons/qa';
import { Design } from '@shared/icons/design';

export const mockPointsOurTeam: PointsOurTeamProps = {
  title: 'Наша **команда**',
  subtitle: 'Нанимаем лучших специалистов в своей сфере',
  buttonText: 'Другие специалисты',
  pointOurTeamContent: [
    {
      cardName: 'Аналитики',
      Icon: Analitics,
      description:
        'UML, BPMN, Sequence, Archimate, Asciidoc, Confluence, Tableu + PowerBI, Draw.IO, MS Visio, USM, UserFlowChart',
      listTitle: 'Вакансии отдела **Аналитики**',
      vacancies: [
        {
          titleVacancy: 'Руководитель группы аналитики данных',
          hhLink: 'https://hh.ru/vacancy/105784168?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Инженер данных',
          hhLink: 'https://hh.ru/vacancy/105784168?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Data-аналитик',
          hhLink: 'https://hh.ru/vacancy/105784168?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'бизнес-аналитик',
          hhLink: 'https://hh.ru/vacancy/105784168?from=employer&hhtmFrom=employer',
        },
      ],
    },
    {
      cardName: 'Frontend developers',
      Icon: FrontendDev,
      description: 'ReactJS, Vue, NextJS, TypeScript, Canvas, NestJS, Swift, Kotlin, React Native',
      listTitle: 'Вакансии отдела **Frontend developers**',
      vacancies: [
        {
          titleVacancy: 'Junior Frontend Developer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Senior React Developer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Full Stack JavaScript Developer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
      ],
    },
    {
      cardName: 'Backend developers',
      Icon: BackendDev,
      description: '1C, C#, Rust, PHP, Python, GoLang, Zabbix, Prometheus, GitLab, SonarQube',
      listTitle: 'Вакансии отдела **Backend developers**',
      vacancies: [
        {
          titleVacancy: 'Python Backend Developer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Node.js Developer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Go (Golang) Backend Engineer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Ruby on Rails Developer',
          hhLink: 'https://hh.ru/vacancy/107852170?from=employer&hhtmFrom=employer',
        },
      ],
    },
    {
      cardName: 'QA - инженеры',
      Icon: QA,
      description:
        'Функциональное и Нефункциональное тестирование, Автотесты, Browserstack, Zephyr, Postman Jenkins, Selenium, Gatling',
        listTitle: 'Вакансии отдела **QA - инженеры**',
      vacancies: [
        {
          titleVacancy: 'Senior QA Automation Engineer',
          hhLink: 'https://hh.ru/vacancy/106334157?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Manual QA Tester',
          hhLink: 'https://hh.ru/vacancy/106334157?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Performance Test Engineer',
        },
        {
          titleVacancy: 'Mobile QA Specialist',
          hhLink: 'https://hh.ru/vacancy/106334157?from=employer&hhtmFrom=employer',
        },
      ],
    },
    {
      cardName: 'Дизайнеры',
      Icon: Design,
      description: 'Figma, Анализ конкурентов, CJM, Прототипирование',
      listTitle: 'Вакансии отдела **Дизайнеры**',
      vacancies: [
        {
          titleVacancy: 'Senior UI/UX Designer',
          hhLink: 'https://hh.ru/vacancy/104925876?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Graphic Designer',
          hhLink: 'https://hh.ru/vacancy/104925876?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Product Designer',
          hhLink: 'https://hh.ru/vacancy/104925876?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'Visual Designer',
          hhLink: 'https://hh.ru/vacancy/104925876?from=employer&hhtmFrom=employer',
        },
        {
          titleVacancy: 'UI Designer (Mobile Applications)',
          hhLink: 'https://hh.ru/vacancy/104925876?from=employer&hhtmFrom=employer',
        },
      ],
    },
  ],
};
