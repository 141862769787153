export const ArrowUpRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.45875 7.0808L16.9807 7.08068M16.9807 7.08068L16.9807 15.4815M16.9807 7.08068L7.08125 16.9802"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
