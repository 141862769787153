import { IOurProductModalCardProps } from '@features/our-products-screen/model/types.ts';
import styles from '@features/our-products-screen/ui/our-products-modal/styles.module.css';

const OurProductModalCard = ({ num, title, description }: IOurProductModalCardProps) => {
  return (
    <li className={styles.listItem}>
      <div className={styles.listItemNum}>{num}.</div>
      <div className={styles.listItemTitle}>{title}</div>
      <div className={styles.listItemDesc}>{description}</div>
    </li>
  );
};

export default OurProductModalCard;
