import { useRef } from 'react';
import classNames from 'classnames';
import { Button } from '@features/button';
import useClickOutside from '@shared/hooks/useClickOutside/useClickOutside.ts';
import { ISubmittedFormProps } from '@features/feedback-screen/model/types.ts';

import styles from '@features/feedback-screen/ui/styles.module.css';

const SubmittedModal = ({ closeModalHandler }: ISubmittedFormProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(modalRef, closeModalHandler);

  return (
    <div className={classNames(styles.modalContainer)}>
      <div className={styles.modalSuccessWrapper} ref={modalRef}>
        <div className={styles.heading}>Ваше резюме успешно отправлено</div>
        <div className={styles.description}>
          Спасибо вам за то, что откликнулись на нашу вакансию. Мы ценим внимание уделённое нашей компании
        </div>
        <div className={styles.closeBtn}>
          <Button title="Закрыть" variant="accent" onClick={closeModalHandler} />
        </div>
      </div>
    </div>
  );
};

export default SubmittedModal;
