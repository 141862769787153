const VueIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.3332 14.5833H43.5554L27.9999 41.4166L12.4443 14.5833H24.3443L27.9999 20.8055L31.5777 14.5833H37.3332Z"
        fill="#41B883"
      />
      <path
        d="M12.4443 14.5833L27.9999 41.4166L43.5554 14.5833H37.3332L27.9999 30.6833L18.5888 14.5833H12.4443Z"
        fill="#41B883"
      />
      <path d="M18.5889 14.5833L28 30.761L37.3333 14.5833H31.5778L28 20.8055L24.3444 14.5833H18.5889Z" fill="#35495E" />
    </svg>
  );
};

export default VueIcon;
