import { useState } from 'react';
import { ControlProps } from '../controls/controls';

import { VolumeUp } from '@shared/ui/icons/VolumeUp';
import { VolumeOff } from '@shared/ui/icons/VolumeOff';

import styles from './volume-control.module.css';

export const VolumeControl = ({ controlProps }: ControlProps) => {
  const [showVolumeSlider, setShowVolumeSlider] = useState(false); // Добавляем состояние для видимости полосы громкости
  const { volume, setVolume } = controlProps;

  const handleVolumeToggle = () => {
    setVolume(volume === 0 ? 1 : 0);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(parseFloat(e.target.value));
  };

  const getVolumeSliderStyle = () => {
    const volumePercentage = volume * 100; // Уровень громкости в процентах
    return {
      background: `linear-gradient(to right, rgb(110, 110, 246) ${volumePercentage}%, #ccc ${volumePercentage}%)`,
    };
  };
  return (
    <div
      className={styles.volumeControl}
      onMouseEnter={() => setShowVolumeSlider(true)}
      onMouseLeave={() => setShowVolumeSlider(false)}
      onClick={() => {
        setTimeout(() => {
          setShowVolumeSlider(false);
        }, 10000);
      }}
    >
      <button onClick={handleVolumeToggle} className={styles.controlButton}>
        {volume === 0 ? <VolumeOff /> : <VolumeUp />}
      </button>
      <div className={styles.volumeSliderWrap}>
        {showVolumeSlider && (
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className={styles.volumeSlider}
            style={getVolumeSliderStyle()}
          />
        )}
      </div>
    </div>
  );
};
