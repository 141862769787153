const SminexLogo = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 224 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M188.471 52.7625L202.389 36.0351L188.981 16.8178H196.26C199.835 16.8178 199.324 18.2862 205.517 27.1607L207.624 30.2252L216.052 17.2647C216.307 16.8816 216.754 16.7539 217.137 16.7539H221.479L208.837 31.949L223.458 52.7625H216.626C212.093 52.8264 212.923 50.9749 206.922 42.4835L203.602 37.759L193.961 52.1879C193.706 52.571 193.323 52.6987 192.812 52.6987H188.471V52.7625Z"
        fill="white"
      />
      <path
        d="M173.85 53.4647C168.934 53.4647 163.826 52.0601 160.634 48.1017C157.633 44.3987 156.548 39.8019 156.42 35.1412C156.356 29.3313 157.825 24.6706 160.889 21.1591C163.954 17.6477 168.167 15.9238 173.53 15.9238C176.084 15.9238 178.383 16.3069 180.362 17.073C182.341 17.8392 183.873 18.8607 185.086 20.2015C186.299 21.5422 187.193 23.0745 187.768 24.7983C188.343 26.5221 188.662 28.4375 188.726 30.4805C188.726 31.5659 188.662 32.5874 188.47 33.4812C188.47 33.6089 188.343 33.6728 188.279 33.6728H165.805C165.933 39.4827 166.827 43.6326 168.487 46.2502C170.147 48.8679 172.892 50.1448 176.787 50.1448C180.809 50.1448 183.937 48.9956 187.002 46.6333C187.513 47.0802 188.087 48.2933 187.002 49.251C184.193 51.8048 178.702 53.4647 173.85 53.4647ZM165.741 31.9489L180.298 30.8636C180.362 26.0113 180.17 18.0307 173.722 18.0307C166.954 18.0307 165.933 26.7775 165.741 31.9489Z"
        fill="white"
      />
      <path
        d="M150.802 48.6121V27.5433C150.802 20.3288 148.057 15.9873 140.97 15.9873C135.99 15.9873 131.265 18.3496 127.882 21.861C127.882 19.0519 127.435 16.7534 124.689 16.7534H118.943L119.263 20.8395V48.5483L118.943 52.6982H128.265L127.946 48.5483V23.7764C130.627 21.7334 133.117 20.7118 136.692 20.7118C141.225 20.7118 142.119 24.9256 142.119 29.5863V47.846C142.119 50.9106 142.821 52.6982 145.375 52.6982H151.121L150.802 48.6121Z"
        fill="white"
      />
      <path
        d="M109.941 6.34685C109.941 8.90066 107.834 10.9437 105.28 10.9437C102.726 10.9437 100.619 8.90066 100.619 6.34685C100.619 3.79305 102.726 1.75 105.28 1.75C107.834 1.75 109.941 3.79305 109.941 6.34685Z"
        fill="white"
      />
      <path
        d="M100.939 16.7533L101.259 20.8394V48.5481L100.939 52.6981H110.261L109.942 48.5481V21.5417C109.942 18.7963 109.431 16.6895 106.686 16.6895H100.939V16.7533Z"
        fill="white"
      />
      <path
        d="M92.1293 48.6121V27.5433C92.1293 20.2649 89.3839 15.9873 82.2971 15.9873C76.3595 15.9873 72.3373 18.988 69.145 22.3718C67.9958 18.3496 65.1866 15.9873 59.8236 15.9873C54.7799 15.9873 50.6938 18.3496 47.3739 21.9249V21.861C47.3739 19.0519 46.9269 16.7534 44.1816 16.7534H38.4355L38.7548 20.8395V48.5483L38.4355 52.6982H47.7569L47.4377 48.5483V23.7764C50.0554 21.6057 53.1838 20.7118 55.6737 20.7118C60.5898 20.7118 61.1005 25.7556 61.1005 29.5863V48.5483L60.7813 52.6982H70.1027L69.7835 48.5483V23.7764C72.0181 21.861 75.1465 20.7118 78.0833 20.7118C83.191 20.7118 83.5102 26.2664 83.5102 29.5863V47.846C83.5102 51.2936 84.4679 52.6982 86.7663 52.6982H92.5123L92.1293 48.6121Z"
        fill="white"
      />
      <path
        d="M14.876 53.4645C10.5984 53.4645 4.27775 52.2515 0.447041 50.0807C1.14934 48.4846 2.49008 47.2716 4.40543 48.2931C7.85307 50.0807 11.3007 50.9107 15.1314 50.9107C20.6221 50.9107 24.6443 47.8461 24.3889 42.2916C23.8143 30.6718 -0.0637173 28.948 0.000127802 14.9659C0.0639729 4.68688 9.32151 1.75 18.196 1.75C22.4736 1.75 26.6874 2.58 30.8373 4.23997C30.135 5.89995 28.9219 7.30453 26.8151 6.283C23.8782 4.87841 20.9413 4.23997 17.8129 4.23997C12.3861 4.23997 9.3215 7.17684 9.3215 11.5183C9.25766 22.6274 33.5188 24.2873 33.9657 38.7163C34.2211 49.2508 25.4105 53.4645 14.876 53.4645Z"
        fill="white"
      />
    </svg>
  );
};

export default SminexLogo;
