import styles from './controls.module.css';
import { Pause } from '@shared/ui/icons/Pause';
import { Play } from '@shared/ui/icons/Play';
import ReactPlayer from 'react-player';
import { VolumeControl } from '@features/video-player';
import { ProgressBar } from '../progress-bar/progress-bar';
import { ScreenSwitcher } from '../screen-switcher/screen-switcher';

interface ControlTypes {
  isIPhone: boolean;
  isPlaying: boolean;
  setIsPlaying: (item: boolean) => void;
  showControls: boolean;
  volume: number;
  currentTime: number;
  duration: number;
  playerRef: ReactPlayer | null;
  setVolume: (el: number) => void;
  isLive: boolean;
  setCurrentTime: (el: number) => void;
  playerContainerRef: HTMLDivElement | null;
  isFullscreen: boolean;
}

export interface ControlProps {
  controlProps: ControlTypes;
}

export const Controls = ({ controlProps }: ControlProps) => {
  const { isPlaying, setIsPlaying, showControls } = controlProps;

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      {
        // Кастомные контролы только для не-iPhone устройств
        <>
          {showControls && (
            <div className={styles.controls}>
              <button onClick={handlePlayPause} className={styles.controlButton}>
                {isPlaying ? <Pause /> : <Play />}
              </button>
              <VolumeControl controlProps={controlProps} />
              <ProgressBar controlProps={controlProps} />
              <ScreenSwitcher controlProps={controlProps} />
            </div>
          )}
        </>
      }
    </>
  );
};
