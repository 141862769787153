export const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
      <path
        d="M17.7765 6.6665L26.6654 15.9998M26.6654 15.9998L17.7765 25.3332M26.6654 15.9998L5.33203 15.9998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
