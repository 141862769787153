import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Input } from '@features/input';
import { Button } from '@features/button';
import ColorTextParser from '@shared/lib/text-parser.tsx';
import useClickOutside from '@shared/hooks/useClickOutside/useClickOutside.ts';
import { ILinkAttachModalProps } from '@features/feedback-screen/model/types.ts';

import styles from '@features/feedback-screen/ui/styles.module.css';

import { DEFAULT_WHITE_COLOR } from '@shared/lib/constants.ts';
import { MODAL_HEADING } from '@features/feedback-screen/api/mock-data.ts';

const LinkAttachModal = ({
  mainTextColor,
  accentTextColor,
  closeModalHandler,
  onAttachLinkHandler,
}: ILinkAttachModalProps) => {
  const [input, setInput] = useState('');
  const modalRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(modalRef, closeModalHandler);

  const onSaveHandler = () => {
    onAttachLinkHandler(input);
    setInput('');
    closeModalHandler?.();
  };

  return (
    <div className={classNames(styles.modalContainer)}>
      <div className={styles.modalLinkContentWrapper} ref={modalRef}>
        <div className={styles.heading}>
          {ColorTextParser({
            text: MODAL_HEADING,
            mainColor: mainTextColor || DEFAULT_WHITE_COLOR,
            secondColor: accentTextColor || DEFAULT_WHITE_COLOR,
          })}
        </div>
        <Input
          type="text"
          placeholder="Прикрепите ссылку"
          onChange={(e) => setInput(e.target.value)}
          onEnterPress={onSaveHandler}
        />
        <div className={styles.btnsContainer}>
          <Button title="Прикрепить" variant="accent" onClick={onSaveHandler} />
          <Button title="Отмена" variant="primary" onClick={closeModalHandler} />
        </div>
      </div>
    </div>
  );
};

export default LinkAttachModal;
