import { FormEvent, forwardRef, useEffect, useState } from 'react';
import {
  AttachedItemType,
  AttachedItemTypes,
  FeedbackScreenModalsVariants,
  IFeedbackScreenProps,
} from '@features/feedback-screen/model/types.ts';
import { Modal } from '@shared/modal';
import SubmittedModal from '@features/feedback-screen/ui/submited-form';
import LinkAttachModal from '@features/feedback-screen/ui/link-attach-form';
import H2Heading from '@shared/ui/h2-heading';
import CheckboxSubmitSection from '@features/feedback-screen/ui/checkbox-submit-section';
import AttachmentSection from '@features/feedback-screen/ui/attachment-section';
import InputsSection from '@features/feedback-screen/ui/inputs-section';
import { emailValidator, phoneValidator, phraseValidator } from '@features/feedback-screen/lib/validator.ts';

import styles from './styles.module.css';

import { INITIAL_INPUTS, INITIAL_INPUTS_ERRORS } from '@features/feedback-screen/model/initialValues.ts';
import { ESCAPE_KEY_NAME } from '@features/feedback-screen/lib/constants.ts';

const FeedbackScreen = forwardRef<HTMLFormElement, IFeedbackScreenProps>(
  ({ heading, dropdownOptions, mainTextColor, accentTextColor }, ref) => {
    const [inputs, setInputs] = useState<Record<string, string>>(INITIAL_INPUTS);
    const [inputsErrors, setInputsErrors] = useState<Record<string, string | boolean>>(INITIAL_INPUTS_ERRORS);
    const [isAgreeChecked, setIsAgreeChecked] = useState(false);
    const [isRequestBtnDisabled, setRequestBtnDisabled] = useState(true);
    const [attachedItems, setAttachedItems] = useState<AttachedItemType[]>([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalVariant, setModalVariant] = useState<FeedbackScreenModalsVariants>(
      FeedbackScreenModalsVariants.AttachLink
    );

    useEffect(() => {
      const close = (e: KeyboardEvent) => {
        if (e.key === ESCAPE_KEY_NAME) {
          closeModalHandler();
        }
      };
      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
    }, []);

    useEffect(() => {
      const { firstName: firstNameError, lastName: lastNameError, email: emailError, phone: phoneError } = inputsErrors;
      const { firstName, lastName, email, phone, departmentId } = inputs;
      const isDisabled =
        !!firstNameError ||
        !!lastNameError ||
        !!emailError ||
        !!phoneError ||
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !departmentId ||
        !isAgreeChecked;

      setRequestBtnDisabled(isDisabled);
    }, [inputs, inputsErrors, isAgreeChecked]);

    const onDeleteHandler = (item: AttachedItemType) => {
      setAttachedItems(attachedItems.filter((el) => el !== item));
    };

    const openModalHandler = (variant: FeedbackScreenModalsVariants) => {
      setModalVariant(variant);
      setModalOpen(true);
    };

    const closeModalHandler = () => {
      setModalOpen(false);
    };

    const onAttachLinkHandler = (link: string) => {
      setAttachedItems((prev) => [
        ...prev,

        { type: AttachedItemTypes.Link, link, id: String(Math.round(Math.random() * Date.now())) },
      ]);
    };

    const submitHandler = async (e: FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const { firstName, lastName, email, phone } = inputs;

      const phoneError = await phoneValidator(phone);
      const emailError = await emailValidator(email);
      const firstNameError = await phraseValidator(firstName);
      const lastNameError = await phraseValidator(lastName);

      if (phoneError || emailError || firstNameError || lastNameError) {
        setInputsErrors({
          ...inputsErrors,
          firstName: firstNameError,
          lastName: lastNameError,
          email: emailError,
          phone: phoneError,
        });
        return;
      }

      openModalHandler(FeedbackScreenModalsVariants.Success);
      setAttachedItems([]);
      setIsAgreeChecked(false);
      setInputs(INITIAL_INPUTS);
      setInputsErrors(INITIAL_INPUTS_ERRORS);
    };

    return (
      <>
        <form ref={ref} className={styles.container} onSubmit={submitHandler}>
          <H2Heading heading={heading} mainTextColor={mainTextColor} accentTextColor={accentTextColor} />
          <div className={styles.content}>
            <InputsSection
              inputs={inputs}
              setInputs={setInputs}
              inputsErrors={inputsErrors}
              setInputsErrors={setInputsErrors}
              dropdownOptions={dropdownOptions}
            />
            <AttachmentSection
              setAttachedItems={setAttachedItems}
              accentTextColor={accentTextColor}
              mainTextColor={mainTextColor}
              attachedItems={attachedItems}
              onDeleteHandler={onDeleteHandler}
              openModalHandler={openModalHandler}
            />
          </div>
          <CheckboxSubmitSection
            isChecked={isAgreeChecked}
            setIsChecked={setIsAgreeChecked}
            isBtnDisabled={isRequestBtnDisabled}
          />
        </form>

        <Modal isOpen={isModalOpen} onClose={closeModalHandler}>
          {isModalOpen && modalVariant === FeedbackScreenModalsVariants.AttachLink && (
            <LinkAttachModal
              mainTextColor={mainTextColor}
              accentTextColor={accentTextColor}
              closeModalHandler={closeModalHandler}
              onAttachLinkHandler={onAttachLinkHandler}
            />
          )}
          {isModalOpen && modalVariant === FeedbackScreenModalsVariants.Success && (
            <SubmittedModal closeModalHandler={closeModalHandler} />
          )}
        </Modal>
      </>
    );
  }
);

export default FeedbackScreen;
