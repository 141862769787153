import { useState, useEffect } from 'react';

const useHasTouch = () => {
  const [hasTouch, setHasTouch] = useState<boolean>(false);

  useEffect(() => {
    const checkTouchSupport = () => {
      if (typeof navigator !== 'undefined') {
        if ('maxTouchPoints' in navigator) {
          return navigator.maxTouchPoints > 0;
        } else {
          return window.matchMedia('(pointer: coarse)').matches;
        }
      }
      return false;
    };

    setHasTouch(checkTouchSupport());

    const handleTouchChange = () => {
      setHasTouch(checkTouchSupport());
    };

    window.addEventListener('resize', handleTouchChange);
    window.addEventListener('orientationchange', handleTouchChange);

    return () => {
      window.removeEventListener('resize', handleTouchChange);
      window.removeEventListener('orientationchange', handleTouchChange);
    };
  }, []);

  return hasTouch;
};

export default useHasTouch;
