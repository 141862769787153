import React from 'react';
import { DropdownOption } from '@features/dropdown/model/types.ts';

export interface IFeedbackScreenProps {
  heading?: string;
  dropdownOptions?: DropdownOption[];
  mainTextColor?: string;
  accentTextColor?: string;
}

export interface IAttachItemsButtonsProps {
  attachedItems: AttachedItemType[];
  inputFileRef: React.RefObject<HTMLInputElement>;
  setAttachedItems: React.Dispatch<React.SetStateAction<AttachedItemType[]>>;
  openModalHandler: (variant: FeedbackScreenModalsVariants) => void;
}

export enum AttachedItemTypes {
  File,
  Link,
}

export type AttachedFileType = {
  type: AttachedItemTypes;
  fileInfo: File;
  id: string;
};

export type AttachedLinkType = {
  type: AttachedItemTypes;
  link: string;
  id: string;
};

export type AttachedItemType = AttachedFileType | AttachedLinkType;

export interface IFilesListProps {
  attachedItems: AttachedItemType[];
  onDeleteHandler: (item: AttachedItemType) => void;
}

export interface ISubmittedFormProps {
  closeModalHandler: () => void;
}

export interface ILinkAttachModalProps {
  mainTextColor?: string;
  accentTextColor?: string;
  closeModalHandler: () => void;
  onAttachLinkHandler: (value: string) => void;
}

export enum FeedbackScreenModalsVariants {
  AttachLink,
  Success,
}

export interface IAttachmentSectionProps {
  mainTextColor?: string;
  accentTextColor?: string;
  attachedItems: AttachedItemType[];
  onDeleteHandler: (item: AttachedItemType) => void;
  openModalHandler: (variant: FeedbackScreenModalsVariants) => void;
  setAttachedItems: React.Dispatch<React.SetStateAction<AttachedItemType[]>>;
}

export interface IInputsSectionProps {
  inputs: Record<string, string>;
  setInputs: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  inputsErrors: Record<string, string | boolean>;
  setInputsErrors: React.Dispatch<React.SetStateAction<Record<string, string | boolean>>>;
  dropdownOptions?: DropdownOption[];
}

export interface ICheckboxSubmitSectionProps {
  isChecked?: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isBtnDisabled?: boolean;
}
