import { Header } from '@features/header';
import { Outlet } from 'react-router-dom';
import { Footer } from '@features/footer';

export const MainLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
