import { PointForTimelineItem } from './types';

export const mockPoints: PointForTimelineItem[] = [
  {
    nameBtn: 'Основание Sminex',
    yearBtn: '2007',
  },
  {
    nameBtn: 'Создание IT-департамента',
    yearBtn: '2013',
  },
  {
    nameBtn: 'Покупка компании Inteko',
    yearBtn: '2022',
  },
  {
    nameBtn: 'Sminex tech самостоятельная IT-компания',
    yearBtn: '2024',
  },
];
