import HTMLIcon from '@shared/icons/marquee-icons/html-icon.tsx';
import CssIcon from '@shared/icons/marquee-icons/css-icon.tsx';
import FigmaIcon from '@shared/icons/marquee-icons/figma-icon.tsx';
import RustIcon from '@shared/icons/marquee-icons/rust-icon.tsx';
import JsIcon from '@shared/icons/marquee-icons/js-icon.tsx';
import VueIcon from '@shared/icons/marquee-icons/vue-icon.tsx';
import ReactIcon from '@shared/icons/marquee-icons/react-icon.tsx';
import SwiftIcon from '@shared/icons/marquee-icons/swift-icon.tsx';
import PhpIcon from '@shared/icons/marquee-icons/php-icon.tsx';
import JiraIcon from '@shared/icons/marquee-icons/jira-icon.tsx';
import VscodeIcon from '@shared/icons/marquee-icons/vscode-icon.tsx';
import GithubIcon from '@shared/icons/marquee-icons/github-icon.tsx';
import GitIcon from '@shared/icons/marquee-icons/git-icon.tsx';
import DockerIcon from '@shared/icons/marquee-icons/docker-icon.tsx';
import PostmanIcon from '@shared/icons/marquee-icons/postman-icon.tsx';
import BitbucketIcon from '@shared/icons/marquee-icons/bitbucket-icon.tsx';

export const TECH_STACK_UPPER = [
  { title: 'rust', icon: <RustIcon /> },
  { title: 'js', icon: <JsIcon /> },
  { title: 'vue js', icon: <VueIcon /> },
  { title: 'react js', icon: <ReactIcon /> },
  { title: 'swift', icon: <SwiftIcon /> },
  { title: 'php', icon: <PhpIcon /> },
  { title: 'css', icon: <CssIcon /> },
  { title: 'html 5', icon: <HTMLIcon /> },
];

export const TECH_STACK_LOWER = [
  { title: 'figma', icon: <FigmaIcon /> },
  { title: 'jira', icon: <JiraIcon /> },
  { title: 'vscode', icon: <VscodeIcon /> },
  { title: 'github', icon: <GithubIcon /> },
  { title: 'git', icon: <GitIcon /> },
  { title: 'docker', icon: <DockerIcon /> },
  { title: 'postman', icon: <PostmanIcon /> },
  { title: 'bitbucket', icon: <BitbucketIcon /> },
];
