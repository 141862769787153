import IconWithText from '@features/footer/ui/icon-with-text';
import styles from './styles.module.css';

import SminexLogo from '@shared/icons/footer/sminex-logo.tsx';
import {
  FOOTER_COMPANY_REQUISITES,
  FOOTER_COPYRIGHT,
  FOOTER_LEFT_INFO_SECTION_ITEMS,
  FOOTER_RIGHT_INFO_SECTION_ITEMS,
} from '@features/footer/api/footer-mock-data.tsx';

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.logoWrapper}>
        <SminexLogo />
      </div>
      <div className={styles.infoSection}>
        <div className={styles.firstIconsContainer}>
          {FOOTER_LEFT_INFO_SECTION_ITEMS.map((item, index) => (
            <IconWithText key={index} icon={item.icon} color={item.color} title={item.title} href={item.href} />
          ))}
        </div>
        <div className={styles.secondInfoContainer}>
          <div>{FOOTER_COMPANY_REQUISITES}</div>
          <div className={styles.careerIconsContainer}>
            {FOOTER_RIGHT_INFO_SECTION_ITEMS.map((item, index) => (
              <IconWithText key={index} icon={item.icon} color={item.color} title={item.title} href={item.href} />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.copyRight}>{FOOTER_COPYRIGHT}</div>
    </footer>
  );
};

export default Footer;
