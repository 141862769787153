const ArrowLeftIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4431 5.8335L4.66537 14.0002M4.66537 14.0002L12.4431 22.1668M4.66537 14.0002L23.332 14.0002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
