const BitbucketIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.454 14.0123C13.3085 14.0104 13.1644 14.0404 13.0317 14.1002C12.899 14.1599 12.7811 14.248 12.6861 14.3582C12.5911 14.4684 12.5214 14.5981 12.4818 14.7381C12.4423 14.8781 12.4339 15.0251 12.4572 15.1687L16.6887 40.8563C16.7414 41.1707 16.9032 41.4564 17.1456 41.6633C17.3881 41.8702 17.6957 41.9851 18.0144 41.9877H38.3144C38.5533 41.9908 38.7853 41.9079 38.9682 41.7542C39.1512 41.6005 39.2728 41.3863 39.311 41.1504L43.5426 15.1737C43.5659 15.0301 43.5574 14.8831 43.5179 14.7431C43.4783 14.6031 43.4086 14.4735 43.3136 14.3633C43.2186 14.2531 43.1006 14.1651 42.968 14.1053C42.8353 14.0456 42.6912 14.0156 42.5457 14.0175L13.454 14.0123ZM31.2719 32.5778H24.7926L23.0382 23.4122H32.8419L31.2719 32.5778Z"
        fill="#2684FF"
      />
      <path
        d="M42.1712 23.3994H32.8238L31.255 32.5577H24.781L17.1367 41.6313C17.379 41.8408 17.6879 41.9573 18.0082 41.9599H38.2969C38.5357 41.963 38.7676 41.8802 38.9504 41.7267C39.1332 41.5731 39.2548 41.359 39.293 41.1233L42.1712 23.3994Z"
        fill="url(#paint0_linear_182_490)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_182_490"
          x1="44.3324"
          y1="25.9641"
          x2="33.5347"
          y2="41.2956"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BitbucketIcon;
