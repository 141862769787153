import { useEffect } from 'react';

const useHideScroll = (isHideScroll?: boolean) => {
  useEffect(() => {
    if (isHideScroll) {
      console.log('hidding');
      document.body.classList.add('modalNoScroll'); // Добавляем класс
    } else {
      console.log('unHidding');
      document.body.classList.remove('modalNoScroll'); // Убираем класс
    }

    return () => {
      console.log('clearing');
      document.body.classList.remove('modalNoScroll'); // Чистим класс при размонтировании
    };
  }, [isHideScroll]);

  return null;
};

export default useHideScroll;
