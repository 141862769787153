import { Button } from '@features/button';
import Checkbox from '@features/checkbox/ui';
import { ICheckboxSubmitSectionProps } from '@features/feedback-screen/model/types.ts';

import styles from '@features/feedback-screen/ui/styles.module.css';

const CheckboxSubmitSection = ({ isChecked, setIsChecked, isBtnDisabled }: ICheckboxSubmitSectionProps) => {
  return (
    <div className={styles.agreementWrapper}>
      <Checkbox isChecked={isChecked} setIsChecked={setIsChecked}>
        <div className={styles.agreementText}>
          <span>Я даю согласие на обработку </span>
          <a href="/docs/agreement.pdf" className={styles.orange} target="_blank">
            персональных данных
          </a>
        </div>
      </Checkbox>
      <div className={styles.submitBtn}>
        <Button title="Откликнуться" variant="accent" type="submit" disabled={isBtnDisabled} />
      </div>
    </div>
  );
};

export default CheckboxSubmitSection;
