import styles from './styles.module.css';
import { PointForTimelineProps } from '../../model/types';
import { useLayoutEffect, useRef } from 'react';

export const PointForTimeline: React.FC<PointForTimelineProps> = ({ pointContent, gsapModule }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ctx = gsapModule.context(() => {
      if (wrapperRef.current) {
        const boxes = wrapperRef.current.querySelectorAll('[data-box]');

        const tl = gsapModule.timeline({
          scrollTrigger: {
            trigger: wrapperRef.current,
            start: 'top bottom-=100',
            once: true,
          },
        });

        boxes.forEach((box, index) => {
          gsapModule.set(box, { opacity: 0 });

          tl.to(
            box,
            {
              opacity: 1,
              ease: 'power2.out',
            },
            index === 0 ? '+=2' : '+=0.1'
          );
        });
      }
    });
    return () => ctx.revert();
  }, [pointContent]);

  return (
    <div ref={wrapperRef} className={styles.pointForTimelineWrapper}>
      {pointContent.map((point, index) => (
        <div key={index} className={`${index % 2 === 0 ? styles.pointForTimelineTop : styles.pointForTimelineBottom}`}>
          {index % 2 === 0 ? (
            <div className={styles.box} data-box>
              <button className={styles.btn}>{point.nameBtn}</button>
              <div className={styles.year}>{point.yearBtn}</div>
            </div>
          ) : (
            <div className={styles.box} data-box>
              <div className={styles.year}>{point.yearBtn}</div>
              <button className={styles.btn}>{point.nameBtn}</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
