const HTMLIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7778 40.4549L14.2822 12.4443H41.7181L39.2225 40.4398L27.9775 43.5554" fill="#E44D26" />
      <path d="M28 41.1735V14.7434H39.2148L37.0747 38.6401" fill="#F16529" />
      <path
        d="M19.3789 18.1689H27.9999V21.6022H23.1449L23.4625 25.1187H27.9999V28.5444H20.3166L19.3789 18.1689ZM20.4679 30.2685H23.9163L24.1583 33.0136L27.9999 34.0421V37.6266L20.9519 35.6604"
        fill="#EBEBEB"
      />
      <path
        d="M36.5902 18.1689H27.9844V21.6022H36.2726L36.5902 18.1689ZM35.9626 25.1187H27.9844V28.5519H32.2192L31.8184 33.0137L27.9844 34.0421V37.6115L35.0173 35.6604"
        fill="#1E2235"
      />
    </svg>

  );
};

export default HTMLIcon;