import * as stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex.ts';

export const styles = stylex.create({
  dropdown: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    fontFamily: fonts.din2014Light,
    fontSize: '1.6666667rem',
    lineHeight: '2.2222222rem',
    '@media (max-width: 767px)': {
      fontSize: '4.2667rem',
      lineHeight: '6.4rem',
    },
  },
  dropdownToggle: {
    width: '40.27777777rem',
    padding: '1.6666667rem 2.7777778rem',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '.83333333rem',
    border: `1px solid #A0A0A0`,
    background: 'transparent',
    fontFamily: 'inherit',
    fontSize: '1.6666667rem',
    lineHeight: '2.2222222rem',
    textAlign: 'left',
    transition: 'border-color 0.3s ease-in-out',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      borderColor: '#fff',
    },
    ':focus': {
      borderColor: '#F69E4B',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '4.266666667rem 5.333333rem',
      borderRadius: '3.2rem',
      fontSize: '4.266666667rem',
      lineHeight: '6.4rem',
    },
  },
  dropdownToggleOpen: {
    border: `1px solid #F69E4B`,
  },
  dropdownTextDefault: {
    color: '#A0A0A0',
  },
  dropdownText: {
    width: '100%',
  },
  dropdownIcon: {
    width: '2.2222222rem',
    height: '2.2222222rem',
    transition: 'all 0.5s ease-in-out',
    '@media (max-width: 767px)': {
      width: '5.3333333rem',
      height: '5.3333333rem',
    },
  },
  dropDownOpenIcon: {
    transform: 'rotate(180deg)',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#121212',
    color: '#fff',
    padding: '1.666666667rem 0',
    border: `1px solid #fff`,
    boxSizing: 'border-box',
    borderRadius: '.83333333rem',
    marginTop: '0.5rem',
    listStyle: 'none',
    zIndex: 10,
    width: '100%',
    minHeight: '31.1111111rem',
    overflowY: 'scroll',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    scrollbarWidth: 'thin',
    '@media (max-width: 767px)': {
      padding: '3.2rem 0',
      borderRadius: '3.2rem',
      marginTop: '2.1333333rem',
    },
  },
  dropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.6666667rem 2.77777778rem',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    ':hover': {
      backgroundColor: '#2a2a2a',
    },
    '@media (max-width: 767px)': {
      padding: '3.2rem',
    },
  },
  dropdownItemIcon: {
    display: 'flex',
    width: '2.2222222rem',
    height: '2.2222222rem',
    '@media (max-width: 767px)': {
      width: '5.3333333rem',
      height: '5.3333333rem',
    },
  },
});
