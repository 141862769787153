import { MutableRefObject } from 'react';

const DividerLogo = ({ ref1, ref2 }: { ref1: MutableRefObject<null>; ref2: MutableRefObject<null> }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 65 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3505 54.2057V40.5586H19.614L0.526855 77.2646V89.4999H24.9975L43.3505 54.2057Z"
        fill="#FDAC42"
        ref={ref1}
      />
      <path
        d="M64.7636 0.793945H40.2929L21.2058 37.4999H43.3518H46.4106V40.5588V48.3235L64.7636 13.0293V0.793945Z"
        fill="#FA8810"
        ref={ref2}
      />
    </svg>
  );
};

export default DividerLogo;
