const BuildIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4553 18.7166C10.7842 18.3877 11.2774 18.3477 11.5569 18.6272L13.3731 20.4434C13.6526 20.7229 13.6126 21.2161 13.2838 21.545L9.88614 24.9426C8.96725 25.8615 7.58919 25.9732 6.80814 25.1922C6.02709 24.4111 6.13883 23.033 7.05771 22.1142L10.4553 18.7166Z"
        fill="currentColor"
      />
      <path
        d="M14.5859 16.0001C14.9764 15.6096 15.6096 15.6096 16.0001 16.0001C16.3906 16.3906 16.3906 17.0238 16.0001 17.4143L14.5859 18.8285C14.1953 19.219 13.5622 19.219 13.1716 18.8285C12.7811 18.438 12.7811 17.8048 13.1716 17.4143L14.5859 16.0001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6931 8.75448C26.1528 7.28599 24.7133 5.84645 23.2448 6.30619L10.7632 10.2148C9.4385 10.6295 9.07673 12.3148 10.0941 13.3322L12.0544 15.2925C12.5581 15.7962 13.3747 15.7962 13.8783 15.2925C14.6008 14.57 15.9508 14.5366 16.7067 15.2925C17.4626 16.0484 17.4292 17.3985 16.7067 18.121C16.2031 18.6246 16.2031 19.4412 16.7067 19.9448L18.667 21.9051C19.6844 22.9225 21.3697 22.5608 21.7844 21.236L25.6931 8.75448Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BuildIcon;