import { FC } from 'react';
import H2Heading from '@shared/ui/h2-heading';
import { SectionWhatIsSminexProps } from '@features/section-what-is-sminex/model/types';

import styles from './styles.module.css';

const MAIN_TEXT_COLOR = '#A0A0A0';
const ACCENT_TEXT_COLOR = '#fff';

export const SectionWhatIsSminex: FC<React.PropsWithChildren<SectionWhatIsSminexProps>> = ({
  sectionContent,
  children,
  secondVariant,
}) => {
  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.aboutSminexWrapper}>
        <H2Heading
          heading={sectionContent.header}
          mainTextColor={MAIN_TEXT_COLOR}
          accentTextColor={ACCENT_TEXT_COLOR}
        />
        <div className={styles.description}>
          <div className={styles.text}>{sectionContent.firstDescription}</div>
          <div className={styles.text}>{sectionContent.secondDescription}</div>
        </div>
      </div>

      {secondVariant ? children : <div className={styles.svgWrapper}>{children}</div>}
    </div>
  );
};
