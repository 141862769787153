import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

import { Video } from '@features/video';
import ColorTextParser from '@shared/lib/text-parser.tsx';
import CustomCursor from '@features/start-screen/ui/custom-cursor/custom-cursor.tsx';
import PlayMediumIcon from '@shared/ui/icons/play-medium-icon.tsx';

import styles from '@features/start-screen/ui/styles.module.css';
import { DEFAULT_WHITE_COLOR } from '@shared/lib/constants.ts';

interface IStartScreenProps {
  videoUrl?: string;
  previewVideoUrl?: string;
  mainText?: string;
  secondaryText?: string;
  mainTextColor?: string;
  accentTextColor?: string;
  disclaimerColor?: string;
}

const StartScreen = ({
  videoUrl,
  previewVideoUrl,
  mainText,
  secondaryText,
  mainTextColor,
  accentTextColor,
  disclaimerColor,
}: IStartScreenProps) => {
  const mainTextContainer = useRef(null);
  const disclaimerContainer = useRef(null);
  const videoContainer = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        mainTextContainer.current,
        {
          y: 100,
          opacity: 0,
          ease: 'power2.out',
        },
        { y: 0, duration: 0.5, opacity: 1, delay: 5 }
      );
      gsap.fromTo(
        disclaimerContainer.current,
        {
          y: 100,
          opacity: 0,
          ease: 'power2.out',
        },
        { y: 0, duration: 0.5, opacity: 1, delay: 5.4 }
      );
      gsap.fromTo(
        videoContainer.current,
        {
          y: 100,
          opacity: 0,
          ease: 'power2.out',
        },
        { y: 0, duration: 0.5, opacity: 1, delay: 5.8 }
      );
    });
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles.screenWrapper}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h1 className={styles.leftHeading} ref={mainTextContainer}>
            {ColorTextParser({
              text: mainText,
              mainColor: mainTextColor || DEFAULT_WHITE_COLOR,
              secondColor: accentTextColor || DEFAULT_WHITE_COLOR,
            })}
          </h1>
          <div
            className={styles.disclaimer}
            style={{ color: disclaimerColor || DEFAULT_WHITE_COLOR }}
            ref={disclaimerContainer}
          >
            {secondaryText}
          </div>
        </div>
        <div className={styles.videoPreviewContainer} ref={videoContainer}>
          <Video videoUrl={videoUrl} previewUrl={previewVideoUrl} />
          <CustomCursor containerRef={videoContainer} className={styles.customCursorPaddings}>
            <div>Смотреть шоурил</div>
            <PlayMediumIcon />
          </CustomCursor>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
