import { MarqueeLine } from '@features/marquee-line';

import styles from '@features/marquee-line-screen/ui/marquee-line-screen.module.css';
import { TECH_STACK_LOWER, TECH_STACK_UPPER } from '@features/marquee-line-screen/lib/marquee-lines-mock-data.tsx';

const MarqueeLinesScreen = () => {
  return (
    <div className={styles.container}>
      <MarqueeLine techStackArray={TECH_STACK_UPPER} />
      <MarqueeLine techStackArray={TECH_STACK_LOWER} isReversed />
    </div>
  );
};

export default MarqueeLinesScreen;
