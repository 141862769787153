import FileIcon from '@shared/icons/file-icon.tsx';
import LinkIcon from '@shared/icons/link-icon.tsx';
import DeleteIcon from '@shared/icons/delete-icon.tsx';

import styles from './styles.module.css';

interface IResumeListItemProps {
  title?: string;
  icon?: 'file' | 'link';
  onDelete?: () => void;
}

const ResumeListItem = ({ title, icon = 'link', onDelete }: IResumeListItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.typeIcon}>
        {icon === 'file' && <FileIcon />}
        {icon === 'link' && <LinkIcon />}
      </div>
      <div className={styles.text}>{title}</div>
      <button className={styles.closeBtn} onClick={onDelete}>
        <DeleteIcon />
      </button>
    </div>
  );
};

export default ResumeListItem;
