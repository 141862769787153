const LocationIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 3C10.7536 3 6.5 7.36556 6.5 12.75C6.5 14.8702 7.17727 16.8158 8.30302 18.4091C8.32321 18.4473 8.32637 18.4899 8.34933 18.5265L14.6827 28.2765C14.9764 28.7286 15.4712 29 16 29C16.5288 29 17.0236 28.7286 17.3173 28.2765L23.6507 18.5265C23.674 18.4899 23.6768 18.4473 23.697 18.4091C24.8227 16.8158 25.5 14.8702 25.5 12.75C25.5 7.36556 21.2464 3 16 3ZM16 16C14.2512 16 12.8333 14.5448 12.8333 12.75C12.8333 10.9552 14.2512 9.5 16 9.5C17.7488 9.5 19.1667 10.9552 19.1667 12.75C19.1667 14.5448 17.7488 16 16 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LocationIcon;
