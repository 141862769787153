const PlayMediumIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M23.9657 14.5831C25.7876 15.5984 25.7943 16.8757 23.9657 18.0236L9.83633 27.5527C8.06101 28.5001 6.85525 27.9407 6.72868 25.8907L6.66872 5.94643C6.62875 4.05806 8.18425 3.52453 9.66646 4.42991L23.9657 14.5831Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayMediumIcon;