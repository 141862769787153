export const HEADING_TITLE = '**Откликнуться** на вакансию';
export const DESCRIPTION_TEXT = 'Прикрепите **файл с резюме** или\u00A0**оставьте** на него **ссылку**';
export const MODAL_HEADING = 'Ссылка на **резюме или портфолио**';

export const IT_DEPARTMENTS_OPTIONS = [
  {
    title: 'Отдел аналитики',
    value: '0',
  },
  {
    title: 'Отдел разработки',
    value: '1',
  },
  {
    title: 'Отдел тестирования',
    value: '2',
  },
  {
    title: 'Отдел дизайна',
    value: '3',
  },
  {
    title: 'Другое',
    value: '4',
  },
];
