const PhoneIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7416 19.3969L20.1343 20.0363C20.1343 20.0363 18.6908 21.556 14.7508 17.4079C10.8108 13.2598 12.2543 11.7401 12.2543 11.7401L12.6367 11.3375C13.5788 10.3457 13.6676 8.75329 12.8457 7.5908L11.1643 5.21281C10.147 3.774 8.18128 3.58393 7.01526 4.81152L4.92246 7.01485C4.3443 7.62355 3.95686 8.4126 4.00385 9.28792C4.12405 11.5273 5.08094 16.3453 10.4205 21.9669C16.0828 27.9283 21.3957 28.1652 23.5684 27.9508C24.2556 27.8829 24.8532 27.5124 25.3348 27.0053L27.2289 25.0112C28.5075 23.6652 28.1469 21.3576 26.5111 20.416L23.9637 18.9497C22.8896 18.3315 21.5811 18.5131 20.7416 19.3969Z"
        fill="currentColor"
      />
      <path
        d="M17.6806 2.50677C17.7689 1.96158 18.2842 1.59176 18.8294 1.68001C18.8632 1.68648 18.9718 1.70677 19.0286 1.71944C19.1425 1.74478 19.3012 1.78378 19.499 1.84142C19.8948 1.95668 20.4476 2.14656 21.111 2.45073C22.4394 3.05974 24.2071 4.12508 26.0415 5.95948C27.8759 7.79388 28.9413 9.56162 29.5502 10.89C29.8545 11.5535 30.0444 12.1063 30.1596 12.502C30.2172 12.6999 30.2562 12.8586 30.2816 12.9723C30.2942 13.0292 30.3036 13.0749 30.31 13.1087L30.3177 13.1503C30.4058 13.6954 30.0394 14.2322 29.4942 14.3205C28.9506 14.4085 28.4385 14.0403 28.3481 13.4978C28.3453 13.4831 28.3376 13.4441 28.3294 13.4071C28.3129 13.3333 28.2845 13.2162 28.2394 13.0613C28.1492 12.7515 27.9925 12.2911 27.7322 11.7235C27.2125 10.5898 26.2778 9.02418 24.6273 7.37369C22.9769 5.72321 21.4113 4.78856 20.2776 4.26878C19.71 4.00857 19.2496 3.85186 18.9397 3.76162C18.7849 3.71652 18.5902 3.67184 18.5164 3.6554C17.9737 3.56496 17.5926 3.0504 17.6806 2.50677Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9801 7.10543C18.1319 6.57439 18.6853 6.26689 19.2164 6.41861L18.9417 7.38015C19.2164 6.41861 19.2164 6.41861 19.2164 6.41861L19.2184 6.41917L19.2204 6.41976L19.2248 6.42105L19.2352 6.42415L19.2616 6.43236C19.2816 6.43879 19.3068 6.44716 19.3367 6.45779C19.3965 6.47904 19.4757 6.50925 19.5728 6.55085C19.7671 6.63411 20.0324 6.76267 20.3585 6.95561C21.0111 7.34185 21.902 7.98343 22.9487 9.03005C23.9953 10.0767 24.6368 10.9676 25.0231 11.6202C25.216 11.9462 25.3445 12.2117 25.4279 12.4059C25.4695 12.503 25.4997 12.5821 25.5209 12.642C25.5316 12.6719 25.5399 12.697 25.5464 12.7171L25.5545 12.7435L25.5576 12.7539L25.5589 12.7583L25.5595 12.7604C25.5595 12.7604 25.5601 12.7623 24.5985 13.037L25.5601 12.7623C25.7119 13.2933 25.4043 13.8468 24.8733 13.9985C24.3468 14.1489 23.7981 13.848 23.6409 13.3252L23.636 13.3108C23.6289 13.2908 23.6143 13.2513 23.5896 13.1937C23.5403 13.0786 23.4508 12.8903 23.302 12.6389C23.0047 12.1365 22.4677 11.3775 21.5344 10.4443C20.6012 9.511 19.8421 8.97407 19.3399 8.67677C19.0884 8.52795 18.9001 8.43848 18.7849 8.38915C18.7273 8.36447 18.6879 8.34975 18.6679 8.34264L18.6535 8.33772C18.1308 8.18055 17.8297 7.63195 17.9801 7.10543Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PhoneIcon;
