import PhoneIcon from '@shared/icons/footer/phone-icon.tsx';
import MailIcon from '@shared/icons/footer/mail-icon.tsx';
import LocationIcon from '@shared/icons/footer/location-icon.tsx';
import HeadHunterIcon from '@shared/icons/footer/head-hunter.tsx';
import HabrCareerIcon from '@shared/icons/footer/habr-career.tsx';
import { IIconWithTextProps } from '@features/footer/model/types.ts';

export const FOOTER_LEFT_INFO_SECTION_ITEMS = [
  { icon: <PhoneIcon />, color: 'gray', title: '+7 (495) 453-68-54', href: 'tel:+74954536854' },
  { icon: <MailIcon />, color: 'gray', title: 'hr@sminex.com', href: 'mailto:hr@sminex.com' },
  {
    icon: <LocationIcon />,
    color: 'gray',
    title: 'Москва, Кадашёвская наб., д. 6/1/2, стр. 1',
    href: 'https://yandex.ru/maps/-/CDTKmH2t',
  },
] as IIconWithTextProps[];

export const FOOTER_RIGHT_INFO_SECTION_ITEMS = [
  { icon: <HeadHunterIcon />, color: 'orange', title: 'head hunter', href: 'https://hh.ru/employer/62136' },
  { icon: <HabrCareerIcon />, color: 'orange', title: 'habr career', href: 'https://career.habr.com/companies/sminex' },
] as IIconWithTextProps[];

export const FOOTER_COPYRIGHT =
  '©2024 Сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой определяемой положениями статьи 437 Гражданского кодекса Российской Федерации';
export const FOOTER_COMPANY_REQUISITES =
  'ООО «ПерфектКом АйТи Солюшнс» ИНН 7735027442. Свидетельство регистрации 2087746716176 1999-2024';
