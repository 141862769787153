import ResumeListItem from '../../../resume-list-item/ui';
import {
  AttachedFileType,
  AttachedItemTypes,
  AttachedLinkType,
  IFilesListProps,
} from '@features/feedback-screen/model/types.ts';
import useIsMobile from '@shared/hooks/useIsMobile.tsx';

import styles from '@features/feedback-screen/ui/styles.module.css';

const FilesList = ({ attachedItems, onDeleteHandler }: IFilesListProps) => {
  const { isMobile } = useIsMobile();

  if (isMobile && !attachedItems.length) return null;

  return (
    <div className={styles.scrollWrapper}>
      <div className={styles.resumeListWrapper}>
        <div className={styles.resumeList}>
          {attachedItems?.map((attachedItem) => {
            if (attachedItem.type === AttachedItemTypes.File) {
              const narrowedTypeItem = attachedItem as AttachedFileType;
              return (
                <ResumeListItem
                  key={attachedItem.id}
                  title={narrowedTypeItem.fileInfo.name}
                  icon="file"
                  onDelete={() => onDeleteHandler(attachedItem)}
                />
              );
            }
            if (attachedItem.type === AttachedItemTypes.Link) {
              const narrowedTypeItem = attachedItem as AttachedLinkType;
              return (
                <ResumeListItem
                  key={attachedItem.id}
                  title={narrowedTypeItem.link}
                  icon="link"
                  onDelete={() => onDeleteHandler(attachedItem)}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default FilesList;
