import { MainPage } from '@pages/mainPage';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { MainLayout } from './main-layout';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<MainPage />} />
      </Route>
    </Route>
  )
);
