import { SminexLogo } from '@shared/icons/sminex';

import styles from './header.module.css';

export const Header = () => {
  return (
    <>
      <div className={`${styles.headerContainer}`}>
        <div className={styles.headerDesc}>
          <SminexLogo />
        </div>
      </div>
      <div className={`${styles.menuWrapper}`}></div>
    </>
  );
};
