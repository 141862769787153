export const SminexLogo = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 226 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M102.736 28.3763L110.28 19.38L103.013 9.04459H106.958C108.896 9.04459 108.619 9.83435 111.976 14.6072L113.118 16.2554L117.686 9.28496C117.824 9.07893 118.066 9.01025 118.274 9.01025H120.627L113.775 17.1825L121.7 28.3763H117.997C115.54 28.4106 115.99 27.4149 112.737 22.848L110.938 20.3071L105.712 28.0673C105.574 28.2733 105.366 28.342 105.089 28.342H102.736V28.3763Z"
        fill="white"
      />
      <path
        d="M94.8116 28.7541C92.147 28.7541 89.3786 27.9987 87.6483 25.8698C86.0219 23.8782 85.4336 21.406 85.3644 18.8994C85.3298 15.7747 86.1257 13.2681 87.7867 11.3796C89.4478 9.49107 91.7317 8.56396 94.6386 8.56396C96.0228 8.56396 97.2686 8.76998 98.3413 9.18203C99.4141 9.59407 100.245 10.1435 100.902 10.8645C101.56 11.5856 102.044 12.4097 102.356 13.3368C102.667 14.2639 102.84 15.294 102.875 16.3928C102.875 16.9765 102.84 17.5259 102.736 18.0066C102.736 18.0753 102.667 18.1096 102.632 18.1096H90.4513C90.5205 21.2343 91.005 23.4662 91.9048 24.874C92.8045 26.2818 94.2925 26.9686 96.4034 26.9686C98.5836 26.9686 100.279 26.3505 101.94 25.08C102.217 25.3204 102.529 25.9728 101.94 26.4879C100.418 27.8613 97.4416 28.7541 94.8116 28.7541ZM90.4167 17.1825L98.3067 16.5988C98.3413 13.9892 98.2375 9.69708 94.7424 9.69708C91.0742 9.69708 90.5205 14.4012 90.4167 17.1825Z"
        fill="white"
      />
      <path
        d="M82.3207 26.1446V14.8134C82.3207 10.9333 80.8327 8.59839 76.9915 8.59839C74.2923 8.59839 71.7315 9.86885 69.8975 11.7574C69.8975 10.2466 69.6552 9.01043 68.1672 9.01043H65.0527L65.2258 11.208V26.1102L65.0527 28.3421H70.1051L69.9321 26.1102V12.7875C71.3855 11.6887 72.7351 11.1393 74.673 11.1393C77.1299 11.1393 77.6144 13.4056 77.6144 15.9122V25.7325C77.6144 27.3807 77.9951 28.3421 79.3793 28.3421H82.4938L82.3207 26.1446Z"
        fill="white"
      />
      <path
        d="M60.1715 3.41343C60.1715 4.7869 59.0295 5.88569 57.6453 5.88569C56.2611 5.88569 55.1191 4.7869 55.1191 3.41343C55.1191 2.03995 56.2611 0.941162 57.6453 0.941162C59.0295 0.941162 60.1715 2.03995 60.1715 3.41343Z"
        fill="white"
      />
      <path
        d="M55.293 9.0104L55.466 11.208V26.1102L55.293 28.3421H60.3453L60.1723 26.1102V11.5857C60.1723 10.1092 59.8955 8.97607 58.4074 8.97607H55.293V9.0104Z"
        fill="white"
      />
      <path
        d="M50.517 26.1446V14.8134C50.517 10.899 49.029 8.59839 45.1878 8.59839C41.9696 8.59839 39.7894 10.2122 38.0592 12.0321C37.4363 9.86886 35.9136 8.59839 33.0068 8.59839C30.273 8.59839 28.0583 9.86885 26.2588 11.7917V11.7574C26.2588 10.2466 26.0166 9.01043 24.5285 9.01043H21.4141L21.5871 11.208V26.1102L21.4141 28.3421H26.4664L26.2934 26.1102V12.7875C27.7122 11.62 29.4079 11.1393 30.7575 11.1393C33.4221 11.1393 33.6989 13.8519 33.6989 15.9122V26.1102L33.5259 28.3421H38.5782L38.4052 26.1102V12.7875C39.6164 11.7574 41.3121 11.1393 42.9039 11.1393C45.6723 11.1393 45.8453 14.1266 45.8453 15.9122V25.7325C45.8453 27.5867 46.3644 28.3421 47.6102 28.3421H50.7247L50.517 26.1446Z"
        fill="white"
      />
      <path
        d="M8.64511 28.7541C6.32656 28.7541 2.90065 28.1017 0.824336 26.9342C1.20499 26.0758 1.9317 25.4234 2.96986 25.9728C4.83854 26.9342 6.70722 27.3806 8.78353 27.3806C11.7596 27.3806 13.9397 25.7324 13.8013 22.7451C13.4898 16.4958 0.547495 15.5687 0.582101 8.04891C0.616706 2.52067 5.63446 0.941162 10.4446 0.941162C12.7631 0.941162 15.0471 1.38755 17.2964 2.28031C16.9158 3.17307 16.2583 3.92848 15.1163 3.37909C13.5244 2.62367 11.9326 2.28031 10.2369 2.28031C7.29551 2.28031 5.63446 3.85981 5.63446 6.19472C5.59985 12.1693 18.7498 13.0621 18.9921 20.8223C19.1305 26.4879 14.355 28.7541 8.64511 28.7541Z"
        fill="white"
      />
      <path
        d="M220.199 8.50378C223.677 8.50378 225.417 10.2694 225.417 13.8007V28.229H222.017V14.7494C222.017 13.4581 221.806 12.5753 221.385 12.101C220.989 11.6003 220.291 11.3499 219.29 11.3499C218.104 11.3499 216.918 11.6266 215.732 12.18C214.546 12.7334 213.136 13.5372 211.502 14.5913L211.344 12.18C212.846 11.0468 214.335 10.1508 215.811 9.49202C217.287 8.8332 218.749 8.50378 220.199 8.50378ZM211.858 0.55835C211.879 4.58139 211.898 8.6042 211.898 12.6273L211.858 13.2678V28.229H208.498V0.55835H211.858Z"
        fill="white"
      />
      <path
        d="M197.821 8.50391C198.401 8.50391 199.047 8.53026 199.758 8.58296C200.47 8.63567 201.194 8.72791 201.932 8.85967C202.697 8.99144 203.395 9.17591 204.027 9.41308L203.593 11.5872C202.723 11.5081 201.801 11.4554 200.825 11.4291C199.877 11.3764 199.06 11.35 198.375 11.35C196.846 11.35 195.634 11.5609 194.738 11.9825C193.842 12.4042 193.196 13.142 192.801 14.1962C192.406 15.2503 192.208 16.7129 192.208 18.5839C192.208 20.455 192.406 21.9308 192.801 23.0112C193.196 24.0654 193.842 24.8032 194.738 25.2249C195.634 25.6465 196.846 25.8574 198.375 25.8574C198.77 25.8574 199.284 25.8442 199.916 25.8178C200.549 25.7915 201.221 25.7519 201.932 25.6992C202.67 25.6465 203.369 25.5675 204.027 25.4621L204.383 27.7152C203.408 28.0578 202.341 28.3082 201.181 28.4663C200.048 28.6244 198.915 28.7035 197.782 28.7035C195.568 28.7035 193.789 28.3741 192.445 27.7152C191.101 27.0564 190.126 25.9891 189.52 24.5134C188.94 23.0376 188.65 21.0611 188.65 18.5839C188.65 16.1067 188.953 14.1303 189.56 12.6545C190.166 11.1787 191.141 10.1246 192.485 9.49214C193.829 8.83332 195.608 8.50391 197.821 8.50391Z"
        fill="white"
      />
      <path
        d="M176.275 8.50391C179.173 8.50391 181.268 9.03097 182.56 10.0851C183.877 11.1392 184.536 12.7072 184.536 14.7891C184.563 16.502 184.207 17.846 183.469 18.8211C182.731 19.7698 181.558 20.2442 179.951 20.2442H171.945L173.239 17.6352H179.081C179.951 17.6352 180.517 17.3453 180.781 16.7656C181.044 16.1595 181.176 15.5006 181.176 14.7891C181.15 13.5505 180.781 12.6677 180.069 12.1406C179.358 11.6136 178.146 11.35 176.433 11.35C175.036 11.35 173.929 11.5609 173.112 11.9825C172.322 12.3778 171.755 13.0893 171.412 14.1171C171.096 15.1185 170.938 16.5284 170.938 18.3468C170.938 20.4023 171.136 21.9703 171.531 23.0508C171.953 24.1312 172.611 24.8691 173.507 25.2644C174.403 25.6597 175.589 25.8574 177.065 25.8574C178.093 25.8574 179.213 25.8178 180.425 25.7388C181.664 25.6334 182.771 25.5148 183.746 25.383L184.101 27.6362C183.495 27.8734 182.744 28.071 181.848 28.2291C180.979 28.3872 180.069 28.5058 179.121 28.5849C178.198 28.664 177.368 28.7035 176.63 28.7035C174.417 28.7035 172.638 28.3741 171.294 27.7152C169.95 27.0301 168.962 25.9496 168.329 24.4738C167.723 22.9717 167.42 20.9952 167.42 18.5444C167.42 16.0145 167.723 14.0249 168.329 12.5754C168.962 11.0997 169.923 10.0587 171.215 9.45261C172.532 8.82014 174.219 8.50391 176.275 8.50391Z"
        fill="white"
      />
      <path
        d="M158.135 3.28613V22.9323C158.135 23.8547 158.333 24.5135 158.728 24.9088C159.124 25.3041 159.796 25.5017 160.744 25.5017H163.67L164.065 28.0711C163.643 28.1766 163.156 28.2688 162.602 28.3478C162.049 28.4269 161.509 28.4796 160.982 28.506C160.454 28.5587 160.033 28.585 159.717 28.585C158.109 28.585 156.884 28.137 156.04 27.241C155.197 26.345 154.775 25.0537 154.775 23.3671V3.28613H158.135ZM164.262 8.97838V11.6269H159.077L160.453 8.97838H164.262Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.876 21.1403V16.9412H132.572L126.699 28.2353V32H134.229L139.876 21.1403Z"
        fill="#FDAC42"
      />
      <path
        d="M146.465 4.70581H138.935L133.062 16H139.877H140.818V16.9411V19.3303L146.465 8.47053V4.70581Z"
        fill="#FA8810"
      />
    </svg>
  );
};
