export const Design = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8802 2.44302C10.2933 3.03371 10.2933 3.99141 10.8802 4.5821L13.6398 7.35937L4.31801 16.7409C2.56066 18.509 2.56066 21.3763 4.31801 23.1449L9.81132 28.6734C10.6898 29.5575 11.8415 30 12.9927 30C14.1445 30 15.2957 29.558 16.1742 28.6734L26.5603 18.2208C27.1462 17.6311 27.1462 16.6758 26.5608 16.0861L16.9613 6.4251C16.9498 6.41273 16.938 6.40051 16.926 6.38844L13.0057 2.44302C12.4188 1.85233 11.4672 1.85233 10.8802 2.44302ZM24 26.9808C24 25.3131 27 20.9423 27 20.9423C27 20.9423 30 25.3131 30 26.9808C30 28.6484 28.6566 30 27 30C25.3434 30 24 28.6484 24 26.9808ZM8.15006 17.1537H23.3789L15.7645 9.49049L8.15006 17.1537Z"
        fill="#A0A0A0"
      />
    </svg>
  );
};
