export const BackendDev = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_1432_760)">
        <path d="M3.87942 6.12156L13.7997 15.9999L3.90039 25.8996" stroke="#A0A0A0" strokeWidth="4" />
        <path d="M17 25L29 25" stroke="#A0A0A0" strokeWidth="4" />
      </g>
      <defs>
        <clipPath id="clip0_1432_760">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
