import React from 'react';
import classNames from 'classnames';
import { IInputProps } from '@features/input/model/types.ts';

import styles from './styles.module.css';

const Input = ({
  type,
  label,
  value,
  name,
  placeholder,
  error,
  disabled,
  required,
  onBlur,
  onChange,
  onEnterPress,
}: IInputProps) => {
  return (
    <div className={classNames(styles.inputWrapper, error && styles.error)}>
      <label htmlFor={label}>{label}</label>
      <input
        type={type}
        id={label}
        value={value}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            onEnterPress?.();
          }
        }}
        disabled={disabled}
        required={required}
      />
      <p className={styles.errorText}>{error}</p>
    </div>
  );
};

export default Input;
