import { ReactNode } from 'react';
import { Close } from '@shared/ui/icons/Close';
import { Portal } from './portal';
import styles from './modal.module.css';
import useHideScroll from '@shared/hooks/useHideScroll.tsx';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  useHideScroll(isOpen);

  return (
    <Portal>
      <div className={`${styles.modalOverlay} ${isOpen ? styles.open : ''}`}>
        <div className={`${styles.modalContent} ${isOpen ? styles.open : ''}`}>
          {children}
          <button
            className={`${styles.closeButton}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose?.();
            }}
          >
            <Close />
          </button>
        </div>
      </div>
    </Portal>
  );
};
