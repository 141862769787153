const DockerIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.2486 25.2655C43.1642 25.1985 42.3766 24.6038 40.7154 24.6038C40.2768 24.6057 39.8391 24.6433 39.4066 24.7163C39.0851 22.5126 37.2632 21.4382 37.1815 21.39L36.7354 21.1328L36.4421 21.5562C36.0747 22.1248 35.8065 22.7517 35.649 23.4102C35.3516 24.6681 35.5325 25.8496 36.1715 26.8597C35.3998 27.2897 34.162 27.3955 33.9116 27.4049H13.4182C12.8829 27.4056 12.4486 27.8382 12.4456 28.3734C12.4217 30.1688 12.7259 31.9534 13.3432 33.6394C14.0491 35.4908 15.0994 36.8545 16.4658 37.6891C17.997 38.6268 20.4846 39.1626 23.3045 39.1626C24.5784 39.1666 25.8499 39.0513 27.1023 38.8184C28.8433 38.4987 30.5186 37.8902 32.0589 37.0179C33.328 36.2829 34.4703 35.348 35.4414 34.2489C37.065 32.411 38.0323 30.364 38.7516 28.5449H39.0383C40.8159 28.5449 41.9091 27.8335 42.5119 27.2374C42.9125 26.8572 43.2252 26.3941 43.4282 25.8804L43.5554 25.508L43.2486 25.2655Z"
        fill="#2496ED"
      />
      <path
        d="M15.3179 26.8045H18.0641C18.1965 26.8045 18.3039 26.6971 18.3039 26.5647V24.1186C18.3046 23.9861 18.1979 23.8782 18.0654 23.8774C18.065 23.8774 18.0645 23.8774 18.0641 23.8774H15.3179C15.1855 23.8774 15.0781 23.9848 15.0781 24.1172V24.1186V26.5647C15.0781 26.6971 15.1855 26.8045 15.3179 26.8045Z"
        fill="#2496ED"
      />
      <path
        d="M19.1025 26.8045H21.8487C21.9811 26.8045 22.0885 26.6971 22.0885 26.5647V24.1186C22.0892 23.9861 21.9824 23.8782 21.85 23.8774C21.8496 23.8774 21.8491 23.8774 21.8487 23.8774H19.1025C18.9693 23.8774 18.8613 23.9854 18.8613 24.1186V26.5647C18.8621 26.6974 18.9698 26.8045 19.1025 26.8045Z"
        fill="#2496ED"
      />
      <path
        d="M22.9439 26.8045H25.6901C25.8226 26.8045 25.9299 26.6971 25.9299 26.5647V24.1186C25.9306 23.9861 25.8239 23.8782 25.6914 23.8774C25.691 23.8774 25.6905 23.8774 25.6901 23.8774H22.9439C22.8114 23.8774 22.7041 23.9848 22.7041 24.1172V24.1186V26.5647C22.7041 26.6971 22.8115 26.8045 22.9439 26.8045Z"
        fill="#2496ED"
      />
      <path
        d="M26.7408 26.8045H29.4869C29.6196 26.8045 29.7273 26.6974 29.7281 26.5647V24.1186C29.7281 23.9854 29.6201 23.8774 29.4869 23.8774H26.7408C26.6083 23.8774 26.501 23.9848 26.501 24.1172V24.1186V26.5647C26.501 26.6971 26.6083 26.8045 26.7408 26.8045Z"
        fill="#2496ED"
      />
      <path
        d="M19.1025 23.2854H21.8487C21.9813 23.2847 22.0884 23.1769 22.0884 23.0443V20.5982C22.0884 20.4657 21.9811 20.3584 21.8487 20.3584H19.1025C18.9698 20.3584 18.8621 20.4655 18.8613 20.5982V23.0443C18.8621 23.1772 18.9696 23.2847 19.1025 23.2854Z"
        fill="#2496ED"
      />
      <path
        d="M22.9439 23.2854H25.6901C25.8227 23.2847 25.9298 23.1769 25.9298 23.0443V20.5982C25.9298 20.4657 25.8225 20.3584 25.6901 20.3584H22.9439C22.8114 20.3584 22.7041 20.4658 22.7041 20.5982V23.0443C22.7041 23.1769 22.8113 23.2847 22.9439 23.2854Z"
        fill="#2496ED"
      />
      <path
        d="M26.7408 23.2854H29.4869C29.6198 23.2847 29.7273 23.1772 29.7281 23.0443V20.5982C29.7273 20.4655 29.6196 20.3584 29.4869 20.3584H26.7408C26.6083 20.3584 26.501 20.4658 26.501 20.5982V23.0443C26.501 23.1769 26.6081 23.2847 26.7408 23.2854Z"
        fill="#2496ED"
      />
      <path
        d="M26.7408 19.765H29.4869C29.6196 19.765 29.7273 19.6578 29.7281 19.5252V17.0777C29.7273 16.945 29.6196 16.8379 29.4869 16.8379H26.7408C26.6083 16.8379 26.501 16.9452 26.501 17.0777V19.5251C26.501 19.6576 26.6083 19.765 26.7408 19.765Z"
        fill="#2496ED"
      />
      <path
        d="M30.5708 26.8045H33.317C33.4497 26.8045 33.5574 26.6974 33.5582 26.5647V24.1186C33.5582 23.9854 33.4502 23.8774 33.317 23.8774H30.5708C30.4384 23.8774 30.3311 23.9848 30.3311 24.1172V24.1186V26.5647C30.3311 26.6971 30.4384 26.8045 30.5708 26.8045Z"
        fill="#2496ED"
      />
    </svg>
  );
};

export default DockerIcon;
