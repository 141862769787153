const GithubIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 12.8284C19.4099 12.8284 12.4443 19.7927 12.4443 28.384C12.4443 35.257 16.9015 41.0878 23.0823 43.1448C23.8596 43.2887 24.1451 42.8073 24.1451 42.3964C24.1451 42.0255 24.1306 40.8 24.124 39.5003C19.7963 40.4412 18.8831 37.6649 18.8831 37.6649C18.1755 35.8669 17.156 35.3889 17.156 35.3889C15.7447 34.4233 17.2624 34.4431 17.2624 34.4431C18.8245 34.5529 19.647 36.0462 19.647 36.0462C21.0344 38.4243 23.286 37.7367 24.1737 37.3394C24.3132 36.3339 24.7164 35.6477 25.1613 35.2593C21.7062 34.8658 18.074 33.532 18.074 27.5716C18.074 25.8733 18.6817 24.4856 19.6769 23.3963C19.5153 23.0044 18.9829 21.4223 19.8275 19.2796C19.8275 19.2796 21.1338 18.8615 24.1064 20.8741C25.3472 20.5294 26.678 20.3566 28 20.3508C29.3219 20.3566 30.6537 20.5294 31.8969 20.8741C34.866 18.8615 36.1704 19.2796 36.1704 19.2796C37.0171 21.4223 36.4844 23.0044 36.3229 23.3963C37.3203 24.4856 37.9238 25.8732 37.9238 27.5716C37.9238 33.5462 34.2848 34.8618 30.821 35.2469C31.3789 35.7296 31.876 36.6763 31.876 38.1275C31.876 40.2088 31.858 41.884 31.858 42.3964C31.858 42.8104 32.1379 43.2954 32.9266 43.1427C39.1039 41.0835 43.5554 35.2547 43.5554 28.384C43.5554 19.7927 36.5908 12.8284 28 12.8284Z"
        fill="white"
      />
      <path
        d="M18.2702 34.9877C18.236 35.0649 18.1142 35.0881 18.0036 35.0351C17.8907 34.9844 17.8273 34.879 17.8639 34.8014C17.8974 34.7218 18.0192 34.6996 18.1318 34.7531C18.2449 34.8037 18.3093 34.9101 18.2702 34.9877ZM19.0353 35.6704C18.9612 35.7392 18.8161 35.7072 18.7176 35.5985C18.6159 35.4901 18.5969 35.3451 18.6721 35.2752C18.7486 35.2065 18.8893 35.2386 18.9913 35.3472C19.093 35.4569 19.1128 35.601 19.0352 35.6705L19.0353 35.6704ZM19.5603 36.5439C19.4649 36.6101 19.309 36.548 19.2127 36.4097C19.1174 36.2715 19.1174 36.1055 19.2148 36.0391C19.3114 35.9726 19.4649 36.0323 19.5625 36.1696C19.6577 36.3103 19.6577 36.4762 19.5602 36.544L19.5603 36.5439ZM20.448 37.5556C20.3627 37.6496 20.1812 37.6244 20.0482 37.4961C19.9123 37.3707 19.8743 37.1927 19.9599 37.0986C20.0461 37.0044 20.2287 37.0309 20.3627 37.1582C20.4978 37.2833 20.539 37.4627 20.4481 37.5556H20.448ZM21.5953 37.8972C21.5578 38.019 21.3829 38.0744 21.2067 38.0227C21.0308 37.9693 20.9156 37.8266 20.9511 37.7035C20.9877 37.5809 21.1634 37.5232 21.3409 37.5786C21.5166 37.6316 21.632 37.7733 21.5954 37.8972H21.5953ZM22.9012 38.0421C22.9056 38.1704 22.7561 38.2768 22.5712 38.2791C22.3851 38.2831 22.2347 38.1793 22.2327 38.0532C22.2327 37.9236 22.3788 37.8182 22.5647 37.8151C22.7496 37.8115 22.9012 37.9146 22.9012 38.0421ZM24.1839 37.9929C24.2061 38.1181 24.0775 38.2466 23.8939 38.2808C23.7133 38.3137 23.5462 38.2365 23.5231 38.1124C23.5007 37.9841 23.6317 37.8556 23.8119 37.8223C23.9959 37.7904 24.1604 37.8656 24.1839 37.9929Z"
        fill="white"
      />
    </svg>
  );
};

export default GithubIcon;
