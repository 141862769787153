import ArrowUpRightIcon from '@shared/icons/arrow-up-right-icon.tsx';
import { IFoxButtonProps } from '@features/button/model/types.ts';

import styles from '@features/button/ui/styles.module.css';

const Button = ({
  title,
  size = 'medium',
  variant = 'primary',
  withIcon,
  disabled,
  onClick,
  type = 'button',
}: IFoxButtonProps) => {
  return (
    <button
      className={`${styles.button} ${styles[size]} ${styles[variant]} ${withIcon ? styles.withIcon : ''}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <span>{title}</span>
      {withIcon && (
        <span className={styles.iconWrapper}>
          <ArrowUpRightIcon />
        </span>
      )}
    </button>
  );
};
export default Button;
