export const INITIAL_INPUTS = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  departmentId: '',
};

export const INITIAL_INPUTS_ERRORS = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  departmentId: false,
};