const HandshakeIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9636 9.1779C15.53 9.39056 15.1004 9.68525 14.7019 10.0894C12.7044 12.1132 10.8886 13.9526 10.8886 13.9526L10.8893 13.9538C10.8785 13.9648 10.8676 13.9738 10.8567 13.9852C10.3397 14.5094 10.3192 15.3449 10.7966 15.8932C10.8156 15.9152 10.8358 15.9369 10.8567 15.9576C11.3935 16.5021 12.2651 16.5034 12.8039 15.9576L14.9014 13.833C15.2632 13.9673 15.619 14.0456 15.9604 14.0848C16.4254 14.137 16.8622 14.1174 17.2507 14.062C18.1977 13.926 18.0986 13.9656 18.6478 13.7828C19.1959 13.6007 19.8116 13.2276 19.8116 13.2276L20.1844 13.6048C20.2255 13.6452 21.1757 14.6093 21.6605 15.0996C21.7463 15.1869 21.8192 15.2587 21.868 15.3102C21.924 15.3694 21.9865 15.4122 22.0252 15.4694L24.4438 17.9194C25.1409 17.1271 25.9372 15.947 25.8389 14.5922L19.7954 8.47124C19.7954 8.47124 17.7036 8.31983 15.9636 9.1779Z"
        fill="currentColor"
      />
      <path
        d="M16.3003 24.2118C15.7651 23.6689 14.8964 23.6689 14.3608 24.2118L13.9627 24.615C14.4971 24.073 14.4966 23.1949 13.9615 22.6525C13.426 22.1108 12.5584 22.1108 12.0228 22.6529C12.5584 22.1108 12.5584 21.232 12.0228 20.6891C11.4877 20.1461 10.6197 20.147 10.0841 20.6891C10.6197 20.147 10.6197 19.2677 10.0841 18.7248C9.54895 18.1828 8.68101 18.1828 8.1454 18.7256L6.94658 19.9395C6.41104 20.4824 6.41104 21.3609 6.94658 21.9038C7.48172 22.4459 8.34937 22.4459 8.88485 21.9038C8.34931 22.4459 8.34931 23.3251 8.88485 23.8676C9.42081 24.4097 10.2884 24.4097 10.8239 23.8676C10.2884 24.4097 10.2884 25.2886 10.8239 25.8315C11.3595 26.3735 12.2271 26.3735 12.7627 25.8315L13.1612 25.4273C12.6269 25.9702 12.6273 26.8483 13.1624 27.3908C13.698 27.9328 14.5656 27.9328 15.1012 27.3908L16.3004 26.176C16.8355 25.6327 16.8355 24.7539 16.3003 24.2118Z"
        fill="currentColor"
      />
      <path
        d="M30.7646 11.3871L23.7074 4.23758C23.3938 3.92081 22.8869 3.92081 22.5742 4.23758L20.3107 6.53039C19.998 6.84716 19.998 7.36068 20.3107 7.67786L27.3688 14.827C27.6811 15.1446 28.1888 15.1446 28.5011 14.827L30.7646 12.5337C31.0781 12.2178 31.0781 11.7043 30.7646 11.3871ZM28.753 12.9726C28.4161 13.313 27.8713 13.313 27.5356 12.9726C27.1995 12.6317 27.1995 12.0799 27.5356 11.7394C27.8713 11.399 28.4161 11.399 28.753 11.7394C29.0891 12.0799 29.0887 12.6317 28.753 12.9726Z"
        fill="currentColor"
      />
      <path
        d="M11.6884 6.73254L9.42532 4.43972C9.11267 4.12296 8.60531 4.12296 8.29259 4.43972L1.23454 11.5892C0.921822 11.9052 0.921822 12.4195 1.23454 12.7359L3.49806 15.0295C3.81036 15.3459 4.31849 15.3459 4.63038 15.0295L11.6884 7.87995C12.0016 7.56318 12.0016 7.04966 11.6884 6.73254ZM4.46392 13.1747C4.12782 13.5147 3.58267 13.5147 3.24652 13.1747C2.91049 12.8339 2.91049 12.2819 3.24652 11.9415C3.58261 11.6011 4.12782 11.6011 4.46392 11.9415C4.80001 12.2819 4.80001 12.8338 4.46392 13.1747Z"
        fill="currentColor"
      />
      <path
        d="M24.2931 19.3713L23.8556 18.9272L19.607 14.6236L19.3209 14.7522L19.0094 14.8661L18.9869 14.873C18.9321 14.8906 18.4195 15.0494 17.4089 15.1942C16.8745 15.2698 16.345 15.2792 15.8325 15.22C15.6298 15.1971 15.4283 15.1636 15.2292 15.1196L13.6028 16.7663C12.6256 17.7562 11.0343 17.7562 10.0571 16.7663C10.0228 16.7312 9.98975 16.6956 9.95917 16.6606L9.94868 16.6487C9.33175 15.9401 9.17501 14.9702 9.46517 14.1285L10.0905 13.1427L10.0894 13.1415L13.9027 9.27915C14.0578 9.1224 14.2206 8.97627 14.3898 8.83828C13.2015 8.60193 12.2045 8.67173 12.2045 8.67173L6.16225 14.7934C6.08086 15.9204 6.84492 17.1556 7.3885 17.8769C8.36654 16.9295 9.92202 16.9418 10.8831 17.9158C11.2409 18.2778 11.4662 18.724 11.5612 19.1917C12.0238 19.2889 12.4639 19.5167 12.8218 19.8792C13.1796 20.242 13.4049 20.6882 13.5 21.1564C13.9622 21.2527 14.403 21.4804 14.7605 21.843C14.9994 22.085 15.1796 22.3643 15.3008 22.6631C15.9512 22.6549 16.6041 22.9014 17.099 23.4023C18.0745 24.3897 18.0741 25.998 17.099 26.985L16.7 27.3899L16.8999 27.5925C17.4359 28.1354 18.3034 28.1354 18.839 27.5925C19.3746 27.0503 19.3746 26.1711 18.839 25.6286L19.2379 26.034C19.7739 26.576 20.6411 26.576 21.1766 26.034C21.7121 25.4911 21.7126 24.6122 21.1766 24.0693C21.7126 24.6122 22.5797 24.6122 23.1153 24.0693C23.6505 23.5277 23.6517 22.6484 23.1153 22.1054C23.6517 22.6484 24.5188 22.6484 25.054 22.1054C25.59 21.5634 25.59 20.6841 25.054 20.142L24.2931 19.3713Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HandshakeIcon;
