import cn from 'classnames';
import { IIconWithTextProps } from '@features/footer/model/types.ts';

import styles from '@features/footer/ui/styles.module.css';

const IconWithText = ({ href, color, icon, title }: IIconWithTextProps) => {
  return (
    <a target="_blank" href={href} className={cn(styles.iconWithText, styles[color])}>
      <div className={styles.iconWrapper}>{icon}</div>
      <div>{title}</div>
    </a>
  );
};

export default IconWithText;