import { RouterProvider } from 'react-router-dom';
import { Router as router } from './router';

function App() {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

export default App;
