const HeadHunterIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3346 16.0003C29.3346 23.3642 23.3652 29.3337 16.0013 29.3337C8.63741 29.3337 2.66797 23.3642 2.66797 16.0003C2.66797 8.63644 8.63741 2.66699 16.0013 2.66699C23.3652 2.66699 29.3346 8.63644 29.3346 16.0003ZM10.2514 14.5186V9.98307H8.10645V21.7347H10.2492L10.2514 17.5936C10.2492 16.8986 10.3148 16.3736 10.4459 16.0181C10.5748 15.6631 10.7781 15.3975 11.0614 15.2197C11.3387 15.0431 11.6576 14.9542 12.0131 14.9542C12.3237 14.9542 12.5809 15.0231 12.7876 15.1625C12.9898 15.2936 13.1416 15.4895 13.2181 15.7181C13.2998 15.9514 13.3387 16.5003 13.3387 17.367V21.7347H15.4842V16.8853C15.4842 16.1431 15.4476 15.5959 15.3742 15.2442C15.3003 14.8909 15.1637 14.5703 14.9614 14.2775C14.7603 13.9875 14.4626 13.7486 14.0642 13.5625C13.667 13.3753 13.2242 13.2809 12.7298 13.2809C11.7698 13.2809 10.942 13.6953 10.2514 14.5186ZM18.6998 14.5186V9.98307H16.557V21.7347H18.6998V17.5936C18.6998 16.8986 18.7653 16.3736 18.8942 16.0181C19.0248 15.6631 19.2292 15.3975 19.5087 15.2197C19.7864 15.0431 20.1053 14.9542 20.4603 14.9542C20.7709 14.9542 21.0303 15.0231 21.2348 15.1625C21.4377 15.2932 21.5899 15.4892 21.6664 15.7181C21.7481 15.9514 21.7887 16.5003 21.7887 17.367V21.7347H23.932V16.8853C23.932 16.1431 23.8953 15.5959 23.8214 15.2442C23.751 14.8973 23.6105 14.5684 23.4087 14.2775C23.2087 13.9875 22.9109 13.7486 22.5142 13.5625C22.1164 13.3753 21.6703 13.2809 21.1776 13.2809C20.2181 13.2809 19.3909 13.6953 18.6998 14.5186Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HeadHunterIcon;
