export const QA = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1987 6.50837C17.5352 6.18294 16.7889 6.00023 16 6.00023C15.2434 6.00023 14.526 6.1683 13.8831 6.46914L12.7071 5.29312C12.3166 4.9026 11.6834 4.9026 11.2929 5.29312C10.9024 5.68364 10.9024 6.31681 11.2929 6.70733L12.2634 7.67783C11.8378 8.15617 11.5026 8.71677 11.2848 9.3328C10.3565 9.90487 9.54001 10.6408 8.87555 11.5002H8C6.61929 11.5002 5.5 10.3809 5.5 9.00023C5.5 8.1718 4.82843 7.50023 4 7.50023C3.17157 7.50023 2.5 8.1718 2.5 9.00023C2.5 11.8221 4.6252 14.1477 7.3624 14.4637C7.17146 15.1149 7.05217 15.7968 7.01365 16.5002H4C3.17157 16.5002 2.5 17.1718 2.5 18.0002C2.5 18.8287 3.17157 19.5002 4 19.5002H7.01365C7.05217 20.2036 7.17146 20.8855 7.3624 21.5368C4.6252 21.8528 2.5 24.1783 2.5 27.0002V28.0002C2.5 28.8287 3.17157 29.5002 4 29.5002C4.82843 29.5002 5.5 28.8287 5.5 28.0002V27.0002C5.5 25.6195 6.61929 24.5002 8 24.5002H8.87555C10.5216 26.6293 13.1007 28.0002 16 28.0002C18.8993 28.0002 21.4784 26.6293 23.1245 24.5002H24C25.3807 24.5002 26.5 25.6195 26.5 27.0002V28.0002C26.5 28.8287 27.1716 29.5002 28 29.5002C28.8284 29.5002 29.5 28.8287 29.5 28.0002V27.0002C29.5 24.1783 27.3748 21.8528 24.6376 21.5368C24.8285 20.8855 24.9478 20.2036 24.9863 19.5002H28C28.8284 19.5002 29.5 18.8287 29.5 18.0002C29.5 17.1718 28.8284 16.5002 28 16.5002H24.9863C24.9478 15.7968 24.8285 15.1149 24.6376 14.4637C27.3748 14.1477 29.5 11.8221 29.5 9.00023C29.5 8.1718 28.8284 7.50023 28 7.50023C27.1716 7.50023 26.5 8.1718 26.5 9.00023C26.5 10.3809 25.3807 11.5002 24 11.5002H23.1245C22.46 10.6408 21.6435 9.90487 20.7152 9.3328C20.5071 8.74429 20.1919 8.20637 19.7931 7.74243L20.8284 6.70711C21.219 6.31658 21.219 5.68342 20.8284 5.29289C20.4379 4.90237 19.8047 4.90237 19.4142 5.29289L18.1987 6.50837Z"
        fill="#A0A0A0"
      />
    </svg>
  );
};
