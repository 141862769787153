import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { LenisRef } from 'lenis/dist/lenis-react';

const useGSAPLenis = ({ gsapModule }: { gsapModule: typeof gsap }) => {
  const lenisRef = useRef<LenisRef | null>(null);

  useEffect(() => {
    function update(time: number) {
      lenisRef.current?.lenis?.raf(time * 10000);
    }

    gsapModule.ticker.lagSmoothing(0);
    gsapModule.ticker.add(update);

    return () => {
      gsapModule.ticker.remove(update);
    };
  });

  return { lenisRef };
};

export default useGSAPLenis;
