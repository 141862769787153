import CorporationIcon from '@shared/icons/our-products/corporation-icon.tsx';
import BuildIcon from '@shared/icons/our-products/build-icon.tsx';
import HandshakeIcon from '@shared/icons/our-products/handshake-icon.tsx';

export const OUR_PRODUCTS_TITLE = 'Наши **продукты**';

export const DIGITAL_CLIENTS_PROJECTS = [
  {
    title: 'CRM КД',
    description:
      'Автоматизации стратегий взаимодействия с клиентами для повышения уровня продаж, оптимизации маркетинга и улучшения обслуживания клиентов',
  },
  {
    title: 'АСУВ',
    description:
      'Автоматизации учета продаваемых лотов за периоды, с возможностью оперативного уведомления и автоматической корректировкой стоимости лотов, при возникновении отклонений от первоначальной оценки стоимости.',
  },
  {
    title: 'ЖКХ',
    description:
      'Продукт для автоматизации и ведения бухгалтерского и налогового учета ЖКХ: от управления объектами инфраструктуры, открытия лицевых счетов до начисления квартплаты и услуг.',
  },
  {
    title: 'ЛК Sminex Comfort',
    description:
      'С помощью ЛК Sminex Comfort можно заказывать услуги Службы комфорта, удобно и безопасно оплачивать предоставленные услуги онлайн, управлять счетами, следить за историей всех взаиморасчетов с компанией и быть в курсе всех важных событий, участвовать в принятии важных решений, отправлять свои пожелания и отзывы.',
  },
  {
    title: 'Рекламно-информационные материалы',
    description: 'Современный учет маркетинговых материалов.',
  },
  {
    title: 'Сайты проектов',
    description: 'Продукт для презентации проектов Компании для клиента.',
  },
];

export const DIGITAL_CORP_PROJECTS = [
  {
    title: 'Realist',
    description: 'Система хранения и анализа предложения на рынке недвижимости',
  },
  {
    title: 'SMDocs',
    description: 'Все хранилища в одном месте. Файловый менеджер, объединяющий в себе основные сервисы хранения данных',
  },
  {
    title: 'TagsMaster',
    description:
      'Создание документов на базе шаблонов, получение данных документа из внешних источников, использование тэгов для оптимизации работы при создании документа',
  },
  {
    title: 'Корпоративный портал',
    description: 'Продукт для предоставления доступа к корпоративным сервисам Компании',
  },
  {
    title: 'Портал продукта',
    description:
      'Продукт для учета и распространение внутренней вики-информации по проектам Компании. Содержит общую информацию по проектам, архитектурные и фасадные решения, конструктивные решения, системы безопасности и т.д.',
  },
  {
    title: 'Совещание+',
    description:
      'Бронирование переговорных и контроль проведения совещания, формирование итогов совещаний, анализ по реализации проектов',
  },
  {
    title: 'Проектные команды',
    description:
      'Продукт для согласования состава проектной команды, закрепление договоренностей между руководителем проекта и функциональным руководителем о нагрузке сотрудника на проекте и длительности его работы.',
  },
  {
    title: 'Система поручений',
    description: 'Хранение и бработка поручений, формирование отчетных документов, создание проектных решений',
  },
  {
    title: 'CRM ДИ',
    description: '',
  },
  {
    title: 'Microsoft Power BI',
    description: 'Визуализация операционной и стратегической отчетности, разработка интерактивной отчетности',
  },
  {
    title: 'Sminex Event',
    description: 'Упроавление мероприятиями внутри компании',
  },
  {
    title: 'Охрана труда',
    description:
      'Продукт для учета и контроля средств индивидуальной защиты (СИЗ) выданных сотрудникам, несчастных случаев, медицинских осмотров, специальной оценки условий труда, обучения и нарядов-допусков.',
  },
  {
    title: 'Согласование',
    description: 'Продукт для подписания, передачи документов контрагенту и хранения документов в электронном виде.',
  },
  {
    title: 'ЭДО',
    description: 'Продукт для подписания, передачи документов контрагенту и хранения документов в электронном виде.',
  },
  {
    title: 'ТЭП',
    description:
      'Продукт, позволяющий получить стандартизированный доступа к ТЭПам проекта в любое время в формате одного окна.',
  },
  {
    title: 'ETL-система',
    description: '',
  },
  {
    title: 'IHR',
    description: '',
  },
  {
    title: 'Data Warehouse (DWH)',
    description: '',
  },
];

export const DIGITAL_CONSTRUCTION_PROJECTS = [
  {
    title: 'Warranty Sminex',
    description:
      'Приложение, для  ведения реестров и эффективного взаимодействия с подрядчиками, позволяет создавать акты осмотра и бесшовно интегрируется с другими системами компании.Позволяет аккумулировать информацию для последующего анализа и выводе ее в инструментах BI',
  },
  {
    title: 'Ведомость объемов работ',
    description:
      'Позволяет изменять, сохранять, выгружать/загружать данные ВОР, утверждать объемы и т.д.. Несмотря на единую точку входа данные по ВОР хранятся во множестве справочников, регистров и документов, скрытых от пользователя.',
  },
  {
    title: 'Dсontract',
    description:
      'Приложение, для взаимодействия с подрядчикоом. Работа с документами, которые требуются для эффективного ведения работ на строительной площадке.',
  },
  {
    title: 'Quality Lab',
    description:
      'Приложение, для организации работы нескольких подразделений компании: строительные группы, отдел разработки продукта, ОККиПР.',
  },
  {
    title: 'Est.SX',
    description: 'Специализированное решение для автоматизации сметных расчетов.',
  },
  {
    title: 'BIM системы',
    description: 'Внедрение технологии BIM 360 в проектировании и строительстве жилых и коммерческих объектов\n',
  },
];

export interface IOurProducts {
  title: string;
  description: string;
}

export interface IOurDepartment {
  title: string;
  projects: IOurProducts[];
  icon: JSX.Element;
  type: 'department';
  variant: 'black' | 'transparent';
}

export interface IOurInfoCard {
  type: 'info';
  mainTitle: string;
  extraTitle: string;
}

export type IOurDepartmentAndInfo = IOurDepartment | IOurInfoCard;

export const OUR_PRODUCTS_DEPARTMENTS: IOurDepartmentAndInfo[] = [
  {
    title: 'Цифровизация корпоративных систем',
    projects: DIGITAL_CLIENTS_PROJECTS,
    type: 'department',
    icon: <CorporationIcon />,
    variant: 'transparent',
  },
  {
    mainTitle: '40 +',
    extraTitle: 'продуктов',
    type: 'info',
  },
  {
    title: 'Цифровизация корпоративных систем',
    projects: DIGITAL_CORP_PROJECTS,
    type: 'department',
    icon: <BuildIcon />,
    variant: 'black',
  },
  {
    mainTitle: '3',
    extraTitle: 'направления',
    type: 'info',
  },
  {
    title: 'Цифровизация корпоративных систем',
    projects: DIGITAL_CONSTRUCTION_PROJECTS,
    type: 'department',
    icon: <HandshakeIcon />,
    variant: 'black',
  },
];
