import { string, ValidationError } from 'yup';

export type IValidateValue = string | false;

const MAX_PHONE_LENGTH = 17;
export const EMAIL_ERROR_MESSAGE = 'Некорретный адрес электронной почты';
export const PHONE_ERROR_MESSAGE = 'Некорретный номер телефона';
export const REQUIRED_PHRASE_MESSAGE = 'Поле должно быть заполнено';

export const emailValidator = async (email: string) => {
  let resp: IValidateValue = false;
  const emailSchema = string().default('').email(EMAIL_ERROR_MESSAGE).required(REQUIRED_PHRASE_MESSAGE);
  try {
    await emailSchema.validate(email);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};

export const phraseValidator = async (phrase: string) => {
  let resp: IValidateValue = false;
  const phraseSchema = string()
    .default('')
    .required(REQUIRED_PHRASE_MESSAGE)
  try {
    await phraseSchema.validate(phrase);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};

export const phoneValidator = async (phrase: string) => {
  let resp: IValidateValue = false;
  const phraseSchema = string()
    .default('')
    .required(REQUIRED_PHRASE_MESSAGE)
    .min(MAX_PHONE_LENGTH, PHONE_ERROR_MESSAGE)
    .max(MAX_PHONE_LENGTH, PHONE_ERROR_MESSAGE);
  try {
    await phraseSchema.validate(phrase);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};
