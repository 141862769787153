export const ArrowBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M12.4431 5.8335L4.66537 14.0002M4.66537 14.0002L12.4431 22.1668M4.66537 14.0002L23.332 14.0002"
        stroke="#F69E4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
