import { ControlProps } from '../controls/controls';
import { FullScreen } from '@shared/ui/icons/FullScreen';
import { FullScreenExit } from '@shared/ui/icons/FullScreenExit';

import styles from './screen-switcher.module.css';

export const ScreenSwitcher = ({ controlProps }: ControlProps) => {
  const { isFullscreen, playerRef, playerContainerRef } = controlProps;
  const handleFullscreenToggle = () => {
    const player = playerRef?.getInternalPlayer();

    if (!isFullscreen && playerContainerRef?.requestFullscreen) {
      playerContainerRef?.requestFullscreen();
      return;
    }

    if (!isFullscreen && player?.webkitEnterFullscreen) {
      player.webkitEnterFullscreen();
      return;
    }

    if (isFullscreen && document.exitFullscreen) {
      document.exitFullscreen();
    }
  };
  return (
    <button onClick={handleFullscreenToggle} className={styles.fullScreenBtn}>
      {isFullscreen ? <FullScreenExit /> : <FullScreen />}
    </button>
  );
};
