import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ICheckboxProps } from '@features/checkbox/model/types.ts';

import styles from './styles.module.css';

const Checkbox: FC<PropsWithChildren<ICheckboxProps>> = ({ children, isChecked, setIsChecked }) => {
  return (
    <label className={styles.container}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => {
          setIsChecked((prev) => !prev);
        }}
        className={styles.rawCheckbox}
      />
      <div className={styles.svgContainer}>
        <svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.200195" y="0.199951" width="25.6" height="25.6" rx="2" fill="#fff" />
          <path
            d="M19.4002 9.79993L13.4947 17.2285C13.2911 17.6095 12.8838 17.7999 12.4765 17.7999C12.0692 17.7999 11.662 17.6095 11.2547 17.419L8.2002 14.5619"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className={classNames({ [styles.hidden]: !isChecked })}
          width="100%"
          height="100%"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.200195" y="0.199951" width="25.6" height="25.6" rx="2" fill="#F78214" />
          <path
            d="M19.4002 9.79993L13.4947 17.2285C13.2911 17.6095 12.8838 17.7999 12.4765 17.7999C12.0692 17.7999 11.662 17.6095 11.2547 17.419L8.2002 14.5619"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
