import { useRef, useState } from 'react';
import useClickOutside from '@shared/hooks/useClickOutside/useClickOutside.ts';
import { DropdownOption, IDropdownProps } from '@features/dropdown/model/types.ts';
import ArrowDownBoldIcon from '@shared/icons/arrow-down-bold-icon.tsx';
import CheckIcon from '@shared/icons/check-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';

const Dropdown = ({ options = [], onSelect, placeholder }: IDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const clickOutsideHandler = () => setIsOpen(false);
  useClickOutside(ref, clickOutsideHandler);

  const selectHandler = (option: DropdownOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect?.(option);
  };

  return (
    <div {...stylex.props(styles.dropdown)} ref={ref}>
      <button
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        {...stylex.props([styles.dropdownToggle, isOpen ? styles.dropdownToggleOpen : null])}
      >
        <span {...stylex.props([styles.dropdownText, !selectedOption && styles.dropdownTextDefault])}>
          {selectedOption ? selectedOption.title : placeholder}
        </span>
        <span {...stylex.props([styles.dropdownIcon, isOpen && styles.dropDownOpenIcon])}>
          <ArrowDownBoldIcon />
        </span>
      </button>
      {isOpen && (
        <ul {...stylex.props(styles.dropdownMenu)}>
          {options.map((option, index) => (
            <li key={index} onClick={() => selectHandler(option)} {...stylex.props([styles.dropdownItem])}>
              {option.title}{' '}
              {selectedOption && selectedOption.title === option.title ? (
                <span {...stylex.props([styles.dropdownItemIcon])}>
                  <CheckIcon />
                </span>
              ) : null}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
