import { FC, useLayoutEffect, useRef } from 'react';
import { PointForTimelineProps } from '../../model/types';
import styles from './styles.module.css';

export const PointForTimelineMobile: FC<PointForTimelineProps> = ({ pointContent, gsapModule }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ctx = gsapModule.context(() => {
      if (containerRef.current) {
        const elements = containerRef.current.children;

        gsapModule.set(elements, { opacity: 0 });

        const tl = gsapModule.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top bottom-=100',
            once: true,
          },
        });

        tl.add(() => {
          Array.from(elements).forEach((element, index) => {
            gsapModule.to(element, {
              opacity: 1,
              delay: index * 0.5,
              ease: 'power2.out',
            });
          });
        }, '+=2.5');
      }
    });
    return () => ctx.revert();
  }, [pointContent]);

  return (
    <div ref={containerRef} className={styles.pointForTimelineWrapperMobile}>
      {pointContent.map((point, index) => (
        <div key={index} className={`${styles.pointForTimelineMobile} ${styles.box}`}>
          <button className={styles.btnMobile}>{point.nameBtn}</button>
          <div className={styles.yearMobile}>{point.yearBtn}</div>
        </div>
      ))}
    </div>
  );
};
