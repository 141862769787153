const VscodeIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7403 23.3436C12.7403 23.3436 11.9826 22.7974 12.8919 22.0681L15.0104 20.1737C15.0104 20.1737 15.6166 19.5359 16.2576 20.0916L35.8075 34.893V41.9906C35.8075 41.9906 35.798 43.1051 34.3678 42.982L12.7403 23.3436Z"
        fill="#2489CA"
      />
      <path
        d="M17.7789 27.9187L12.7399 32.4999C12.7399 32.4999 12.2221 32.8851 12.7399 33.5734L15.0795 35.7014C15.0795 35.7014 15.6351 36.2981 16.456 35.6193L21.7982 31.5685L17.7789 27.9187Z"
        fill="#1070B3"
      />
      <path
        d="M26.6263 27.9565L35.8677 20.9L35.8078 13.8402C35.8078 13.8402 35.4131 12.2995 34.0965 13.1014L21.7988 24.2941L26.6263 27.9565Z"
        fill="#0877B9"
      />
      <path
        d="M34.3682 42.9916C34.9049 43.5409 35.5553 43.361 35.5553 43.361L42.7571 39.8122C43.6791 39.1839 43.5496 38.404 43.5496 38.404V17.2122C43.5496 16.2808 42.5961 15.9588 42.5961 15.9588L36.3541 12.9499C34.9902 12.1069 34.0967 13.1014 34.0967 13.1014C34.0967 13.1014 35.2459 12.2742 35.8079 13.8402V41.8613C35.8079 42.0539 35.7669 42.2433 35.6848 42.4138C35.5206 42.7453 35.1638 43.0547 34.3082 42.9253L34.3682 42.9916Z"
        fill="#3C99D4"
      />
    </svg>
  );
};

export default VscodeIcon;
