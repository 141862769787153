const SwiftIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3567 12.4443H36.5826C37.4208 12.4443 38.2347 12.578 39.0243 12.8575C40.1663 13.2707 41.1988 13.9999 41.9763 14.9478C42.7659 15.8957 43.2883 17.0624 43.4705 18.2777C43.5434 18.7273 43.5556 19.177 43.5556 19.6266V36.4339C43.5556 36.9686 43.5313 37.5155 43.4219 38.0381C43.179 39.2412 42.608 40.3714 41.7819 41.2829C40.968 42.1943 39.899 42.8749 38.7449 43.2395C38.0403 43.4582 37.3115 43.5554 36.5704 43.5554C36.2424 43.5554 19.3081 43.5554 19.053 43.5433C17.8139 43.4825 16.5869 43.0815 15.5543 42.3888C14.5461 41.7082 13.72 40.7603 13.1855 39.6666C12.7238 38.7308 12.493 37.6857 12.493 36.6405V19.3593C12.4687 18.3263 12.6874 17.3054 13.1369 16.3818C13.6592 15.2881 14.4732 14.328 15.4815 13.6475C16.514 12.9426 17.7288 12.5294 18.9679 12.4686C19.0894 12.4443 19.223 12.4443 19.3567 12.4443Z"
        fill="url(#paint0_linear_182_5130)"
      />
      <path
        d="M38.6841 37.8923C38.5747 37.7221 38.4532 37.552 38.3196 37.394C38.0159 37.0294 37.6636 36.7134 37.2749 36.4461C36.789 36.1179 36.218 35.9113 35.6349 35.887C35.2219 35.8627 34.8088 35.9357 34.4201 36.0815C34.0314 36.2152 33.6548 36.4096 33.2903 36.6041C32.8652 36.8228 32.44 37.0416 31.9905 37.2238C31.456 37.4426 30.8972 37.6127 30.3262 37.7343C29.6095 37.8679 28.8806 37.9166 28.1639 37.9044C26.864 37.8801 25.5642 37.6857 24.3251 37.3211C23.2318 36.9929 22.187 36.5433 21.2031 35.9721C20.3405 35.4739 19.5388 34.9027 18.7856 34.2586C18.1661 33.7239 17.5951 33.1527 17.0606 32.545C16.6961 32.1197 16.3438 31.67 16.0159 31.2082C15.8822 31.0259 15.7607 30.8315 15.6514 30.637L12.4443 27.1735V19.335C12.4443 15.5311 15.5178 12.4443 19.3201 12.4443H25.4549L29.9982 17.0624C40.2512 24.0381 36.9347 31.7308 36.9347 31.7308C36.9347 31.7308 39.8503 35.012 38.6841 37.8923Z"
        fill="url(#paint1_linear_182_5130)"
      />
      <path
        d="M30.0224 17.0625C40.2754 24.0382 36.959 31.7309 36.959 31.7309C36.959 31.7309 39.8745 35.0243 38.6961 37.9045C38.6961 37.9045 37.4935 35.8872 35.4769 35.8872C33.5332 35.8872 32.3913 37.9045 28.4797 37.9045C19.7695 37.9045 15.6514 30.625 15.6514 30.625C23.499 35.7899 28.8562 32.1319 28.8562 32.1319C25.3212 30.0781 17.8016 20.2587 17.8016 20.2587C24.3493 25.8368 27.1798 27.3073 27.1798 27.3073C25.4912 25.9097 20.7535 19.0799 20.7535 19.0799C24.5437 22.9201 32.0755 28.2795 32.0755 28.2795C34.2135 22.349 30.0224 17.0625 30.0224 17.0625Z"
        fill="#1E2235"
      />
      <defs>
        <linearGradient
          id="paint0_linear_182_5130"
          x1="30.9448"
          y1="11.3342"
          x2="25.0647"
          y2="44.6686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAAE42" />
          <stop offset="1" stopColor="#EF3E31" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_182_5130"
          x1="28.3111"
          y1="12.947"
          x2="24.0076"
          y2="37.344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E39F3A" />
          <stop offset="1" stopColor="#D33929" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SwiftIcon;
