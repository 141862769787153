import ColorTextParser from '@shared/lib/text-parser.tsx';
import { IH2HeadingProps } from '@shared/ui/h2-heading/types.ts';

import styles from '@shared/ui/h2-heading/styles.module.css';

import { DEFAULT_WHITE_COLOR } from '@shared/lib/constants.ts';

const H2Heading = ({ heading, mainTextColor, accentTextColor }: IH2HeadingProps) => {
  return (
    <h2 className={styles.heading}>
      {ColorTextParser({
        text: heading,
        mainColor: mainTextColor || DEFAULT_WHITE_COLOR,
        secondColor: accentTextColor || DEFAULT_WHITE_COLOR,
      })}
    </h2>
  );
};

export default H2Heading;
