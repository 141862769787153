import { ChangeEvent } from 'react';
import { Input } from '@features/input';
import { Dropdown } from '@features/dropdown';
import { IInputsSectionProps } from '@features/feedback-screen/model/types.ts';
import { emailValidator, phoneValidator, phraseValidator } from '@features/feedback-screen/lib/validator.ts';
import { formatPhoneNumber } from '@features/feedback-screen/lib/phoneFormatter.ts';

import styles from '@features/feedback-screen/ui/styles.module.css';

const InputsSection = ({ inputs, setInputs, inputsErrors, setInputsErrors, dropdownOptions }: IInputsSectionProps) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const phoneChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: formatPhoneNumber(e.target.value) }));
  };

  const onBlurPhoneHandler = (e: ChangeEvent<HTMLInputElement>) => {
    phoneValidator(inputs?.phone).then((error) => setInputsErrors((prev) => ({ ...prev, [e.target.name]: error })));
  };

  const onBlurEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    emailValidator(inputs?.email).then((error) => setInputsErrors((prev) => ({ ...prev, [e.target.name]: error })));
  };

  const onBlurNamesHandler = (e: ChangeEvent<HTMLInputElement>) => {
    phraseValidator(inputs?.[`${e.target.name}`]).then((error) =>
      setInputsErrors((prev) => ({ ...prev, [e.target.name]: error }))
    );
  };

  return (
    <div className={styles.inputsWrapper}>
      <Dropdown
        placeholder="Выберите отдел"
        options={dropdownOptions}
        onSelect={(option) => setInputs((prev) => ({ ...prev, departmentId: option.value }))}
      />
      <div className={styles.extraSpace} />
      <Input
        value={inputs?.firstName}
        name="firstName"
        type="text"
        label="Имя"
        placeholder="Имя"
        onChange={handleInputChange}
        onBlur={onBlurNamesHandler}
        error={inputsErrors.firstName}
      />
      <Input
        name="lastName"
        type="text"
        label="Фамилия"
        placeholder="Фамилия"
        onChange={handleInputChange}
        onBlur={onBlurNamesHandler}
        error={inputsErrors.lastName}
      />
      <Input
        name="email"
        type="email"
        label="Email"
        placeholder="Email"
        value={inputs?.email}
        onChange={handleInputChange}
        onBlur={onBlurEmailHandler}
        error={inputsErrors?.email}
      />
      <Input
        name="phone"
        type="tel"
        label="Телефон"
        placeholder="Телефон"
        value={inputs?.phone}
        onChange={phoneChangeHandler}
        onBlur={onBlurPhoneHandler}
        error={inputsErrors?.phone}
      />
    </div>
  );
};

export default InputsSection;
