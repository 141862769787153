const JiraIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.2559 12.4443H27.2744C27.2744 14.238 27.9869 15.9581 29.2552 17.2264C30.5235 18.4947 32.2437 19.2072 34.0373 19.2072H36.7971V21.8718C36.7995 25.6035 39.8239 28.628 43.5555 28.6305V13.744C43.5555 13.0264 42.9738 12.4443 42.2559 12.4443Z"
        fill="#2684FF"
      />
      <path
        d="M34.8427 19.9092H19.8613C19.8636 23.6408 22.8881 26.6654 26.6198 26.6678H29.3796V29.3409C29.3844 33.0726 32.4109 36.095 36.1425 36.095V21.2091C36.1425 20.4913 35.5605 19.9093 34.8427 19.9093V19.9092Z"
        fill="url(#paint0_linear_182_4832)"
      />
      <path
        d="M27.4258 27.3696H12.4443C12.4443 31.1047 15.4723 34.1325 19.2072 34.1325H21.9756V36.797C21.9781 40.5252 24.9973 43.5484 28.7256 43.5555V28.6694C28.7256 27.9516 28.1437 27.3696 27.4258 27.3696Z"
        fill="url(#paint1_linear_182_4832)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_182_4832"
          x1="35.8219"
          y1="19.9352"
          x2="29.4878"
          y2="26.5447"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_182_4832"
          x1="28.8339"
          y1="27.4433"
          x2="21.5068"
          y2="34.6521"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JiraIcon;
