import { forwardRef } from 'react';
import ColorTextParser from '@shared/lib/text-parser.tsx';
import { IChooseUsCardProps } from '@features/choose-us-screen/model/types.ts';

import styles from '@features/choose-us-screen/ui/choose-us.module.css';

import { DEFAULT_WHITE_COLOR } from '@shared/lib/constants.ts';

const ChooseUsCard = forwardRef<HTMLDivElement, IChooseUsCardProps>(
  ({ cardInfo, currentIndex, allCardsCount, mainTextColor, accentTextColor }, ref) => {
    return (
      <div className={`card ${styles.card}`} ref={ref}>
        <div className={styles.cardTextContainer}>
          <div className={styles.cardNumber}>
            {currentIndex}/{allCardsCount}
          </div>
          <h3 className={styles.cardTitle}>
            {ColorTextParser({
              text: cardInfo.title,
              mainColor: mainTextColor || DEFAULT_WHITE_COLOR,
              secondColor: accentTextColor || DEFAULT_WHITE_COLOR,
            })}
          </h3>
          <div className={styles.cardDescription}>{cardInfo.description}</div>
        </div>
        <div className={styles.imgContainer}>
          <img src={cardInfo.image} alt={cardInfo.title ?? ''} />
        </div>
      </div>
    );
  }
);

export default ChooseUsCard;
