const JsIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.5554 12.4443H12.4443V43.5554H43.5554V12.4443Z" fill="#F7DF1E" />
      <path
        d="M33.3429 36.7499C33.9696 37.7731 34.7849 38.5252 36.2269 38.5252C37.4382 38.5252 38.212 37.9198 38.212 37.0832C38.212 36.0808 37.417 35.7257 36.0836 35.1425L35.3528 34.8289C33.2431 33.9302 31.8417 32.8042 31.8417 30.424C31.8417 28.2314 33.5123 26.5623 36.1231 26.5623C37.9819 26.5623 39.3182 27.2092 40.2812 28.903L38.0046 30.3647C37.5034 29.466 36.9627 29.1119 36.1231 29.1119C35.2669 29.1119 34.7241 29.6551 34.7241 30.3647C34.7241 31.2418 35.2673 31.5968 36.5217 32.14L37.2525 32.4531C39.7365 33.5183 41.139 34.6042 41.139 37.0457C41.139 39.6778 39.0713 41.1198 36.2945 41.1198C33.5794 41.1198 31.8254 39.826 30.9671 38.1302L33.3429 36.7499ZM23.0155 37.0032C23.4748 37.8181 23.8925 38.5069 24.897 38.5069C25.8575 38.5069 26.4634 38.1311 26.4634 36.6699V26.7292H29.3869V36.7094C29.3869 39.7366 27.612 41.1144 25.0214 41.1144C22.6807 41.1144 21.3251 39.903 20.6357 38.444L23.0155 37.0032Z"
        fill="black"
      />
    </svg>
  );
};

export default JsIcon;
